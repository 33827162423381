import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../../../atoms/location/authFlowAtoms";
import Username from "../../../../../assets/authBanner/username.png";
import AandRUsername from "../../../../../assets/authBanner/aandr_username.png";
import { useAtomValue, useSetAtom } from "jotai";
import { stepDataAtom } from "../../../atoms";
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  neverMissProjectDeadlinesText,
  takeControlText,
} from "../../../../Auth/AuthBannerInfoWrapper";
import { Box } from "@mui/material";
import { Button } from "../../../../../core-ui/components/Button/Button";
import { useMediaQueryBreakpoint } from "../../../../../../hooks/useMediaQuery";
import { Text } from "../../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../../core-ui/components/Text/TextUtils";
import { UsernameField } from "../../../../AccountSettingsModal/tabs/UsernameField";
import {
  activeUserAtom,
  isActiveUserLabelUserAtom,
} from "../../../../../../atoms/user/activeUserAtom";
import useCreateProfileOnboardingSurveyDataMutation from "../../../../../../hooks/marketingHooks/useCreateProfileOnboardingSurveyDataMutation";
import { AuthStepBanner } from "../AuthStepBanner";
import { ResponsiveAuthContent } from "../../ResponsiveAuthContent";
import { ValidationLabelProps } from "../../../../AccountSettingsModal/tabs/ValidationLabel.types";
import { validateUsername } from "../../validators/usernameValidators";

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
}

export interface UsernameSelectionForm extends HTMLFormElement {
  readonly elements: FormElements;
}

const PREVIOUS_STEP = AuthFlowSteps.PROFILE_SELECTION;
const NEXT_STEP = AuthFlowSteps.EMAIL_VERIFICATION;

export const UserProfileCreation = () => {
  const setStepData = useSetAtom(stepDataAtom);
  const setStep = useSetAtom(authStepAtom);
  const activeUser = useAtomValue(activeUserAtom);
  const isLabelUser = useAtomValue(isActiveUserLabelUserAtom);
  const { mutateAsync: createProfileOnboardingSurvey, isPending } =
    useCreateProfileOnboardingSurveyDataMutation();

  const { isMobile } = useMediaQueryBreakpoint();
  const [hasUsernameErrors, setHasUsernameErrors] = useState<
    Record<string, ValidationLabelProps>
  >({});

  const handleUsernameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      setHasUsernameErrors(validateUsername(value, true));
    },
    [setHasUsernameErrors],
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const form = e.target as UsernameSelectionForm;
      const username = form.elements.username.value;
      // If the username hasn't changed and we already went through this step in the past, go to the next step
      if (
        username === activeUser!.username &&
        activeUser!.has_selected_username
      ) {
        setStep(isLabelUser ? AuthFlowSteps.PHONE_COLLECTION : NEXT_STEP);
        return;
      }
      // Since we are still here, update the username on the backend
      await createProfileOnboardingSurvey({
        username,
        profile_id: activeUser!.profile_id,
      }).then(() => {
        setStep(isLabelUser ? AuthFlowSteps.PHONE_COLLECTION : NEXT_STEP);
      });
    },
    [createProfileOnboardingSurvey, setStep, activeUser, isLabelUser],
  );

  const titleContent = useMemo(() => {
    return (
      <AuthStepBanner
        onClick={() => {
          setStep(isLabelUser ? AuthFlowSteps.LABEL_SELECTION : PREVIOUS_STEP);
        }}
        currentStep={isLabelUser ? 3 : 2}
        maxStep={isLabelUser ? 4 : 3}
      />
    );
  }, [setStep, isLabelUser]);

  useEffect(() => {
    setStepData({
      disableClose: true,
      hideFooter: true,
      disablePadding: true,
      maxWidth: "md",
      title: isMobile ? titleContent : undefined,
      onSubmit: handleSubmit,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, isMobile, titleContent, handleSubmit]);
  return (
    <ResponsiveAuthContent
      titleContent={titleContent}
      {...(isLabelUser ? neverMissProjectDeadlinesText : takeControlText)}
      src={`url(${isLabelUser ? AandRUsername : Username})`}
    >
      <Box>
        <Text variant={TextStyleVariant.H6}>Get your profile started</Text>
        <Text variant={TextStyleVariant.P1}>
          Add a username unique to you, this is how you&apos;ll appear to
          others. You can change your username at a later time in account
          settings.
        </Text>
      </Box>
      <UsernameField
        my={"auto"}
        TextFieldProps={{
          defaultValue: activeUser?.username,
          onChange: handleUsernameChange,
        }}
        label={"Choose your username"}
        disabled={isPending}
        errorMessage={false}
        validationLabels={hasUsernameErrors}
        isStudio={false}
      />
      <Button fullWidth type={"submit"} loading={isPending}>
        Create Your Account
      </Button>
    </ResponsiveAuthContent>
  );
};
