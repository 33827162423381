import { AuthFlowSteps } from "../../../atoms/location/authFlowAtoms";
import { AuthBannerTextProps } from "../Auth/AuthBannerInfoWrapper";
import { ReactNode } from "react";
import { Grid2Props } from "@mui/material";

export interface AuthStepComponentProps {
  step: AuthFlowSteps;
}

export interface ResponsiveAuthBannerProps
  extends Partial<AuthBannerTextProps> {
  src?: string;
  children: ReactNode;
  compact?: boolean;
  gridProps?: Partial<Grid2Props>;
}

export enum SignUpOrInMethodsEnum {
  UNKNOWN = "unknown",
  GOOGLE = "google",
  INTERNAL = "internal",
  PHONE = "phone",
}

export const SignUpOrInMethods = [
  SignUpOrInMethodsEnum.GOOGLE,
  SignUpOrInMethodsEnum.PHONE,
  SignUpOrInMethodsEnum.INTERNAL,
];

export function convertToSignUpOrInMethodsEnum(
  str: string,
): str is SignUpOrInMethodsEnum {
  return SignUpOrInMethods.includes(str as SignUpOrInMethodsEnum);
}

interface SignUpOrInFormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
  password: HTMLInputElement;
}

export interface SignUpOrInFormElement extends HTMLFormElement {
  readonly elements: SignUpOrInFormElements;
}
