import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../../../atoms/location/authFlowAtoms";
import BannerImage from "../../../../../assets/authBanner/studio_location.png";
import { useAtomValue, useSetAtom } from "jotai";
import { stepDataAtom } from "../../../atoms";
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { manageStudioWithEase } from "../../../../Auth/AuthBannerInfoWrapper";
import { Box } from "@mui/material";
import { Button } from "../../../../../core-ui/components/Button/Button";
import { useMediaQueryBreakpoint } from "../../../../../../hooks/useMediaQuery";
import { Text, TEXT_WEIGHT } from "../../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../../core-ui/components/Text/TextUtils";
import { UsernameField } from "../../../../AccountSettingsModal/tabs/UsernameField";
import useCreateProfileOnboardingSurveyDataMutation from "../../../../../../hooks/marketingHooks/useCreateProfileOnboardingSurveyDataMutation";
import { AuthStepBanner } from "../AuthStepBanner";
import { ResponsiveAuthContent } from "../../ResponsiveAuthContent";
import useUpdateStudioMutation from "../../../../../../hooks/studioHooks/useUpdateStudioMutation";
import { activeStudioProfileAtom } from "../../../../../../atoms/user/activeProfileAtom";
import { LocationInput } from "../../../../LocationInput/LocationInput";
import { ValidationLabelProps } from "../../../../AccountSettingsModal/tabs/ValidationLabel.types";
import { validateUsername } from "../../validators/usernameValidators";
import { SlimActiveStudio } from "../../../../../../store/models/studio";
import useActiveStudiosQuery from "../../../../../../hooks/userHooks/useActiveStudiosQuery";
import { SoundWaveLoader } from "../../../../../elements/SoundWaveLoader/SoundWaveLoader";
import PlaceResult = google.maps.places.PlaceResult;

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
}

export interface UsernameSelectionForm extends HTMLFormElement {
  readonly elements: FormElements;
}

const PREVIOUS_STEP = AuthFlowSteps.PROFILE_SELECTION;
const NEXT_STEP = AuthFlowSteps.EMAIL_VERIFICATION;

interface StudioProfileCreationProps {
  activeStudio?: SlimActiveStudio;
  creationOnlyMode?: boolean;
  isLoading?: boolean;
  setLocationData: Dispatch<SetStateAction<PlaceResult | undefined>>;
  titleContent?: ReactNode;
}

export const StudioProfileCreation = ({
  activeStudio,
  creationOnlyMode,
  isLoading,
  setLocationData,
  titleContent,
}: StudioProfileCreationProps) => {
  const [hasUsernameErrors, setHasUsernameErrors] = useState<
    Record<string, ValidationLabelProps>
  >(validateUsername(activeStudio?.username || "", true));

  const handleUsernameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value.toLowerCase();
      setHasUsernameErrors(validateUsername(value, true));
    },
    [setHasUsernameErrors],
  );

  return (
    <ResponsiveAuthContent
      titleContent={titleContent}
      {...manageStudioWithEase}
      src={`url(${BannerImage})`}
    >
      <Box
        my={"auto"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
      >
        <Box>
          <Text variant={TextStyleVariant.H6}>
            {creationOnlyMode
              ? "Create Your Studio"
              : "Get your profile started"}
          </Text>
        </Box>
        <UsernameField
          TextFieldProps={{
            defaultValue: activeStudio?.username,
            onChange: handleUsernameChange,
          }}
          label={"Choose your username"}
          disabled={isLoading}
          errorMessage={false}
          validationLabels={hasUsernameErrors}
          isStudio={true}
        />
        <Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
          <Text weight={TEXT_WEIGHT.SEMI_BOLD}>Studio address</Text>
          <LocationInput
            showBorder
            defaultValue={activeStudio?.location?.city_location || ""}
            placeholder={"Start by typing in your address"}
            onPlaceSelected={(place) => {
              setLocationData(place);
            }}
          />
        </Box>
      </Box>
      <Button fullWidth type={"submit"} loading={isLoading}>
        {activeStudio ? "Save" : "Create"} my studio
      </Button>
    </ResponsiveAuthContent>
  );
};

export const StudioProfileCreationStep = () => {
  const setStepData = useSetAtom(stepDataAtom);
  const setStep = useSetAtom(authStepAtom);
  const [locationData, setLocationData] = useState<PlaceResult>();
  const { isLoading } = useActiveStudiosQuery({});
  const activeStudio = useAtomValue(activeStudioProfileAtom);
  const { mutateAsync: createProfileOnboardingSurvey, isPending } =
    useCreateProfileOnboardingSurveyDataMutation();
  const { mutateAsync: updateStudio, isPending: isUpdatingStudio } =
    useUpdateStudioMutation();

  const { isMobile } = useMediaQueryBreakpoint();

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const form = e.target as UsernameSelectionForm;
      const username = form.elements.username.value.toLowerCase();
      // If the username hasn't changed and we already went through this step in the past, go to the next step
      const username_unchanged =
        activeStudio &&
        username === activeStudio.username &&
        activeStudio.has_selected_username;

      const location_unchanged =
        activeStudio &&
        locationData?.formatted_address === activeStudio.location?.formatted;
      if (username_unchanged && location_unchanged) {
        setStep(NEXT_STEP);
        return;
      }
      // Since we are still here, update the username on the backend
      await updateStudio({
        username,
        location: locationData,
        studio_id: activeStudio?.id,
      }).then((studio) => {
        return createProfileOnboardingSurvey({
          username,
          profile_id: studio.studio_profile!.id!,
        }).then(() => {
          setStep(NEXT_STEP);
        });
      });
    },
    [
      createProfileOnboardingSurvey,
      setStep,
      activeStudio,
      updateStudio,
      locationData,
    ],
  );

  const titleContent = useMemo(() => {
    return (
      <AuthStepBanner
        onClick={() => {
          setStep(PREVIOUS_STEP);
        }}
        currentStep={2}
        maxStep={3}
      />
    );
  }, [setStep]);

  useEffect(() => {
    setStepData({
      disableClose: true,
      hideFooter: true,
      disablePadding: true,
      maxWidth: "md",
      title: isMobile ? titleContent : undefined,
      onSubmit: handleSubmit,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, isMobile, titleContent, handleSubmit]);

  if (isLoading) {
    return <SoundWaveLoader width={150} height={150} />;
  }

  return (
    <StudioProfileCreation
      setLocationData={setLocationData}
      isLoading={isPending || isUpdatingStudio}
      titleContent={titleContent}
      activeStudio={activeStudio}
    />
  );
};
