// getter/setter for the search term
import { atom } from "jotai";
import { activeUserAtom } from "../user/activeUserAtom";
import { activeProfileAtom } from "../user/activeProfileAtom";
import { DISCIPLINE_TYPE } from "../../hooks/user";
import { activeUserStudiosAtom } from "../user/activeUserStudiosAtom";

export enum AuthFlowSteps {
  // If the step value is zero, the flow should not be displayed.
  NONE = "none",
  // The user has signed up, but has not selected any profile with us before
  PASSWORD_CREATION = "password_creation",
  // The user has signed up, but has not selected any profile with us before
  PROFILE_SELECTION = "profile_selection",
  // The admin has signed up, but has not selected any label with us before
  LABEL_SELECTION = "label_selection",
  // The user has signed up with a profile type, but has not completed their profile
  PROFILE_CREATION = "profile_creation",
  // The user has signed up with a profile, and selected the type, but has not verified their email
  EMAIL_VERIFICATION = "email_verification",
  // The user has signed up with a verified email on their profile, but has not given us their phone number
  PHONE_COLLECTION = "phone_collection",
  // The user has signed up with a verified email on their profile with a phone number, but has not yet verified it
  PHONE_VERIFICATION = "phone_verification",
  // The user has completed sign up, but has not filled out onboarding questions
  ONBOARDING_SURVEY = "onboarding_survey",
  // The user has completed onboarding for the first time visiting our site
  ONBOARDING_COMPLETE = "onboarding_complete",
}

export const maxAuthStepAtom = atom((get) => {
  const activeUser = get(activeUserAtom);
  const activeProfile = get(activeProfileAtom);
  // Here we validate the requested auth step from the URL against the data we have to see where a user is in the auth flow
  if (!activeUser) {
    // Since the user wants to be in the flow, but they are not signed in, we set the step to none
    return AuthFlowSteps.NONE;
  }
  // If the user is an A&R or Admin, they should verify their email first, otherwise they should select their profile first
  if (activeUser.disciplines?.aandr || activeUser.disciplines?.admin) {
    // If the user has a username, but no verified email, then they are still in the email verification step
    if (!activeUser.verified) {
      return AuthFlowSteps.EMAIL_VERIFICATION;
    }
    if (!activeUser.has_password) {
      return AuthFlowSteps.PASSWORD_CREATION;
    }
    // If the user is signed in, but has no disciplines, then they are still in the profile selection step
    if (Object.keys(activeUser.disciplines || {}).length === 0) {
      return AuthFlowSteps.PROFILE_SELECTION;
    }
    // Figure out how to insert label selection
    if (!activeUser.has_selected_label) {
      return AuthFlowSteps.LABEL_SELECTION;
    }

    // If the user has a discipline, but no username, then they are still in the profile creation step
    if (!activeUser.has_selected_username) {
      return AuthFlowSteps.PROFILE_CREATION;
    }
  } else {
    if (!activeUser.has_password) {
      return AuthFlowSteps.PASSWORD_CREATION;
    }
    // If the user is signed in, but has no disciplines, then they are still in the profile selection step
    if (Object.keys(activeUser.disciplines || {}).length === 0) {
      return AuthFlowSteps.PROFILE_SELECTION;
    }
    // If the user has a discipline, but no username, then they are still in the profile creation step
    if (!activeProfile?.has_selected_username) {
      return AuthFlowSteps.PROFILE_CREATION;
    }
  }
  if (activeUser.needs_onboarding_info) {
    return AuthFlowSteps.ONBOARDING_SURVEY;
  }
  return AuthFlowSteps.ONBOARDING_COMPLETE;
});

const _authStepAtom = atom(AuthFlowSteps.NONE);

export const authStepAtom = atom(
  (get) => {
    const stepValue = get(_authStepAtom);
    const value = Object.values(AuthFlowSteps).includes(
      stepValue as AuthFlowSteps,
    )
      ? stepValue
      : AuthFlowSteps.NONE;
    const activeUser = get(activeUserAtom);
    const activeUserStudios = get(activeUserStudiosAtom);
    const maxStep = get(maxAuthStepAtom);
    let minimumStep = value;

    // If the maximum is lower than the minimum, set the minimum to the maximum
    if (!minimumStep && maxStep !== AuthFlowSteps.ONBOARDING_COMPLETE)
      minimumStep = maxStep;
    // If we have a user and no step set, set one as a minimum.
    if (activeUser) {
      if (!minimumStep || minimumStep == AuthFlowSteps.NONE) {
        if (activeUser.disciplines?.aandr || activeUser.disciplines?.admin) {
          if (!activeUser.email_verified) {
            minimumStep = AuthFlowSteps.EMAIL_VERIFICATION;
          } else if (!activeUser.has_password) {
            minimumStep = AuthFlowSteps.PASSWORD_CREATION;
          } else if (!activeUser.has_selected_label) {
            minimumStep = AuthFlowSteps.LABEL_SELECTION;
          } else if (!activeUser.has_selected_username) {
            minimumStep = AuthFlowSteps.PROFILE_CREATION;
          } else if (!activeUser.phone_number) {
            minimumStep = AuthFlowSteps.PHONE_COLLECTION;
          } else if (!activeUser.phone_number.verified) {
            minimumStep = AuthFlowSteps.PHONE_VERIFICATION;
          } else {
            minimumStep = AuthFlowSteps.PROFILE_SELECTION;
          }
        } else {
          if (!activeUser.has_password) {
            minimumStep = AuthFlowSteps.PASSWORD_CREATION;
          } else if (!activeUser.primaryDiscipline) {
            minimumStep = AuthFlowSteps.PROFILE_SELECTION;
          } else if (
            activeUser.primaryDiscipline !== DISCIPLINE_TYPE.STUDIO_MANAGER &&
            !activeUser.has_selected_username
          ) {
            minimumStep = AuthFlowSteps.PROFILE_CREATION;
          } else if (
            activeUser.primaryDiscipline === DISCIPLINE_TYPE.STUDIO_MANAGER &&
            activeUserStudios.findIndex((studio) => {
              return studio.has_selected_username;
            }) === -1
          ) {
            minimumStep = AuthFlowSteps.PROFILE_CREATION;
          } else if (!activeUser.email_verified) {
            minimumStep = AuthFlowSteps.EMAIL_VERIFICATION;
          } else if (!activeUser.phone_number) {
            minimumStep = AuthFlowSteps.PHONE_COLLECTION;
          } else if (!activeUser.phone_number.verified) {
            minimumStep = AuthFlowSteps.PHONE_VERIFICATION;
          }
        }
      }
    }

    // Ensure any other minimums

    // If we are in the phone verification step without a phone number, go back to the phone collection step
    if (
      minimumStep === AuthFlowSteps.PHONE_VERIFICATION &&
      !activeUser?.phone_number
    ) {
      return AuthFlowSteps.PHONE_COLLECTION;
    }

    return minimumStep;
  },
  (get, set, value: AuthFlowSteps | undefined) => {
    set(_authStepAtom, value || AuthFlowSteps.NONE);
  },
);

authStepAtom.debugLabel = "Validated Auth Step Atom";
