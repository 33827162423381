import {
  AccountSettingsTextField,
  AccountSettingsTextFieldProps,
} from "./AccountSettingsTextField";

interface UsernameFieldProps extends AccountSettingsTextFieldProps {
  isStudio?: boolean;
}

export const UsernameField = ({
  isStudio,
  TextFieldProps,
  ...props
}: UsernameFieldProps) => {
  return (
    <AccountSettingsTextField
      label={"Username"}
      name={"username"}
      TextFieldProps={{
        placeholder: "Username",
        slotProps: {
          input: {
            slotProps: {
              input: {
                sx: {
                  // Usernames cannot contain uppercase characters. Everything is made lowercase on the backend
                  textTransform: "lowercase",
                },
              },
            },
          },
        },
        ...TextFieldProps,
      }}
      helperText={isStudio ? "" : "Note: used to login"}
      {...props}
    />
  );
};
