import { DialogActions, DialogActionsProps, styled } from "@mui/material";
import classNames from "classnames";
import { TrackableClassNames } from "../../../../constants/trackableClassNames";

type ResponsiveDialogActionsProps = Partial<DialogActionsProps>;

const StyledResponsiveDialogActions = styled(DialogActions)(({ theme }) => ({
  borderRadius: 0,
  [theme.breakpoints.up("md")]: {
    borderRadius: theme.spacing(1),
  },
}));

export const ResponsiveDialogActions = (
  actionsProps: ResponsiveDialogActionsProps,
) => {
  const {
    children: actionsChildren,
    className: actionsClassName,
    ...remainingActionsProps
  } = actionsProps;
  return (
    <StyledResponsiveDialogActions
      className={classNames(
        TrackableClassNames.ENGINEEARS_DIALOG_ACTIONS,
        actionsClassName,
      )}
      {...remainingActionsProps}
    >
      {actionsChildren}
    </StyledResponsiveDialogActions>
  );
};
