import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import {
  Button,
  ButtonVariant,
} from "../../../../../core-ui/components/Button/Button";
import { Text } from "../../../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../../../../elements/SoundWaveLoader/SoundWaveLoader";
import { Box, BoxProps } from "@mui/material";
import VerificationCodeInput from "../../../../Auth/VerificationCodeInput";

interface VerifyPhoneInputProps extends Omit<BoxProps, "children"> {
  phoneNumber: string;
  handleVerifyPhone: (verificationCode: string) => Promise<boolean>;
  handleResendVerifyPhone: () => Promise<void>;
  verificationError?: boolean;
  isVerifyingPhone?: boolean;
  resendLoading?: boolean;
  resentVerification?: boolean;
}

export const VerifyPhoneInput = ({
  phoneNumber,
  handleVerifyPhone,
  handleResendVerifyPhone,
  verificationError,
  isVerifyingPhone,
  resentVerification,
  resendLoading,
  ...props
}: VerifyPhoneInputProps) => {
  const onVerifyPhone = async (verificationCode: string) => {
    return await handleVerifyPhone(verificationCode);
  };

  const onResendVerifyPhone = async () => {
    return await handleResendVerifyPhone();
  };

  return (
    <Box {...props}>
      <Text variant={TextStyleVariant.H6}>Confirm your phone</Text>
      <Text variant={TextStyleVariant.P1}>
        Enter the verification code we sent to:
        <br />
        {phoneNumber}
      </Text>
      <VerificationCodeInput codeLength={6} onComplete={onVerifyPhone} />
      {verificationError && (
        <Text variant={TextStyleVariant.P1} color={TextColor.ERROR}>
          <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faXmark} />
          Invalid verification code
        </Text>
      )}
      {resendLoading ? (
        <SoundWaveLoader width={100} height={100} />
      ) : resentVerification ? (
        <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
          <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
          Verification code resent
        </Text>
      ) : (
        <Button
          variant={ButtonVariant.TEXT}
          style={{ display: "inline", fontWeight: "600" }}
          onClick={onResendVerifyPhone}
          loading={isVerifyingPhone}
        >
          Resend a code
        </Button>
      )}
    </Box>
  );
};
