import AuthStepWrapper from "./AuthStepWrapper";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../../atoms/location/authFlowAtoms";
import { useAtomValue, useSetAtom } from "jotai";
import { stepDataAtom } from "../../atoms";
import { useEffect, useMemo } from "react";
import {
  neverMissProjectDeadlinesText,
  takeControlText,
} from "../../../Auth/AuthBannerInfoWrapper";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { useMediaQueryBreakpoint } from "../../../../../hooks/useMediaQuery";
import { AuthStepBanner } from "./AuthStepBanner";
import { ResponsiveAuthContent } from "../ResponsiveAuthContent";
import AddPhone from "../../../../assets/authBanner/add_phone.png";
import AandRAddPhone from "../../../../assets/authBanner/aandr_add_phone.png";
import { emitAnalyticsTrackingEvent } from "../../../../../utils/analyticsUtils";
import useActiveUserQuery from "../../../../../hooks/userHooks/useActiveUserQuery";
import { AddPhoneInput } from "../../../Auth/AddPhoneInput";
import { useUpdateUserProfile } from "../../../../../hooks/userHooks/useUpdateUserProfile";
import { isActiveUserLabelUserAtom } from "../../../../../atoms/user/activeUserAtom";

const NEXT_STEP = AuthFlowSteps.PHONE_VERIFICATION;
const REMIND_ME_LATER_STEP = AuthFlowSteps.ONBOARDING_SURVEY;
const MY_STEP = AuthFlowSteps.PHONE_COLLECTION;

const StepInner = () => {
  const setStepData = useSetAtom(stepDataAtom);
  const setStep = useSetAtom(authStepAtom);
  const { data: activeUser } = useActiveUserQuery();
  const isLabelUser = useAtomValue(isActiveUserLabelUserAtom);

  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();

  const handleAddPhoneNumber = (phoneNumber: string) => {
    emitAnalyticsTrackingEvent("auth_add_phone_clicked_submit", {});
    // Phone number hasn't changed, so skip submission and go to next step
    if (phoneNumber === activeUser?.phone_number?.phone_number) {
      setStep(NEXT_STEP);
      return;
    }
    void updateUserProfile({ phone_number: phoneNumber }).then(() => {
      setStep(NEXT_STEP);
    });
  };

  const { isMobile } = useMediaQueryBreakpoint();

  const titleContent = useMemo(() => {
    return (
      <AuthStepBanner
        onClick={() => {
          // If the email is verified, going back to that step will just bring us back to this step, so go back 2.
          setStep(
            activeUser?.email_verified
              ? AuthFlowSteps.PROFILE_CREATION
              : AuthFlowSteps.EMAIL_VERIFICATION,
          );
        }}
        currentStep={isLabelUser ? 4 : 3}
        maxStep={isLabelUser ? 4 : 3}
      />
    );
  }, [setStep, activeUser?.email_verified, isLabelUser]);

  useEffect(() => {
    setStepData({
      disableClose: true,
      hideFooter: true,
      disablePadding: true,
      maxWidth: "md",
      title: isMobile ? titleContent : undefined,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, isMobile, titleContent]);

  return (
    <ResponsiveAuthContent
      titleContent={titleContent}
      {...(isLabelUser ? neverMissProjectDeadlinesText : takeControlText)}
      src={`url(${isLabelUser ? AandRAddPhone : AddPhone})`}
    >
      <AddPhoneInput
        width={"100%"}
        my={"auto"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={1}
        guestAuth={false}
        phoneLogin={false}
        isLoading={false}
        defaultPhoneNumber={activeUser?.phone_number?.phone_number}
        handleAddPhoneNumber={handleAddPhoneNumber}
      />
      <Button
        variant={ButtonVariant.UNSTYLED}
        sx={(theme) => ({
          textDecoration: "underline",
          alignSelf: "flex-end",
          color: theme.palette.text.primary,
        })}
        onClick={() => {
          emitAnalyticsTrackingEvent(
            "auth_add_phone_clicked_remind_me_later",
            {},
          );
          setStep(REMIND_ME_LATER_STEP);
        }}
      >
        Remind me later
      </Button>
    </ResponsiveAuthContent>
  );
};

export const PhoneCollectionStep = () => {
  return (
    <AuthStepWrapper step={MY_STEP}>
      <StepInner />
    </AuthStepWrapper>
  );
};
