import { useSearchParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { EmailOrUsernameField } from "../EmailOrUsernameField";
import { useDebouncedUsernameCheck } from "../../../SignInForm/useDebouncedUsernameCheck";
import { SignInPasswordField } from "./SignInPasswordField";
import {
  validateEmail,
  validateUmgEmail,
} from "../../components/validators/emailValidators";
import { Button } from "../../../../core-ui/components/Button/Button";
import { emitAnalyticsTrackingEvent } from "../../../../../utils/analyticsUtils";
import { loginRequest } from "../../../../../utils/umgAuthConfig";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { SignInVerificationCodeField } from "./SignInVerificationCodeField";
import { SignInUsernameParameter } from "../../constants";

interface SignInInternalProps {
  loading?: boolean;
}

const SignInInternal = ({ loading }: SignInInternalProps) => {
  const [searchParams] = useSearchParams({
    [SignInUsernameParameter]: "",
  });
  const { instance } = useMsal();
  const [emailOrUsername, setEmailOrUsername] = useState(
    searchParams.get(SignInUsernameParameter) || "",
  );
  const username = useMemo(() => {
    if (emailOrUsername.includes("@")) {
      return "";
    }
    return emailOrUsername;
  }, [emailOrUsername]);
  const isValidEmail = useMemo(() => {
    const validations = validateEmail(emailOrUsername, true);
    return Object.values(validations).findIndex((data) => data.invalid) === -1;
  }, [emailOrUsername]);

  const isUmgEmail = useMemo(() => {
    if (!isValidEmail) return false;
    const validations = validateUmgEmail(emailOrUsername, true);
    return Object.values(validations).findIndex((data) => data.invalid) === -1;
  }, [emailOrUsername, isValidEmail]);
  const { usernameExists, usernameBelongsToPasswordlessAccount } =
    useDebouncedUsernameCheck(username);

  const handleUmgLogin = useCallback(() => {
    emitAnalyticsTrackingEvent("auth_click_on_umg_login", {});

    instance
      .loginRedirect({
        ...loginRequest,
        redirectStartPage: `${window.location.origin}${location.pathname}`,
      })
      .catch((e: any) => {
        toast.error(
          "Something went wrong with the login request. Please contact support.",
        );
      });
  }, [instance]);
  return (
    <>
      <EmailOrUsernameField
        TextFieldProps={{
          defaultValue: emailOrUsername,
          onChange: (e) => {
            setEmailOrUsername(e.target.value);
          },
        }}
      />
      {!(usernameBelongsToPasswordlessAccount || isUmgEmail) && (
        <SignInPasswordField />
      )}
      {usernameBelongsToPasswordlessAccount ? (
        <SignInVerificationCodeField email={emailOrUsername} />
      ) : (
        <Button
          disabled={(!isValidEmail && !usernameExists) || loading}
          fullWidth
          loading={loading}
          type={isUmgEmail ? undefined : "submit"}
          onClick={
            isUmgEmail
              ? () => {
                  handleUmgLogin();
                }
              : undefined
          }
        >
          {isUmgEmail ? "Sign In With UMG SSO" : "Submit"}
        </Button>
      )}
    </>
  );
};

export default SignInInternal;
