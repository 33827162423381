import { useCallback } from "react";
import { Button, ButtonProps } from "../../../core-ui/components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { SignUpOrInMethodsEnum } from "../types";
import { SignInMethodParameter } from "../constants";

interface SignInOptionButtonProps extends ButtonProps {
  method: SignUpOrInMethodsEnum;
}

export const SignUpOrInOptionButton = ({
  method,
  ...props
}: SignInOptionButtonProps) => {
  const { hash, search } = useLocation();
  const navigate = useNavigate();

  const handleClickButton = useCallback(
    (pageKey: string) => {
      navigate(
        {
          hash,
          search: queryString.stringify({
            ...queryString.parse(search),
            [SignInMethodParameter]: pageKey,
          }),
        },
        { replace: false },
      );
    },
    [search, navigate, hash],
  );

  return (
    <Button
      fullWidth
      onClick={() => {
        handleClickButton(method);
      }}
      {...props}
    />
  );
};
