import {
  TextColor,
  TextStyleVariant,
} from "../../../../../core-ui/components/Text/TextUtils";
import { ProfileTypeCardTitle } from "../../../../Auth/AuthModal.styles";
import { Text } from "../../../../../core-ui/components/Text/Text";
import { faCircle, faCircleCheck } from "@fortawesome/pro-duotone-svg-icons";
import ProfileTypeCardContainer from "./styles/ProfileTypeCardContainer.styles";
import ProfileTypeCardInfo from "./styles/ProfileTypeCardInfo.styles";
import { MouseEventHandler, useCallback, useRef } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import {
  DISCIPLINE_TYPE,
  getDisciplineString,
} from "../../../../../../hooks/user";
import { ProfileTypeDescriptions } from "./constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ProfileTypeCardProps {
  discipline: DISCIPLINE_TYPE;
  isPrimary: boolean;
  isDisabled: boolean;
  onClick?: (discipline: DISCIPLINE_TYPE, checked: boolean) => void;
  defaultValue?: boolean;
}

export const ProfileTypeCard = ({
  discipline,
  defaultValue,
  isPrimary = false,
  isDisabled = false,
  onClick = () => {},
}: ProfileTypeCardProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const description = ProfileTypeDescriptions[discipline];
  const profileType = getDisciplineString(discipline);

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if ((e.target as HTMLElement).tagName !== "INPUT") {
        e.preventDefault();
        ref.current?.click();
        return;
      }
      onClick(discipline, (e.target as HTMLInputElement).checked);
    },
    [onClick, ref, discipline],
  );
  return (
    <ProfileTypeCardContainer onClick={isDisabled ? undefined : handleClick}>
      <FormControlLabel
        control={
          <Checkbox
            icon={
              <FontAwesomeIcon
                icon={faCircle}
                className={"profile-type-check inactive"}
              />
            }
            checkedIcon={
              <FontAwesomeIcon
                icon={faCircleCheck}
                className={"profile-type-check active"}
              />
            }
            disabled={isDisabled}
            // sx={{ display: "none" }}
            inputRef={ref}
            value={discipline}
            name={"discipline"}
            defaultChecked={defaultValue}
          />
        }
        label={
          <ProfileTypeCardInfo>
            <ProfileTypeCardTitle>
              <Box className={"profile-type-pill"}>
                <Text
                  variant={TextStyleVariant.S2}
                  color={isDisabled ? TextColor.TERTIARY : TextColor.PRIMARY}
                >
                  {profileType}
                </Text>
              </Box>
              {isPrimary && (
                <Text
                  className={"primary-text"}
                  variant={TextStyleVariant.P2}
                  color={TextColor.SUCCESS}
                >
                  Primary
                </Text>
              )}
            </ProfileTypeCardTitle>
            <Text
              variant={TextStyleVariant.P1}
              color={isDisabled ? TextColor.TERTIARY : TextColor.SECONDARY}
              bold
            >
              {description}
            </Text>
          </ProfileTypeCardInfo>
        }
        labelPlacement={"start"}
      />
    </ProfileTypeCardContainer>
  );
};
