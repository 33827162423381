import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccountSettingsTextField,
  AccountSettingsTextFieldProps,
} from "../../../AccountSettingsModal/tabs/AccountSettingsTextField";
import { ShowHideIcon } from "../../../PasswordField/PasswordField.styles";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material";
import { useState } from "react";
import classNames from "classnames";

const label = "Password";
export const SignInPasswordField = ({
  TextFieldProps,
  className,
  disabled,
  ...props
}: AccountSettingsTextFieldProps) => {
  const theme = useTheme();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  return (
    <AccountSettingsTextField
      label={label}
      className={classNames([
        className,
        {
          disabled,
        },
      ])}
      name={"password"}
      TextFieldProps={{
        placeholder: label,
        type: showCurrentPassword ? "text" : "password",
        required: true,
        InputProps: {
          endAdornment: (
            <ShowHideIcon>
              <FontAwesomeIcon
                icon={showCurrentPassword ? faEye : faEyeSlash}
                color={theme.palette.text.secondary}
                aria-disabled={disabled}
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              />
            </ShowHideIcon>
          ),
        },
        disabled,
        ...TextFieldProps,
      }}
      {...props}
    />
  );
};
