import { FormGroup, styled } from "@mui/material";

const ProfileTypeCardContainer = styled(FormGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  minHeight: 90,
  height: "100%",
  minWidth: "min(304px, 75vw)",
  padding: "8px 16px",
  boxSizing: "border-box",
  alignItems: "center",
  borderRadius: theme.border.radius.lg,
  border: `1px solid ${theme.palette.customColor.darkerMidgroundColor}`,
  boxShadow: "none",
  cursor: "pointer",
  "& .profile-type-pill": {
    display: "flex",
    padding: "4px 8px",
    boxSizing: "border-box",
    justifyContent: "center",
    gap: "10px",
    borderRadius: "8px",
    background: theme.palette.customColor.midgroundColor,
  },
  "& .profile-type-check": {
    "--fa-primary-color": theme.palette.customColor.midgroundColor,
    "--fa-secondary-color": theme.palette.customColor.midgroundColor,
    height: "41px",
    width: "41px",
    "&.active": {
      display: "none",
    },
  },
  "& .primary-text": {
    display: "none",
  },
  "&:has(.PrivateSwitchBase-input:checked)": {
    border: `1px solid ${theme.palette.success.main}`,
    "& .profile-type-pill": {
      background: theme.palette.customColor.successFadedColor,
    },
    "& .profile-type-check": {
      "--fa-primary-color": theme.palette.success.main,
      "--fa-secondary-color": theme.palette.customColor.successFadedColor,

      "&.inactive": {
        display: "none",
      },
      "&.active": {
        display: "unset",
      },
    },
    "& .primary-text": {
      display: "unset",
    },
  },
  "&:has(.Mui-disabled)": {
    cursor: "not-allowed",
  },
  "& > label": {
    pointerEvents: "none",
    flex: 1,
    flexDirection: "row-reverse",
    margin: 0,
    "& > *:first-of-type": {
      marginLeft: "auto",
    },
    "& *": {
      pointerEvents: "none",
    },
  },
}));

export default ProfileTypeCardContainer;
