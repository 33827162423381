import { Box, styled } from "@mui/material";

const LabelOptionCardInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "150px",
  height: "150px",
  alignItems: "center",
  gap: "12px",
  flexShrink: 0,
  pointerEvents: "none",
  justifyContent: "center",
  padding: theme.spacing(1),
  boxSizing: "border-box",
  borderRadius: "8px",
  border: `2px solid ${theme.palette.customColor.buttonColorHover}`,
  [theme.breakpoints.up("lg")]: {
    width: "200px",
    height: "200px",
  },
}));

export default LabelOptionCardInfo;
