import { useSetAtom } from "jotai/index";
import { useCallback } from "react";
import {
  COMMANDS,
  POWER_USER_CONTROLS_CATEGORY,
  POWER_USER_CONTROLS_LABEL,
} from "../types";
import { usePowerUserControl } from "../usePowerUserControl";
import { showJotaiDevToolsAtom } from "../../../atoms/dev/jotaiDevtoolsAtom";

const LABEL = POWER_USER_CONTROLS_LABEL.JOTAI_DEVTOOLS;

/**
 * Sets up the power user control that toggles dark mode.
 *
 * When the user presses the shortcut, the dark mode is toggled.
 *
 * @returns The shortcuts data, which is an object with two properties:
 * - `keys`: an array of strings representing the keys that trigger the shortcut
 * - `label`: a string that describes the shortcut
 */
export const useJotaiDevToolsControls = () => {
  const toggleJotaiDevTools = useSetAtom(showJotaiDevToolsAtom);
  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      toggleJotaiDevTools((prev) => !prev);
    },
    [toggleJotaiDevTools],
  );
  return usePowerUserControl(
    LABEL,
    { ...COMMANDS[LABEL], category: POWER_USER_CONTROLS_CATEGORY.DEV },
    handleKeyPress,
  );
};
