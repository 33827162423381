import { useAtom, useAtomValue } from "jotai";
import "rc-pagination/assets/index.css";
import { Suspense } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { Outlet, useLocation } from "react-router-dom";
import { consentAtom } from "./atoms/consentAtom";
import { displayGenerateBookingInfoAtom } from "./atoms/modals/generatedBookingInfoModal";
import { SCREENS } from "./routes/screens";
import { toggleLoginModal } from "./store/actions/userInterface";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import ChatModal from "./stories/components/ChatModal/ChatModal";
import { CookieBanner } from "./stories/components/CookieBanner/CookieBanner";
import { EngineerPromoListModal } from "./stories/components/EngineerPromoListModal/EngineerPromoListModal";
import { GenerateBookingInfo } from "./stories/components/GenerateBookingInfo/GenerateBookingInfo";
import { BottomNav } from "./stories/components/Navigation/BottomNav/BottomNav";
import {
  DEFAULT_TAB_OVERLAY_CLASS,
  useBottomTabBarOverlayView,
} from "./stories/components/Navigation/BottomNav/useBottomTabBarOverlayView";
import PowerUserControlsHelpModal from "./stories/components/PowerUserControlsHelpModal/PowerUserControlsHelpModal";
import ScrollToTop from "./stories/components/ScrollToTopHoc/ScrollToTopHoc";
import { SearchFiltersPanel } from "./stories/components/SearchFilters/SearchFiltersPanel/SearchFiltersPanel";
import { UnauthenticatedModal } from "./stories/components/UnauthenticatedModal/UnauthenticatedModal";
import { SoundWaveLoader } from "./stories/elements/SoundWaveLoader/SoundWaveLoader";
import WrappedArtistOnboardingSurveyForm from "./stories/forms/ArtistOnboardingSurveyForm/ArtistOnboardingSurveyForm";
import { MaintenanceWrapper } from "./stories/screens/MaintenanceScreen/MaintenanceWrapper";
import { chatModalDisplayAtom } from "./stories/components/ChatModal/atoms";
import ChatSuspense from "./stories/components/ChatInjector/ChatSuspense";
import { AccountSettingsModal } from "./stories/components/AccountSettingsModal/AccountSettingsModal";
import { accountSettingsModalOpenAtom } from "./stories/components/AccountSettingsModal/atoms";
import AuthFlow from "./stories/components/AuthFlow/AuthFlow";
import { StudioCreationModal } from "./stories/components/AuthFlow/components/StudioCreationModal";

const UiContent = () => {
  const dispatch = useAppDispatch();
  const hasConsent = useAtomValue(consentAtom);
  const showModal = useAtomValue(chatModalDisplayAtom);
  const [isAccountSettingsModalOpen, setIsAccountSettingsModalOpen] = useAtom(
    accountSettingsModalOpenAtom,
  );
  const { showLoginModal } = useAppSelector((state) => state.userInterface);
  const location = useLocation();
  const [isGeneratedBookingModalOpen, setIsGeneratedBookingModalOpen] = useAtom(
    displayGenerateBookingInfoAtom,
  );

  const stickyFooterButton = useBottomTabBarOverlayView(
    typeof hasConsent === "undefined",
    <CookieBanner />,
    DEFAULT_TAB_OVERLAY_CLASS,
  );

  return (
    <>
      <ScrollToTop />
      <Suspense
        fallback={
          <div id={"fallback"} className="container" style={{ margin: "auto" }}>
            <SoundWaveLoader
              width={100}
              height={100}
              styles={{ margin: "auto" }}
            />
          </div>
        }
      >
        <MaintenanceWrapper>
          <Outlet />
        </MaintenanceWrapper>
      </Suspense>
      <AuthFlow />
      <StudioCreationModal />
      {isAccountSettingsModalOpen && (
        <AccountSettingsModal
          onClose={() => {
            setIsAccountSettingsModalOpen(false);
          }}
        />
      )}
      <SearchFiltersPanel />
      {/* TODO: Move this modal to the favorites button? and to the livestream page? */}
      <UnauthenticatedModal
        closeModal={() => {
          if (showLoginModal) {
            dispatch(toggleLoginModal());
          }
        }}
        showModal={showLoginModal}
        message={
          location.pathname == SCREENS.LIVESTREAM_SCREEN
            ? "Please sign in to join the stream"
            : "Please sign in to add favorites"
        }
      />
      {showModal && (
        <ChatSuspense>
          <ChatModal />
        </ChatSuspense>
      )}
      {/* TODO: Move this modal to the inside of auth modal  */}
      <WrappedArtistOnboardingSurveyForm />
      <PowerUserControlsHelpModal />
      <EngineerPromoListModal />
      <GenerateBookingInfo
        isOpen={isGeneratedBookingModalOpen}
        setIsOpen={setIsGeneratedBookingModalOpen}
      />
      {stickyFooterButton}
      <BottomNav />
    </>
  );
};

export default UiContent;
