import { FC } from "react";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import "./NavLoginContainer.css";
import { DesktopNavItems } from "../DesktopNavItems/DesktopNavItems";
import Link from "../../../elements/Link/Link";
import { PAGE_HASH } from "../../../../atoms/location/locationAtom";

interface NavLoginContainerProps {
  column?: boolean;
  onClick?: () => void;
  showSignUpButton?: boolean;
}

export const NavLoginContainer: FC<NavLoginContainerProps> = ({
  column = false,
  onClick,
  showSignUpButton = true,
}: NavLoginContainerProps) => {
  const { isMobile } = useMediaQueryBreakpoint();

  return (
    <div
      className={`nav-login-container ${column ? "nav-login-container--column" : ""}`}
    >
      {!isMobile && <DesktopNavItems />}
      {showSignUpButton && (
        <Button
          className="nav-login-button"
          data-testid={"nav-sign-up"}
          fullWidth
          LinkComponent={Link}
          {...{ to: PAGE_HASH.SIGN_UP }}
          analyticsEvent={{
            name: "clicked_sign_up",
          }}
          variant={ButtonVariant.OUTLINED}
        >
          Sign up
        </Button>
      )}
      <Button
        className="nav-login-button"
        data-testid={"nav-login"}
        fullWidth
        LinkComponent={Link}
        {...{ to: PAGE_HASH.SIGN_IN }}
        analyticsEvent={{
          name: "clicked_login",
        }}
      >
        Login
      </Button>
    </div>
  );
};
