import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { Provider as JotaiProvider, useAtomValue } from "jotai";
import { createRoot } from "react-dom/client";
import * as Geocode from "react-geocode";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { registerSW } from "virtual:pwa-register";
import App from "./App";
import { consentAtom } from "./atoms/consentAtom";
import {
  ENV,
  INSTANCE_URL,
  IS_DEVELOPMENT_MODE,
  VERSION,
} from "./constants/generated";
import "./index.css";
import {
  DOLBY_ATMOS_LANDING,
  DREAMVILLE_LANDING,
  MIXHER_LANDING,
  NAMM_LANDING,
  ROUTE_PREFIXES,
  SOUND_OF_TOMORROW_LANDING,
} from "./routes";
import { AuthenticatedRoute } from "./routes/AuthenticatedRoute/AuthenticatedRoute";
import * as Pages from "./routes/lazy";
import { SCREENS } from "./routes/screens";
import store, { persistor } from "./store";
import { GOOGLE_API } from "./store/utils";
import { AppScreenContent } from "./stories/components/AppScreenContent/AppScreenContent";
import LoadingScreen from "./stories/components/LoadingScreen/LoadingScreen";
import NotFoundScreen from "./stories/screens/NotFoundScreen/NotFoundScreen";
import { jotaiStore } from "./utils/jotaiStore";
import { msalConfig } from "./utils/umgAuthConfig";
import { DevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";
import { darkModeAtom } from "./atoms/user/darkModeAtom";
import { showJotaiDevToolsAtom } from "./atoms/dev/jotaiDevtoolsAtom";

registerSW({ immediate: true });
// Used for UMG SSO authentication.
const msalInstance = new PublicClientApplication(msalConfig);
const datadogVersion = `${VERSION}-${INSTANCE_URL ? "firebase" : "django"}`;
const hasConsent = jotaiStore.get(consentAtom);

Geocode.setKey(GOOGLE_API);
Geocode.setLanguage("en");
Geocode.setRegion("us");

if (!IS_DEVELOPMENT_MODE) {
  datadogLogs.init({
    clientToken: "pub98e8687c13143116e0595d0a98473cb4",
    site: "us5.datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: "engineears",
    trackingConsent: `${!hasConsent ? "not-" : ""}granted`,
    env: ENV,
    version: datadogVersion,
  });

  datadogRum.init({
    applicationId: "84b62862-2443-4ce0-b664-457a5f78247c",
    clientToken: "pub98e8687c13143116e0595d0a98473cb4",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "us5.datadoghq.com",
    service: "engineears",
    trackingConsent: `${!hasConsent ? "not-" : ""}granted`,
    env: ENV,
    allowedTracingUrls: [
      "http://localhost:8080/api/",
      /https:\/\/.*\.engineears\.com\/api\//,
      /https:\/\/.*\..*-?engineears-215922\.appspot\.com\/api\//,
    ],
    // Specify a version number to identify the deployed version of your application in Datadog
    version: datadogVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    enableExperimentalFeatures: ["feature_flags"],
    defaultPrivacyLevel: "mask-user-input",
  });
}

const LayoutJotaiWrapper = () => {
  const darkMode = useAtomValue(darkModeAtom);
  const showJotaiDevTools = useAtomValue(showJotaiDevToolsAtom);
  return (
    <>
      {showJotaiDevTools && (
        <DevTools
          position={"top-left"}
          store={jotaiStore}
          theme={darkMode ? "dark" : "light"}
        />
      )}
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <PersistGate loading={<LoadingScreen />} persistor={persistor}>
            <App />
          </PersistGate>
        </MsalProvider>
      </Provider>
    </>
  );
};

const Layout = () => {
  return (
    <JotaiProvider store={jotaiStore}>
      <LayoutJotaiWrapper />
    </JotaiProvider>
  );
};

// 3️⃣ Router singleton created
const routes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      { path: SCREENS.PROFILE_SCREEN, element: <Pages.ProfileScreenPage /> },
      {
        path: SCREENS.HOME_SCREEN,
        // By setting hidden to true, we redirect to `/for-all` to any unauthenticated user
        element: <AuthenticatedRoute hidden={true} />,
        children: [
          {
            index: true,
            element: <Navigate to={SCREENS.DASHBOARD} />,
          },
        ],
      },
      {
        path: SCREENS.ADMIN_PERFORMANCE_CHART_SCREEN,
        element: <Pages.AdminPerformanceChartScreenPage />,
      },
      {
        path: SCREENS.PAGINATED_STUDIOS,
        element: <Pages.PaginatedVerifiedStudioRoomsScreenPage />,
      },
      {
        path: SCREENS.PAGINATED_ENGINEERS,
        element: <Pages.PaginatedVerifiedEntityScreenPage />,
      },
      {
        path: SCREENS.DASHBOARD_FAVORITES,
        element: <Pages.FavoritesScreenPage />,
      },
      {
        path: SCREENS.RECORDING_SESSION,
        element: <Pages.RecordingSessionScreenPage />,
      },
      {
        path: SCREENS.AANDR_SETUP_SCREEN,
        element: <Pages.AandRSetupScreenPage />,
      },
      {
        path: SCREENS.DOLBY_REGISTRATION_CHECKPOINT,
        element: <Pages.DolbyCourseRegistrationPage />,
      },
      {
        path: SCREENS.PRO_TOOLS_REGISTRATION_CHECKPOINT,
        element: <Pages.ProToolRegistrationPage />,
      },
      {
        path: SCREENS.NAMM_REGISTRATION_CHECKPOINT,
        element: <Pages.NammRegistrationPage />,
      },
      {
        path: SCREENS.USER_FEEDBACK_RATING,
        element: <Pages.UserSatisfactionRatingPage />,
      },
      {
        path: SCREENS.SCHEDULED_PROJECT_OVERVIEW_SCREEN,
        element: <Pages.ScheduledProjectOverviewScreenPage />,
      },

      {
        path: SCREENS.MOBILE_CHECKOUT,
        element: <Pages.MobileCheckoutScreenPage />,
      },
      { path: SCREENS.STUDIO_ROOM, element: <Pages.StudioRoomScreenPage /> },
      { path: SCREENS.STUDIO_SCREEN, element: <Pages.StudioScreenPage /> },
      {
        path: SCREENS.UPLOAD_SCREEN,
        element: <Pages.LoggedOutUploadScreenPage />,
      },
      {
        path: SCREENS.LOGGED_OUT_SESSION_DETAILS,
        element: <Pages.LoggedOutRecordingSessionDetailsScreenPage />,
      },
      {
        path: SCREENS.PASSWORD_RESET,
        element: <Pages.ResetPasswordScreenPage />,
      },
      { path: SCREENS.SIGNED_OUT_HOME_SCREEN, element: <Pages.ForAllPage /> },
      { path: SCREENS.SIGN_IN_SCREEN, element: <Pages.ForAllPage /> },
      { path: SCREENS.SIGN_UP_SCREEN, element: <Pages.ForAllPage /> },
      { path: SCREENS.SETTINGS_SCREEN, element: <Pages.ForAllPage /> },
      {
        path: SCREENS.DASHBOARD,
        element: <AuthenticatedRoute />,
        children: [
          {
            index: true,
            element: <Pages.ModularDashboardPage />,
          },
        ],
      },
      {
        path: ROUTE_PREFIXES.CUSTOMER_PORTAL,
        element: <AuthenticatedRoute />,
        children: [
          {
            index: true,
            path: "*",
            element: <Pages.CustomerPortalHomeScreenPage />,
          },
        ],
      },
      {
        path: SCREENS.KLARNA_PAYMENT_REDIRECT,
        element: <Pages.KlarnaPaymentRedirectScreenPage />,
      },
      {
        path: SCREENS.CASH_APP_REDIRECT,
        element: <Pages.CashAppPaymentRedirectScreenPage />,
      },
      {
        path: SCREENS.AFFIRM_PAYMENT_REDIRECT,
        element: <Pages.AffirmPaymentRedirectScreenPage />,
      },
      {
        path: SCREENS.MUSO_AUTH_REDIRECT_SCREEN,
        element: <Pages.MusoAuthRedirectScreenPage />,
      },
      {
        path: SCREENS.MUSO_SELECTION_SCREEN,
        element: <Pages.MusoSelectionScreenPage />,
      },
      { path: SCREENS.VERIFY_EMAIL, element: <Pages.VerifyEmailScreenPage /> },
      {
        path: SCREENS.SETUP_STRIPE,
        element: <Pages.StripeSetupRedirectPage />,
      },
      {
        path: SCREENS.NOTIFICATIONS,
        element: <Pages.MobileNotificationsScreenPage />,
      },
      {
        path: ROUTE_PREFIXES.PROJECTS,
        children: [{ index: true, path: "*", element: <Pages.ProjectsPage /> }],
      },
      { path: SCREENS.PROJECT, element: <Pages.ProjectScreenPage /> },
      { path: SCREENS.COMMUNITY, element: <Pages.CommunityPage /> },
      {
        path: SCREENS.MOBILE_PROJECT_REVIEW,
        element: <Pages.MobileProjectReviewScreenPage />,
      },
      {
        path: SCREENS.FIND_MY_ENGINEER,
        element: <Pages.FindMyEngineerScreenPage />,
      },
      { path: SCREENS.SEARCH, element: <Pages.ExploreScreenPage /> },
      {
        path: SCREENS.ADD_SUBSCRIPTION,
        element: <Pages.AddSubscriptionScreenPage />,
      },
      {
        path: SCREENS.LOAD_PREDEFINED_PROJECT,
        element: <Pages.LoadPredefinedProjectScreenPage />,
      },
      {
        path: SCREENS.TRANSACTION_OVERVIEW,
        element: <Pages.TransactionOverviewScreenPage />,
      },
      {
        path: SCREENS.ONBOARDING_RSVP_SCREEN,
        element: <Pages.OnboardingRSVPScreenPage />,
      },
      {
        path: SCREENS.TEAM_INVITE_SCREEN,
        element: <Pages.TeamInviteScreenPage />,
      },
      {
        path: SCREENS.ADMIN_DASHBOARD,
        element: <Pages.AdminDashboardScreenPage />,
      },
      { path: SCREENS.BLOG_SCREEN, element: <Pages.BlogScreenPage /> },
      {
        path: SCREENS.TRANSACTION_BOOKING_SCREEN,
        element: <Pages.TransactionBookingScreenPage />,
      },
      {
        path: SCREENS.LIVESTREAM_SCREEN,
        element: <Pages.LivestreamScreenPage />,
      },
      {
        path: SCREENS.FEATURED_TRACK_REQUEST,
        element: <Pages.FeaturedTrackReviewPage />,
      },
      {
        path: SCREENS.SELECT_FEATURED_TRACK,
        element: <Pages.SelectFeaturedTrackScreenPage />,
      },
      {
        path: SCREENS.SUBSCRIPTION_SUCCESS,
        element: <Pages.SubscriptionSuccessScreenPage />,
      },
      {
        path: SCREENS.LOGGED_OUT_REVIEW,
        element: <Pages.LoggedOutReviewScreenPage />,
      },
      {
        path: ROUTE_PREFIXES.BOOKINGS,
        element: <AuthenticatedRoute />,
        children: [
          { index: true, path: ":tab", element: <Pages.BookingsScreenPage /> },
        ],
      },
      {
        path: SCREENS.RELEASE_CHECKOUT,
        element: <Pages.ReleaseCheckoutScreenPage />,
      },
      {
        path: SCREENS.RELEASE_DETAIL,
        element: <Pages.ReleaseDetailScreenPage />,
      },
      {
        path: SCREENS.MY_LIBRARY,
        children: [{ index: true, element: <Pages.MyLibraryPage /> }],
        element: <AuthenticatedRoute />,
      },
      { path: SCREENS.BEAT_MATRIX, element: <Pages.BeatMatrixScreenPage /> },
      {
        path: SCREENS.BEAT_MATRIX_SUBMISSIONS,
        element: <Pages.BeatMatrixSubmissionScreenPage />,
      },
      {
        path: SCREENS.MESSAGES,
        caseSensitive: false,
        children: [{ index: true, element: <Pages.MessagesPage /> }],
        element: <AuthenticatedRoute />,
      },
      {
        path: SCREENS.THE_SOUND_OF_TOMORROW,
        element: <Pages.ExternalPage path={SOUND_OF_TOMORROW_LANDING} />,
      },
      {
        path: SCREENS.SOUND_OF_TOMORROW,
        element: <Pages.ExternalPage path={SOUND_OF_TOMORROW_LANDING} />,
      },
      {
        path: SCREENS.DREAMVILLE,
        element: <Pages.ExternalPage path={DREAMVILLE_LANDING} />,
      },
      {
        path: SCREENS.SEEING_SOUNDS,
        element: <Pages.ExternalPage path={DREAMVILLE_LANDING} />,
      },
      {
        path: SCREENS.MIXHER,
        element: <Pages.ExternalPage path={MIXHER_LANDING} />,
      },
      {
        path: SCREENS.DOLBY_ATMOS,
        element: <Pages.ExternalPage path={DOLBY_ATMOS_LANDING} />,
      },
      {
        path: SCREENS.NAMM,
        element: <Pages.ExternalPage path={NAMM_LANDING} />,
      },
      {
        path: "*",
        element: <NotFoundScreen />,
      },
    ],
  },
];
if (ENV === "development" && routes[0] && routes[0].children) {
  routes[0].children.push({
    path: SCREENS.DISTRIBUTION,
    element: <Pages.DistributionScreenPage />,
  });
}
const router = createBrowserRouter(routes);
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <RouterProvider
    fallbackElement={<AppScreenContent isLoading={true} />}
    router={router}
  />,
);
