import { AUTH_GOOGLE } from "../../store/utils/routes";
import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { promiseEngineEarsHandler } from "../helpers";
import User from "../../store/models/user";

export interface AuthGoogleParams {
  credentials: string;
  clientId: string;
}

interface GoogleAuthResponse {
  user: User;
  token: string;
  created: boolean;
}

export const postAuthGoogle = async (params: AuthGoogleParams) => {
  return makeBackendPostCallWithJsonResponse<GoogleAuthResponse>(
    AUTH_GOOGLE,
    params,
  ).then(promiseEngineEarsHandler);
};
