import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { toast } from "react-toastify";

interface SignUpOrInWithGoogleProps {
  onSuccess: (credentialResponse: CredentialResponse) => void;
}

export const SignUpOrInWithGoogle = ({
  onSuccess = () => {},
}: SignUpOrInWithGoogleProps) => {
  return (
    <GoogleLogin
      click_listener={() => {
        emitAnalyticsTrackingEvent("auth_click_on_google_login", {});
      }}
      onSuccess={onSuccess}
      onError={() => {
        toast.error("Unable to sign in via Google.");
      }}
      useOneTap
      use_fedcm_for_prompt
    />
  );
};
