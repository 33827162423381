import { useMemo } from "react";
import {
  AccountSettingsTextField,
  AccountSettingsTextFieldProps,
} from "../../AccountSettingsModal/tabs/AccountSettingsTextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material";

interface UsernameFieldProps extends AccountSettingsTextFieldProps {
  emailOnly?: boolean;
  isValid?: boolean;
}

export const EmailOrUsernameField = ({
  emailOnly,
  isValid,
  TextFieldProps,
  ...props
}: UsernameFieldProps) => {
  const label = useMemo(
    () => (emailOnly ? "Email" : "Email or Username"),
    [emailOnly],
  );
  const theme = useTheme();
  return (
    <AccountSettingsTextField
      label={label}
      name={"username"}
      TextFieldProps={{
        placeholder: label,
        InputProps: isValid
          ? {
              endAdornment: (
                <FontAwesomeIcon
                  icon={faCheck}
                  color={theme.palette.success.main}
                />
              ),
            }
          : {},
        ...TextFieldProps,
      }}
      {...props}
    />
  );
};
