import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { Box } from "@mui/material";
import ProfileSelectionBody from "../../AuthFlow/components/steps/ProfileSelectionStep/ProfileSelectionBody";
import { activeUserAtom } from "../../../../atoms/user/activeUserAtom";
import {
  DISCIPLINE_TYPE,
  disciplineTypeToRegisterEventMap,
  getDisciplineString,
} from "../../../../hooks/user";
import { useAtomValue } from "jotai";
import {
  FormEvent,
  FormEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import handleProfileSelectionFormSubmitFactory, {
  ProfileSelectionForm,
} from "../../AuthFlow/components/steps/ProfileSelectionStep/handleProfileSelectionFormSubmitFactory";
import { QUERY_KEYS } from "../../../../constants/queryKeys";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { useAppDispatch } from "../../../../store/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { ServicesFormModalFooter } from "../../../screens/ProfileScreen/components/ServicesFormModalFooter";
import Form from "../../../elements/Form/Form";
import { HandleProfileSelectionFormSubmitFactoryParams } from "../../AuthFlow/components/steps/ProfileSelectionStep/types";

interface AccountTypeProps {
  onClose: () => void;
}

// This should be kept in sync with ProfileSelectionStep.tsx
export const AccountType = ({ onClose }: AccountTypeProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const activeUser = useAtomValue(activeUserAtom);
  const isLabelUser = useMemo(() => {
    return Boolean(
      activeUser?.disciplines?.aandr || activeUser?.disciplines?.admin,
    );
  }, [activeUser?.disciplines?.aandr, activeUser?.disciplines?.admin]);

  const initialValues = useMemo(() => {
    const result: Partial<Record<DISCIPLINE_TYPE, boolean>> = {};
    if (activeUser?.disciplines) {
      if (activeUser?.disciplines.admin) {
        result[DISCIPLINE_TYPE.ADMIN] = true;
      }
      if (activeUser?.disciplines.aandr) {
        result[DISCIPLINE_TYPE.A_AND_R] = true;
      }
      if (activeUser?.disciplines.engineer) {
        result[DISCIPLINE_TYPE.ENGINEER] = true;
      }
      if (activeUser?.disciplines.artist) {
        result[DISCIPLINE_TYPE.ARTIST] = true;
      }
      if (activeUser?.disciplines.producer) {
        result[DISCIPLINE_TYPE.PRODUCER] = true;
      }
      if (activeUser?.disciplines.studio_manager) {
        result[DISCIPLINE_TYPE.STUDIO_MANAGER] = true;
      }
      if (activeUser?.disciplines.listener) {
        result[DISCIPLINE_TYPE.LISTENER] = true;
      }
      if (activeUser?.disciplines.other) {
        result[DISCIPLINE_TYPE.OTHER] = true;
      }
    }
    return result;
  }, [activeUser?.disciplines]);

  const [clickedDisciplines, setClickedDisciplines] = useState<
    DISCIPLINE_TYPE[]
  >(Object.keys(initialValues).map((key) => parseInt(key) as DISCIPLINE_TYPE));

  const hasOther = useMemo(
    () => clickedDisciplines.includes(DISCIPLINE_TYPE.OTHER),
    [clickedDisciplines],
  );
  const hasStudioManager = useMemo(
    () => clickedDisciplines.includes(DISCIPLINE_TYPE.STUDIO_MANAGER),
    [clickedDisciplines],
  );
  const hasAny = useMemo(
    () => clickedDisciplines.length > 0,
    [clickedDisciplines.length],
  );

  const handleClickDiscipline = useCallback(
    (discipline: DISCIPLINE_TYPE) => {
      const itemIndex = clickedDisciplines.indexOf(discipline);
      if (itemIndex !== -1) {
        setClickedDisciplines((current) => {
          const result = [...current];
          result.splice(itemIndex, 1);
          return result;
        });
      } else {
        setClickedDisciplines((current) => [...current, discipline]);
      }
    },
    [clickedDisciplines, setClickedDisciplines],
  );

  const { mutateAsync: updateProfile, isPending } = useMutation({
    mutationKey: ["update-profile-account-type"],
    mutationFn: async (args: {
      e: FormEvent<ProfileSelectionForm>;
      args: HandleProfileSelectionFormSubmitFactoryParams;
    }) => {
      args.e.preventDefault();
      return handleProfileSelectionFormSubmitFactory(args.args)(args.e);
    },
  });

  const handleSubmit: FormEventHandler<ProfileSelectionForm> = useCallback(
    async (e) =>
      updateProfile({
        e,
        args: {
          dispatch,
          userDisciplines: activeUser?.disciplines,
          primaryDiscipline: clickedDisciplines[0],
          initialPrimaryDiscipline: activeUser?.primaryDiscipline,
        },
      }).then(async () => {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.LOAD_USER],
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_USER_PROFILE, activeUser?.username],
        });
        const eventName =
          disciplineTypeToRegisterEventMap.get(clickedDisciplines[0]) ||
          "unknown_primary_account_type_registered";
        emitAnalyticsTrackingEvent(eventName, {
          user_id: activeUser?.id,
          username: activeUser?.username,
          email: activeUser?.email,
          all_account_types: clickedDisciplines
            .map(getDisciplineString)
            .join(" / "),
          account_type: getDisciplineString(clickedDisciplines[0]),
        });
        onClose();
        return false;
      }),

    [
      activeUser,
      queryClient,
      dispatch,
      clickedDisciplines,
      onClose,
      updateProfile,
    ],
  );
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: "24px",
        gap: "32px",
      }}
      component={Form}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e as FormEvent<ProfileSelectionForm>);
      }}
      id={"account-type-form"}
    >
      <Text
        variant={TextStyleVariant.P1}
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Update/select an additional profile type below
      </Text>
      <ProfileSelectionBody
        onClickDiscipline={handleClickDiscipline}
        primaryDiscipline={clickedDisciplines[0]}
        isLabelUser={isLabelUser}
        initialValues={initialValues}
        showBothOptions={false}
        hasOther={hasOther}
        hasAny={hasAny}
        hasStudioManager={hasStudioManager}
        otherDescription={activeUser?.other_description}
        labelEmailVerified={isLabelUser && Boolean(activeUser?.email_verified)}
      />

      <Button type={"submit"} ref={ref} sx={{ display: "none" }}>
        This is an invisible button that is triggered via JS to call the form
        properly. Hello random source code reader!
      </Button>
      <ServicesFormModalFooter>
        <Button
          disabled={isPending}
          fullWidth
          variant={ButtonVariant.OUTLINED}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          loading={isPending}
          disabled={clickedDisciplines.length === 0}
          // When the footer button is clicked, click a button hidden inside our form
          onClick={(e) => {
            e.preventDefault();
            ref.current?.click();
          }}
        >
          Save Settings
        </Button>
      </ServicesFormModalFooter>
    </Box>
  );
};
