import classNames from "classnames";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useResizeObserver } from "usehooks-ts";
import {
  bottomNavContainerHeightAtom,
  bottomNavHeightAtom,
  showBottomNavAtom,
} from "../../../../atoms/navAtoms";
import {
  useMediaQuery,
  useMediaQueryBreakpoint,
} from "../../../../hooks/useMediaQuery";
import { ROUTE_PREFIXES } from "../../../../routes";
import { SCREENS } from "../../../../routes/screens";
import { useAppSelector } from "../../../../store/hooks";
import { isUserVerifiedAdminSelector } from "../../../../store/selectors/userInfoSelectors";
import { getProfileScreenRoute } from "../../../../store/utils/routeGetters";
import { isUserListenerOnly } from "../../../../utils/utils";
import { NavSpacer } from "../../../screens/NavSpacer/NavSpacer";
import { FooterPlayer } from "../../FooterPlayer/FooterPlayer";
import { Size } from "../../SessionScheduleGuide/SessionScheduleGuide";
import "./BottomNav.css";
import { BottomNavContainer } from "./BottomNav.styles";
import { NavItem, NavTabButtonEnum } from "./NavItem";
import {
  BOTTOM_TAB_BAR_FOCUSED_OVERLAY_ID,
  BOTTOM_TAB_BAR_OVERLAY_ID,
  DRAWER_CONTAINER_ID,
} from "./useBottomTabBarOverlayView";
import { activeProfileAtom } from "../../../../atoms/user/activeProfileAtom";
import { isActiveUserEngineerOrStudioManagerAtom } from "../../../../atoms/user/activeUserAtom";
import ChecklistWrapper from "../../Onboarding/ChecklistWrapper";
import { Box } from "@mui/material";
import { PAGE_HASH } from "../../../../atoms/location/locationAtom";

const navButtonsMap = new Map([
  [
    NavTabButtonEnum.ADMIN_DASHBOARD,
    {
      title: "Dashboard",
      path: SCREENS.ADMIN_DASHBOARD,
    },
  ],
  [
    NavTabButtonEnum.DASHBOARD,
    {
      title: "Dashboard",
      path: SCREENS.DASHBOARD,
    },
  ],
  [
    NavTabButtonEnum.PROJECTS,
    {
      title: "Bookings",
      path: SCREENS.PROJECTS_NO_TAB,
      linkPaths: [ROUTE_PREFIXES.BOOKINGS], // The projects screen can redirect to a new screen that is located under this route
    },
  ],
  [
    NavTabButtonEnum.MY_LIBRARY,
    {
      title: "Library",
      path: SCREENS.MY_LIBRARY,
    },
  ],
  [
    NavTabButtonEnum.NOTIFICATIONS,
    {
      title: "Notifications",
      path: SCREENS.NOTIFICATIONS,
    },
  ],
  [
    NavTabButtonEnum.MESSAGES,
    {
      title: "Messages",
      path: SCREENS.MESSAGES,
    },
  ],
  [
    NavTabButtonEnum.COMMUNITY,
    {
      title: "Community",
      path: SCREENS.COMMUNITY,
    },
  ],
  [
    NavTabButtonEnum.SEARCH,
    {
      title: "Search",
      path: SCREENS.SEARCH,
    },
  ],
  [
    NavTabButtonEnum.PROFILE,
    {
      title: "Profile",
      path: SCREENS.PROFILE_SCREEN,
    },
  ],
  [
    NavTabButtonEnum.ARTIST_HOME,
    {
      title: "Home",
      path: SCREENS.SIGNED_OUT_HOME_SCREEN,
    },
  ],
  [
    NavTabButtonEnum.SIGN_IN,
    {
      title: "Login",
      path: PAGE_HASH.SIGN_IN,
    },
  ],
  [
    NavTabButtonEnum.SIGN_UP,
    {
      title: "Sign Up",
      path: PAGE_HASH.SIGN_UP,
    },
  ],
]);

export const BottomNav = () => {
  const { isDesktop } = useMediaQueryBreakpoint();
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const showBottomNav = useAtomValue(showBottomNavAtom);
  const [height, setHeight] = useAtom(bottomNavHeightAtom);
  const setContainerHeight = useSetAtom(bottomNavContainerHeightAtom);
  const elementRef = useRef<HTMLDivElement>(null);
  const containerElementRef = useRef<HTMLDivElement>(null);
  const onResizeContainer = useCallback(
    (size: Size) => {
      setTimeout(() => {
        setContainerHeight(size.height || 0);
      }, 0);
    },
    [setContainerHeight],
  );
  const onResize = useCallback(
    (size: Size) => {
      setTimeout(() => {
        setHeight(size.height || 0);
      }, 0);
    },
    [setHeight],
  );
  useResizeObserver({
    ref: elementRef,
    box: "border-box",
    onResize,
  });
  useResizeObserver({
    ref: containerElementRef,
    box: "border-box",
    onResize: onResizeContainer,
  });
  const currentLoggedInUser = useAppSelector((state) => state.accountInfo.user);
  const userIsEngineerOrStudioManager = useAtomValue(
    isActiveUserEngineerOrStudioManagerAtom,
  );
  const userIsAdmin = useAppSelector(isUserVerifiedAdminSelector);
  const userIsListenerOnly = currentLoggedInUser
    ? isUserListenerOnly(currentLoggedInUser)
    : false;
  const isTooNarrowForText = useMediaQuery("(max-width:745px)");
  const { pathname } = useLocation();

  const artistLoggedInTabs = [
    NavTabButtonEnum.PROFILE,
    NavTabButtonEnum.NOTIFICATIONS,
    NavTabButtonEnum.PROJECTS,
    NavTabButtonEnum.MESSAGES,
  ];
  const engineerLoggedInTabs = [
    NavTabButtonEnum.DASHBOARD,
    NavTabButtonEnum.NOTIFICATIONS,
    NavTabButtonEnum.PROJECTS,
    NavTabButtonEnum.MESSAGES,
    NavTabButtonEnum.COMMUNITY,
  ];
  const adminLoggedInTabs = [
    NavTabButtonEnum.ADMIN_DASHBOARD,
    NavTabButtonEnum.NOTIFICATIONS,
    NavTabButtonEnum.PROJECTS,
    NavTabButtonEnum.MESSAGES,
    NavTabButtonEnum.COMMUNITY,
  ];
  const loggedOutTabs = [
    NavTabButtonEnum.ARTIST_HOME,
    NavTabButtonEnum.COMMUNITY,
    NavTabButtonEnum.SEARCH,
    NavTabButtonEnum.SIGN_UP,
    NavTabButtonEnum.SIGN_IN,
  ];
  const listenerOnlyTabs = [
    NavTabButtonEnum.PROFILE,
    NavTabButtonEnum.NOTIFICATIONS,
    NavTabButtonEnum.MY_LIBRARY,
    NavTabButtonEnum.MESSAGES,
  ];

  const activeProfile = useAtomValue(activeProfileAtom);

  const currentTabs = useMemo(() => {
    if (!currentLoggedInUser) {
      return loggedOutTabs;
    }
    if (userIsListenerOnly) {
      return listenerOnlyTabs;
    }
    if (userIsAdmin) {
      return adminLoggedInTabs;
    }
    if (!userIsEngineerOrStudioManager) {
      return artistLoggedInTabs;
    }
    return engineerLoggedInTabs;
  }, [isAuthenticated, userIsEngineerOrStudioManager, activeProfile]);

  const selectedProfileUserName = activeProfile?.username;

  return (
    <>
      <NavSpacer variant="bottom" />
      <BottomNavContainer
        className={classNames({
          "nav-tab-container": true,
          "nav-tab-hidden": !showBottomNav,
        })}
        $showNav={showBottomNav}
        $navItemsHeight={height}
        ref={containerElementRef}
      >
        <FooterPlayer />
        <div id={DRAWER_CONTAINER_ID} className="overlay-above-bottom-nav" />
        <div
          id={BOTTOM_TAB_BAR_FOCUSED_OVERLAY_ID}
          className="overlay-above-bottom-nav focused-overlay"
        />
        <div
          id={BOTTOM_TAB_BAR_OVERLAY_ID}
          className="overlay-above-bottom-nav"
        />
        <Box ref={elementRef}>
          <ChecklistWrapper />
          <div
            className={classNames({
              "nav-tab-items-container": true,
            })}
            style={{ display: isDesktop ? "none" : undefined }}
          >
            {currentTabs.map((tabKey, index) => {
              const nav = navButtonsMap.get(tabKey);
              let path = nav?.path.toString();
              path =
                nav?.path === SCREENS.PROFILE_SCREEN
                  ? getProfileScreenRoute(selectedProfileUserName)
                  : path;

              return (
                <NavItem
                  key={index}
                  currentPath={pathname}
                  navTabEnum={tabKey}
                  title={nav?.title ?? ""}
                  path={path!}
                  linkPaths={nav?.linkPaths}
                  showIconOnly={isTooNarrowForText}
                />
              );
            })}
            {isTooNarrowForText &&
              currentLoggedInUser &&
              !userIsEngineerOrStudioManager &&
              !userIsListenerOnly &&
              !userIsAdmin && (
                <NavItem
                  currentPath={pathname}
                  navTabEnum={NavTabButtonEnum.SEARCH}
                  title="Search"
                  path={SCREENS.SEARCH}
                  showIconOnly={isTooNarrowForText}
                />
              )}
          </div>
        </Box>
      </BottomNavContainer>
    </>
  );
};
