import { Box, styled } from "@mui/material";

export const FieldContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;
`;

export const UsernameChecksContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
