import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { PROFILE_ONBOARDING_SURVEY_DATA_API } from "../../store/utils/routes";
import { promiseEngineEarsHandler } from "../helpers";
import { compile } from "path-to-regexp";

export interface CreateProfileOnboardingSurveyDataArgs {
  username: string;
  profile_id: number;
}

export const createProfileOnboardingSurveyData = async (
  args: CreateProfileOnboardingSurveyDataArgs,
) => {
  return makeBackendPostCallWithJsonResponse<null>(
    compile(PROFILE_ONBOARDING_SURVEY_DATA_API)({
      profile_id: args.profile_id,
    }),
    { username: args.username },
  ).then(promiseEngineEarsHandler);
};

export default createProfileOnboardingSurveyData;
