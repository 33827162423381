import { useEffect, useLayoutEffect } from "react";
import useActiveUserQuery from "../../hooks/userHooks/useActiveUserQuery";
import { AppScreenContent } from "../../stories/components/AppScreenContent/AppScreenContent";
import { Outlet, useNavigate } from "react-router-dom";
import { SCREENS } from "../screens";
import { useAtom } from "jotai";
import { compactSignInOpenAtom } from "../../stories/components/AuthFlow/atoms";

interface AuthenticatedRouteProps {
  // If the route should show a sign in modal, or redirect to the home screen
  hidden?: boolean;
}

export const AuthenticatedRoute = ({ hidden }: AuthenticatedRouteProps) => {
  const [isRequired, setIsRequired] = useAtom(compactSignInOpenAtom);
  const { isLoading, data: activeUser } = useActiveUserQuery();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && !activeUser && hidden) {
      navigate(SCREENS.SIGNED_OUT_HOME_SCREEN, { replace: true });
    }
  }, [activeUser, navigate, isLoading, hidden]);

  // This prevents the user from closing sign in or sign up
  useLayoutEffect(() => {
    if (isLoading) return;
    if (!activeUser && !hidden) {
      if (!isRequired) {
        setIsRequired(true);
      }
    }
  }, [setIsRequired, isRequired, isLoading, activeUser, hidden]);

  if (isLoading) {
    return <AppScreenContent isLoading={true} />;
  }
  if (!activeUser && !hidden) {
    return null;
  }
  return <Outlet />;
};
