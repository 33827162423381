import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import {
  updateStudio,
  updateStudioProfileParams,
} from "../../store/actions/studio";
import { useAppDispatch } from "../../store/hooks";
import useActiveStudiosQuery from "../userHooks/useActiveStudiosQuery";

const useUpdateStudioMutation = () => {
  const dispatch = useAppDispatch();
  const { refetch } = useActiveStudiosQuery({});
  return useMutation({
    mutationKey: [QUERY_KEYS.UPDATE_STUDIO],
    mutationFn: async (params: updateStudioProfileParams) => {
      return dispatch(updateStudio(params))
        .unwrap()
        .then((studio) => {
          void refetch();
          return studio;
        });
    },
  });
};

export default useUpdateStudioMutation;
