import { useMutation } from "@tanstack/react-query";
import {
  verifyAccountEmail,
  VerifyEmailArgs,
} from "../../store/actions/accountInfo";
import { useAppDispatch } from "../../store/hooks";

const useVerifyAccountMutation = () => {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationKey: ["verify_email"],
    mutationFn: (args: VerifyEmailArgs) => {
      return dispatch(verifyAccountEmail(args));
    },
  });
};

export default useVerifyAccountMutation;
