import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import queryString from "query-string";
import { SignInPhoneNumberInputField } from "./SignInPhoneNumberInputField";
import { SignInPhoneNumberVerificationField } from "./SignInPhoneNumberVerificationField";

const SignInPhone = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [verificationView, setVerificationView] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleSuccessfulSignIn = useCallback(() => {
    navigate(
      {
        hash: "#",
        search: queryString.stringify({
          ...queryString.parse(search),
          method: undefined,
        }),
      },
      { replace: true },
    );
  }, [search, navigate]);

  return verificationView ? (
    <SignInPhoneNumberVerificationField
      phoneNumber={phoneNumber}
      onCancel={() => setVerificationView(false)}
      onComplete={handleSuccessfulSignIn}
    />
  ) : (
    <SignInPhoneNumberInputField
      onSave={(newNumber) => {
        setPhoneNumber(newNumber);
        setVerificationView(true);
      }}
      originalNumber={phoneNumber}
    />
  );
};

export default SignInPhone;
