import { makeBackendPostCallWithJsonResponse } from "../../store/utils/fetch";
import { RESEND_EMAIL_VERIFICATION } from "../../store/utils/routes";
import { promiseEngineEarsHandler } from "../../api/helpers";

export interface ResendEmailVerificationArgs {
  send_verification_code?: boolean;
  email_or_username?: string;
}

export const sendEmailVerification = async (
  args: ResendEmailVerificationArgs,
) => {
  return makeBackendPostCallWithJsonResponse(
    RESEND_EMAIL_VERIFICATION,
    args,
  ).then(promiseEngineEarsHandler);
};
