import { FormEvent } from "react";
import { convertToSignUpOrInMethodsEnum, SignUpOrInFormElement } from "./types";
import {
  validateEmail,
  validateUmgEmail,
} from "./components/validators/emailValidators";

export const getMethodFromSearchParams = (searchParams: URLSearchParams) => {
  const method = searchParams.get("method") || "";
  if (convertToSignUpOrInMethodsEnum(method)) {
    return method;
  }
  return undefined;
};

export const isValidEmailUtil = (email: string) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const extractSignUpOrInDataFromForm = (
  e: FormEvent<SignUpOrInFormElement>,
) => {
  const { username, password } = e.currentTarget.elements;

  const validatedUsernameOrEmail = username.value.trim();
  const isEmail = validatedUsernameOrEmail.includes("@")
    ? Object.keys(validateEmail(validatedUsernameOrEmail)).length === 0
    : false;
  const isUmgEmail = isEmail
    ? Object.keys(validateUmgEmail(validatedUsernameOrEmail)).length === 0
    : false;
  let validatedPassword: string = "";
  try {
    validatedPassword = password.value;
  } catch {
    /* pass */
  }

  return {
    username: validatedUsernameOrEmail,
    isEmail,
    isUmgEmail,
    password: validatedPassword,
  };
};
