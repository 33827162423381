import { Divider, Grid2, useMediaQuery, useTheme } from "@mui/material";
import { ProfileTypeCard } from "./ProfileTypeCard";
import { LabelProfileTypes, ProfileTypes } from "./constants";
import { DISCIPLINE_TYPE } from "../../../../../../hooks/user";
import UMG_Logo_Black from "../../../../../assets/UMG Logo_Black.svg";
import {
  TextColor,
  TextStyleVariant,
} from "../../../../../core-ui/components/Text/TextUtils";
import { Text } from "../../../../../core-ui/components/Text/Text";
import { useMemo } from "react";
import FormOutlinedTextField from "../../../../../elements/Form/FormOutlinedTextField";

interface ProfileSelectionBodyProps {
  isLabelUser?: boolean;
  showBothOptions?: boolean;
  primaryDiscipline?: DISCIPLINE_TYPE;
  clickedDisciplines?: DISCIPLINE_TYPE[];
  onClickDiscipline?: (discipline: DISCIPLINE_TYPE, checked: boolean) => void;
  initialValues?: Partial<Record<DISCIPLINE_TYPE, boolean>>;
  labelEmailVerified?: boolean;
  hasOther?: boolean;
  hasStudioManager?: boolean;
  hasAny?: boolean;
  otherDescription?: string;
}
const ProfileSelectionBody = ({
  isLabelUser = false,
  showBothOptions = true,
  initialValues = {},
  onClickDiscipline,
  primaryDiscipline,
  labelEmailVerified,
  otherDescription,
  hasOther,
  hasStudioManager,
  hasAny,
}: ProfileSelectionBodyProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const bonusContent = useMemo(() => {
    return (
      <>
        {hasAny && !labelEmailVerified && (
          <Grid2
            size={{ xs: 12, lg: 12 }}
            sx={(theme) => ({
              backgroundColor: theme.palette.warning.main,
              borderRadius: theme.border.radius["sm"],
              padding: theme.spacing(2),
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            })}
          >
            <Text
              variant={TextStyleVariant.P2}
              color={TextColor.PRIMARY_NO_DARK_THEME}
              style={{
                textAlign: "center",
                maxWidth: "450px",
                fontWeight: "600",
              }}
            >
              {hasStudioManager
                ? "Note: if you would like to add an artist, engineer, or listener profile type, please do so in the account settings after signing up."
                : "Note: if you would like to add a studio owner profile type, please do so in the account settings after signing up."}
            </Text>
          </Grid2>
        )}
        {hasOther && (
          <Grid2 size={{ xs: 12, lg: 12 }}>
            <FormOutlinedTextField
              fullWidth
              autoFocus
              name={"otherDescription"}
              defaultValue={otherDescription}
              label={"Why did you join EngineEars?"}
              disabled={Boolean(otherDescription)}
              placeholder={"Why did you join engineers"}
            />
          </Grid2>
        )}
      </>
    );
  }, [
    otherDescription,
    labelEmailVerified,
    hasAny,
    hasOther,
    hasStudioManager,
  ]);

  return (
    <Grid2
      container
      justifyContent={"center"}
      spacing={2}
      alignItems={"stretch"}
      width={"100%"}
    >
      <Grid2
        size={{ xs: 12, lg: showBothOptions ? 7.9 : 12 }}
        sx={{
          display: isLabelUser && !showBothOptions ? "none" : undefined,
        }}
        spacing={2}
        container
      >
        <Grid2 container spacing={2} alignItems={"stretch"}>
          {ProfileTypes.map((key) => {
            return (
              <Grid2 key={key} size={{ xs: 12, md: 6 }}>
                <ProfileTypeCard
                  onClick={onClickDiscipline}
                  discipline={key}
                  defaultValue={initialValues[key]}
                  isDisabled={
                    isLabelUser ||
                    (showBothOptions &&
                      key !== DISCIPLINE_TYPE.STUDIO_MANAGER &&
                      DISCIPLINE_TYPE.STUDIO_MANAGER === primaryDiscipline) ||
                    (showBothOptions &&
                      Boolean(primaryDiscipline) &&
                      key === DISCIPLINE_TYPE.STUDIO_MANAGER &&
                      DISCIPLINE_TYPE.STUDIO_MANAGER !== primaryDiscipline)
                  }
                  isPrimary={key === primaryDiscipline}
                />
              </Grid2>
            );
          })}
        </Grid2>
        {showBothOptions ? bonusContent : null}
      </Grid2>

      <Grid2
        sx={{
          display: !showBothOptions ? "none" : undefined,
        }}
        size={{ xs: 12, lg: "auto" }}
      >
        <Divider
          orientation={isDesktop ? "vertical" : "horizontal"}
          variant="middle"
        />
      </Grid2>
      <Grid2
        size={{ xs: 12, lg: showBothOptions ? 3.9 : 12 }}
        sx={{
          display: !isLabelUser && !showBothOptions ? "none" : undefined,
        }}
      >
        <Grid2
          height={"100%"}
          container
          spacing={2}
          justifyContent={"center"}
          sx={(theme) => ({
            alignItems: "stretch",
            [theme.breakpoints.up("md")]: {
              alignItems: "center",
            },
            [theme.breakpoints.up("lg")]: {
              alignItems: showBothOptions ? "stretch" : "center",
            },
          })}
          flexDirection={"column"}
        >
          <Grid2
            size={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLabelUser && (
              <img
                style={{
                  margin: "0 auto",
                  maxWidth: "350px",
                  maxHeight: "120px",
                  height: "auto",
                  width: "100%",
                }}
                src={UMG_Logo_Black}
              />
            )}
          </Grid2>
          {LabelProfileTypes.map((key) => (
            <Grid2
              key={key}
              size={{ xs: 12, md: 6, lg: !showBothOptions ? 6 : 12 }}
            >
              <ProfileTypeCard
                onClick={onClickDiscipline}
                discipline={key}
                defaultValue={initialValues[key]}
                isDisabled={!isLabelUser}
                isPrimary={key === primaryDiscipline}
              />
            </Grid2>
          ))}
          <Grid2 size={12}>
            {!labelEmailVerified && (
              <Text variant={TextStyleVariant.P2} color={TextColor.TERTIARY}>
                *UMG Single Sign-on (SSO) required.
              </Text>
            )}
          </Grid2>
        </Grid2>
      </Grid2>
      {!showBothOptions ? bonusContent : null}
    </Grid2>
  );
};

export default ProfileSelectionBody;
