import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import useLoginUMGMutation from "./useLoginUMGMutation";
import { useAppSelector } from "../../store/hooks";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { assertEngineEarsErrorType } from "../../api/helpers";
import { tokenAtom } from "../../atoms/auth/tokenAtom";
import { emitAnalyticsTrackingEvent } from "../../utils/analyticsUtils";
import { loginRequest } from "../../utils/umgAuthConfig";

/**
 * Originally located in the `SignInForm` component.
 * This hook is used to authenticate the user after they are redirected back
 * to EngineEars from the UMG portal.
 */
export const useAuthenticateUMG = () => {
  const { instance, accounts, inProgress } = useMsal();
  const token = useAtomValue(tokenAtom);
  const isUmgAuthenticated = useIsAuthenticated();
  const [umgAccessToken, setUmgAccessToken] = useState("");
  const { mutateAsync: loginUMGAsync } = useLoginUMGMutation();

  const { localUTMParams, user } = useAppSelector((state) => state.accountInfo);

  useEffect(() => {
    if (token) return;
    if (!isUmgAuthenticated) return;
    if (!accounts.length) return;
    if (inProgress !== InteractionStatus.None) return;
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setUmgAccessToken(response.accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          void instance.acquireTokenRedirect(request);
        }
      });
  }, [isUmgAuthenticated, accounts, inProgress, token]);

  useEffect(() => {
    if (!umgAccessToken) return;
    void loginUMGAsync({ access_token: umgAccessToken }).then((response) => {
      if (!assertEngineEarsErrorType(response)) {
        emitAnalyticsTrackingEvent("login_umg", {
          username: `${user?.username}`,
          ...localUTMParams,
        });
      }
    });
  }, [umgAccessToken]);
};
