import { AuthBannerTextWrapper } from "../../Auth/AuthModal.styles";
import {
  Text,
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_WEIGHT,
} from "../../../core-ui/components/Text/Text";
import { List, ListItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { AuthBannerTextProps } from "../../Auth/AuthBannerInfoWrapper";

export const AuthBannerText = ({
  title,
  bulletPoints,
  bottomText,
}: AuthBannerTextProps) => {
  return (
    <AuthBannerTextWrapper>
      <Text
        color={TEXT_COLOR.LIGHT_GRAY}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        size={TEXT_SIZE.LARGE}
        className={"title"}
      >
        {title}
      </Text>
      <List>
        {bulletPoints.map((point, index) => (
          <ListItem
            style={{ color: TEXT_COLOR.TERTIARY, gap: "10px" }}
            key={index}
          >
            <FontAwesomeIcon icon={faCheck} />
            {point}
          </ListItem>
        ))}
      </List>
      <Text color={TEXT_COLOR.LIGHT_GRAY} weight={TEXT_WEIGHT.SEMI_BOLD}>
        {bottomText}
      </Text>
    </AuthBannerTextWrapper>
  );
};
