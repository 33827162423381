import { Box } from "@mui/material";
import { TextStyleVariant } from "../../../../../core-ui/components/Text/TextUtils";
import { Text } from "../../../../../core-ui/components/Text/Text";

const ProfileSelectionTitle = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={2}
    >
      <Text variant={TextStyleVariant.H3}>Choose your profile</Text>
      <Text variant={TextStyleVariant.P1}>
        Select all profile types that fit your need below
      </Text>
    </Box>
  );
};

export default ProfileSelectionTitle;
