import AuthStepWrapper from "../AuthStepWrapper";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../../../atoms/location/authFlowAtoms";
import { useAtomValue, useSetAtom } from "jotai";
import { stepDataAtom } from "../../../atoms";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQueryBreakpoint } from "../../../../../../hooks/useMediaQuery";
import { AuthStepBanner } from "../AuthStepBanner";
import { ResponsiveAuthContent } from "../../ResponsiveAuthContent";
import { Text } from "../../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../../core-ui/components/Text/TextUtils";
import { Box } from "@mui/material";
import {
  MajorLabelEnum,
  UMGSubLabelEnum,
} from "../../../../../../store/models/trophy";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import LabelSelectionBody from "./LabelSelectionBody";
import {
  activeUserAtom,
  isActiveUserAandRAtom,
  isActiveUserAdminAtom,
} from "../../../../../../atoms/user/activeUserAtom";
import {
  updateUserAAndR,
  updateUserAdmin,
} from "../../../../../../store/actions/accountInfo";

interface FormElements extends HTMLFormControlsCollection {
  label: HTMLInputElement;
}

export interface LabelSelectionForm extends HTMLFormElement {
  readonly elements: FormElements;
}

const NEXT_STEP = AuthFlowSteps.PROFILE_CREATION;
const NEXT_STEP_IF_USERNAME_CHOSEN = AuthFlowSteps.PHONE_COLLECTION;
const MY_STEP = AuthFlowSteps.LABEL_SELECTION;

const StepInner = () => {
  const setStepData = useSetAtom(stepDataAtom);
  const setStep = useSetAtom(authStepAtom);
  const isAandR = useAtomValue(isActiveUserAandRAtom);
  const isAdmin = useAtomValue(isActiveUserAdminAtom);
  const dispatch = useAppDispatch();
  const activeUser = useAtomValue(activeUserAtom);
  const { user } = useAppSelector((state) => state.accountInfo);
  const initialValue = useMemo(() => {
    return (
      user?.aandr?.label?.[0]?.umg_sub_label ||
      user?.admin?.label?.[0]?.umg_sub_label
    );
  }, [user?.admin, user?.aandr]);

  const { isMobile } = useMediaQueryBreakpoint();
  const [selectedLabel, setSelectedLabel] = useState<
    UMGSubLabelEnum | undefined
  >(initialValue);

  const handleSubmit = useCallback(
    async (e: FormEvent<LabelSelectionForm>) => {
      e.preventDefault();
      const form = e.target as LabelSelectionForm;
      const label = parseInt(form.elements.label.value) as UMGSubLabelEnum;
      // If the username hasn't changed and we already went through this step in the past, go to the next step
      if (
        // We check all the possible combinations of aandr and admin labels
        // This is to make sure the user didn't add admin to their profile, while already being an AandR on this label
        label === initialValue
      ) {
        setStep(
          activeUser?.has_selected_username
            ? NEXT_STEP_IF_USERNAME_CHOSEN
            : NEXT_STEP,
        );
        return;
      }
      const promises: Promise<unknown>[] = [];
      if (isAandR) {
        promises.push(
          dispatch(
            updateUserAAndR({
              is_primary_type: true,
              deleted: false,
              major_label: MajorLabelEnum.UMG,
              sub_label: [label],
            }),
          ).unwrap(),
        );
      }
      if (isAdmin) {
        promises.push(
          dispatch(
            updateUserAdmin({
              deleted: false,
              major_label: MajorLabelEnum.UMG,
              sub_label: [label],
            }),
          ).unwrap(),
        );
      }
      await Promise.all(promises).then(() => {
        setStep(
          activeUser?.has_selected_username
            ? NEXT_STEP_IF_USERNAME_CHOSEN
            : NEXT_STEP,
        );
      });
    },
    [
      setStep,
      isAandR,
      isAdmin,
      dispatch,
      initialValue,
      activeUser?.has_selected_username,
    ],
  );

  const titleContent = useMemo(() => {
    return (
      <AuthStepBanner
        onClick={() => {
          setStep(AuthFlowSteps.PROFILE_SELECTION);
        }}
        currentStep={2}
        maxStep={4}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text
            variant={isMobile ? TextStyleVariant.H5 : TextStyleVariant.H2}
            sx={{ textAlign: "center" }}
          >
            Choose your label
          </Text>
          <Text
            variant={isMobile ? TextStyleVariant.CAPTION : TextStyleVariant.P2}
            sx={{ textAlign: "center" }}
          >
            Select your primary label association
          </Text>
        </Box>
      </AuthStepBanner>
    );
  }, [setStep, isMobile]);

  useEffect(() => {
    setStepData({
      disableClose: true,
      hideFooter: false,
      disablePadding: true,
      showDividers: false,
      maxWidth: "md",
      title: titleContent,
      onSubmit: typeof selectedLabel !== "undefined" ? handleSubmit : undefined,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, titleContent, handleSubmit, selectedLabel]);

  return (
    <ResponsiveAuthContent>
      <LabelSelectionBody
        onClick={(label, checked) => {
          if (checked) {
            setSelectedLabel(label);
          }
        }}
        initialValue={initialValue}
      />
    </ResponsiveAuthContent>
  );
};

export const LabelSelectionStep = () => {
  return (
    <AuthStepWrapper step={MY_STEP}>
      <StepInner />
    </AuthStepWrapper>
  );
};
