import { Box, styled } from "@mui/material";

export const AuthStepBannerContentWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  "& .titleContent": {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
}));

export const MainContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  justifyContent: "flex-start",
  alignItems: "flex-start",
  height: "100%",
  width: "100%",
  gap: theme.spacing(2),
  boxSizing: "border-box",
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(4),
    justifyContent: "center",
  },
}));

export const SignUpFooter = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  justifyContent: "center",
});
