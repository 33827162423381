import AuthStepWrapper from "../AuthStepWrapper";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../../../atoms/location/authFlowAtoms";
import { useAtomValue, useSetAtom } from "jotai";
import { stepDataAtom } from "../../../atoms";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  neverMissProjectDeadlinesText,
  takeControlText,
} from "../../../../Auth/AuthBannerInfoWrapper";
import {
  Button,
  ButtonVariant,
} from "../../../../../core-ui/components/Button/Button";
import { useMediaQueryBreakpoint } from "../../../../../../hooks/useMediaQuery";
import { AuthStepBanner } from "../AuthStepBanner";
import { ResponsiveAuthContent } from "../../ResponsiveAuthContent";
import BannerImage from "../../../../../assets/authBanner/verify_phone.png";
import AandRBannerImage from "../../../../../assets/authBanner/aandr_verify_phone.png";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import useActiveUserQuery from "../../../../../../hooks/userHooks/useActiveUserQuery";
import { useUpdateUserProfile } from "../../../../../../hooks/userHooks/useUpdateUserProfile";
import useVerifyPhoneMutation from "../../../../../../hooks/userHooks/useVerifyPhoneMutation";
import { VerifyPhoneInput } from "./VerifyPhoneInput";
import { isActiveUserLabelUserAtom } from "../../../../../../atoms/user/activeUserAtom";

const NEXT_STEP = AuthFlowSteps.ONBOARDING_SURVEY;
const MY_STEP = AuthFlowSteps.PHONE_VERIFICATION;

const StepInner = () => {
  const setStepData = useSetAtom(stepDataAtom);
  const setStep = useSetAtom(authStepAtom);
  const [sentInitialCode, setSentInitialCode] = useState(false);
  const { data: activeUser } = useActiveUserQuery();
  const isLabelUser = useAtomValue(isActiveUserLabelUserAtom);

  const {
    mutateAsync: updateUserProfile,
    reset: resetSendCode,
    isPending: resendLoading,
    isSuccess: resentVerification,
  } = useUpdateUserProfile();
  const {
    mutateAsync: verifyPhone,
    isPending: isVerifyingPhone,
    isError: verificationError,
  } = useVerifyPhoneMutation();

  const handleResendVerifyPhone = useCallback(async () => {
    await updateUserProfile({
      phone_number: activeUser?.phone_number?.phone_number,
    }).then(() => {
      emitAnalyticsTrackingEvent("auth_verify_phone_clicked_resend", {});
      setTimeout(() => {
        resetSendCode();
      }, 5000);
    });
  }, [updateUserProfile, activeUser]);

  const handleVerifyPhoneNumber = useCallback(
    async (verificationCode: string) => {
      return verifyPhone(verificationCode)
        .then(() => {
          emitAnalyticsTrackingEvent("auth_verify_phone_used_code", {});
          setStep(NEXT_STEP);
          return true;
        })
        .catch(() => {
          emitAnalyticsTrackingEvent("auth_verify_phone_used_code_error", {});
          return false;
        });
    },
    [setStep, verifyPhone],
  );

  const { isMobile } = useMediaQueryBreakpoint();

  const titleContent = useMemo(() => {
    return (
      <AuthStepBanner
        onClick={() => {
          // If the email is verified, going back to that step will just bring us back to this step, so go back 2.
          setStep(AuthFlowSteps.PHONE_COLLECTION);
        }}
        currentStep={3}
        maxStep={3}
      />
    );
  }, [setStep]);

  useEffect(() => {
    setStepData({
      disableClose: true,
      hideFooter: true,
      disablePadding: true,
      maxWidth: "md",
      title: isMobile ? titleContent : undefined,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, isMobile, titleContent]);

  useEffect(() => {
    if (activeUser?.phone_number?.verified) {
      setStep(NEXT_STEP);
    } else if (!sentInitialCode) {
      setSentInitialCode(true);
      void handleResendVerifyPhone();
    }
  }, [
    activeUser?.phone_number?.verified,
    setStep,
    setSentInitialCode,
    sentInitialCode,
    handleResendVerifyPhone,
  ]);

  return (
    <ResponsiveAuthContent
      titleContent={titleContent}
      {...(isLabelUser ? neverMissProjectDeadlinesText : takeControlText)}
      src={`url(${isLabelUser ? AandRBannerImage : BannerImage})`}
    >
      <VerifyPhoneInput
        phoneNumber={activeUser!.phone_number!.phone_number}
        handleVerifyPhone={handleVerifyPhoneNumber}
        handleResendVerifyPhone={handleResendVerifyPhone}
        verificationError={verificationError}
        isVerifyingPhone={isVerifyingPhone}
        resentVerification={resentVerification}
        resendLoading={resendLoading}
        width={"100%"}
        my={"auto"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={2}
      />
      <Button
        variant={ButtonVariant.UNSTYLED}
        sx={(theme) => ({
          textDecoration: "underline",
          alignSelf: "flex-end",
          color: theme.palette.text.primary,
        })}
        onClick={() => {
          emitAnalyticsTrackingEvent(
            "auth_verify_phone_clicked_remind_me_later",
            {},
          );
          setStep(NEXT_STEP);
        }}
      >
        Remind me later
      </Button>
    </ResponsiveAuthContent>
  );
};

export const PhoneVerificationStep = () => {
  return (
    <AuthStepWrapper step={MY_STEP}>
      <StepInner />
    </AuthStepWrapper>
  );
};
