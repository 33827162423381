import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getCalendarIntegrationStatus } from "../../../../store/actions/calendarService";
import { updateStudio } from "../../../../store/actions/studio";
import {
  AccountDetailsBody,
  AccountDetailsFieldContainer,
  FieldInput,
  SeperatorLine,
} from "../AccountSettingsModal.styles";
import { Text, TEXT_WEIGHT } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { LocationInput } from "../../LocationInput/LocationInput";
import { CalendarManageView } from "../../CalendarManageView/CalendarManageView";
import { PresentationSettings } from "../../PresentationSettings/PresentationSettings";
import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { DISCIPLINE_TYPE, getUserDisciplines } from "../../../../hooks/user";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Box, Grid2, useTheme } from "@mui/material";
import { ResetPassword } from "../ResetPassword";
import { loadEngineerServices } from "../../../../store/actions/services";
import { ServicesFormModalFooter } from "../../../screens/ProfileScreen/components/ServicesFormModalFooter";
import {
  createRecordingService,
  createRecordingServiceParams,
} from "../../../../store/actions/recording";
import { BasePopover } from "../../../core-ui/components/BasePopover/BasePopover";
import { PopoverTrigger } from "@radix-ui/react-popover";
import useModal from "../../../../hooks/useModal";
import { useNavigate } from "react-router-dom";
import {
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../../store/utils/routeGetters";
import { AccountSettingsPhoneNumberField } from "./AccountSettingsPhoneNumberField";
import { UsernameField } from "./UsernameField";
import { useAtomValue } from "jotai";
import {
  activeProfileAtom,
  isStudioProfileAtom,
} from "../../../../atoms/user/activeProfileAtom";
import { DisplayNameField } from "./DisplayNameField";
import { useUpdateUserProfile } from "../../../../hooks/userHooks/useUpdateUserProfile";
import { activeUserAtom } from "../../../../atoms/user/activeUserAtom";
import { AccountSettingsTextField } from "./AccountSettingsTextField";
import { SlimActiveStudio } from "../../../../store/models/studio";
import { QUERY_KEYS } from "../../../../constants/queryKeys";
import { useQueryClient } from "@tanstack/react-query";
import { ACCOUNT_SETTINGS_MODAL_ID } from "../constants";
import { ValidationLabelProps } from "./ValidationLabel.types";
import { validateUsername } from "../../AuthFlow/components/validators/usernameValidators";

export interface updateProfileErrorProps {
  code: number;
  firstName: string;
  lastName: string;
  displayName: string;
  username: string;
  email: string;
  phoneNumber: string;
}

interface AccountSettingsProps {
  onClose: () => void;
  ref?: React.Ref<HTMLDivElement>;
}

export const AccountSettings = ({ onClose, ref }: AccountSettingsProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeProfile = useAtomValue(activeProfileAtom);
  const isStudioProfile = useAtomValue(isStudioProfileAtom);
  const activeUser = useAtomValue(activeUserAtom);
  const queryClient = useQueryClient();
  const { mutateAsync: updateUserProfile, isPending } = useUpdateUserProfile();
  const { user } = useAppSelector((state) => state.accountInfo);
  const userAccountTypes = getUserDisciplines(user);
  const [savingChanges, setSavingChanges] = useState(false);
  const [updateProfileError, setUpdateProfileError] =
    useState<updateProfileErrorProps>();
  const [firstName, setFirstName] = useState(activeUser?.first_name || "");
  const [lastName, setLastName] = useState(activeUser?.last_name || "");
  const [displayName, setDisplayName] = useState(activeProfile?.display_name);
  const [updatedUsername, setUpdatedUsername] = useState(
    activeProfile?.username,
  );

  const [hasUsernameErrors, setHasUsernameErrors] = useState<
    Record<string, ValidationLabelProps>
  >({});
  const [email, setEmail] = useState(user?.email ?? "");
  const [isValidEmail, setIsValidEmail] = useState(Boolean(user?.email));
  const [location, setLocation] = useState<
    undefined | google.maps.places.PlaceResult
  >(undefined);
  const { calendarIntegrated } = useAppSelector(
    (state) => state.calendarService,
  );
  const recordingService = useAppSelector(
    (state) => state.engineerServices.recordingService,
  );
  const [pendingRecordingService, setPendingRecordingService] =
    useState<createRecordingServiceParams>();
  const {
    isOpen: isPopoverOpen,
    setIsOpen: setIsPopoverOpen,
    closeModal: closePopover,
  } = useModal();

  const handleUsernameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      setUpdatedUsername(value);
      setHasUsernameErrors(validateUsername(value));
    },
    [setUpdatedUsername, setHasUsernameErrors],
  );

  const handleDisplayNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value;
      setDisplayName(value);
    },
    [setDisplayName],
  );

  useEffect(() => {
    if (!isStudioProfile && user?.engineer?.id) {
      void dispatch(
        loadEngineerServices({
          engineer_id: user.engineer.id,
        }),
      ).unwrap();
    }
  }, [isStudioProfile, user, dispatch]);

  useEffect(() => {
    if (isStudioProfile) {
      void dispatch(
        getCalendarIntegrationStatus({ studio_id: activeProfile?.id }),
      ).unwrap();
    }
  }, [isStudioProfile, activeProfile?.id, dispatch]);

  useEffect(() => {
    if (recordingService) {
      setPendingRecordingService({
        price: recordingService.service_rate?.price || 0,
        label_price: recordingService?.service_rate?.label_price || 0,
        travel_to_artist_price:
          recordingService?.service_rate?.travel_to_artist_price || 0,
        minimum_session_time_minutes:
          recordingService.minimum_session_time_minutes || 0,
        maximum_session_time_minutes:
          recordingService.maximum_session_time_minutes || 0,
        will_come_to_you: Boolean(recordingService.will_come_to_you),
        max_travel_distance_minutes:
          recordingService.max_travel_distance_minutes || 0,
        equipment_highlights: recordingService.equipment_highlights || "",
        arrival_information: recordingService.arrival_information || "",
        recording_location: recordingService.recording_location?.city_location,
        unit_number: recordingService.unit_number || "",
        minimum_gap_between_sessions_in_minutes:
          recordingService.minimum_gap_between_sessions_in_minutes || 0,
        number_of_hours_notice: recordingService.number_of_hours_notice || 0,
        minimum_deposit_percentage:
          recordingService.minimum_deposit_percentage.toString() ?? "1.0",
      });
    }
  }, [recordingService]);

  const handleSavingChanges = () => {
    setSavingChanges(true);
    const updatingUsername = activeProfile?.username !== updatedUsername;
    if (isStudioProfile) {
      void dispatch(
        updateStudio({
          studio_id: activeProfile?.id,
          location: location,
          profile: {
            display_name: displayName,
          },
          ...(updatingUsername && {
            username: updatedUsername,
          }),
        }),
      )
        .unwrap()
        .then(() => {
          void queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.LOAD_STUDIOS, activeUser?.id],
          });
        });
      void updateUserProfile({
        email,
      })
        .then(() => {
          onClose();
          toast.success("Successfully updated account details");
          if (updatingUsername) {
            navigate(getStudioScreenRoute(updatedUsername));
          }
        })
        .catch((err) => {
          setUpdateProfileError(err);
        })
        .finally(() => {
          setSavingChanges(false);
        });
    } else {
      if (recordingService && location && pendingRecordingService) {
        void dispatch(
          createRecordingService({
            ...pendingRecordingService,
            recording_location: location,
          }),
        ).unwrap();
      }
      void updateUserProfile({
        first_name: firstName,
        last_name: lastName,
        display_name: displayName,
        email: email,
        ...(updatingUsername && {
          username: updatedUsername,
        }),
      })
        .then(() => {
          onClose();
          toast.success("Successfully updated account details");
          if (updatingUsername) {
            navigate(getProfileScreenRoute(updatedUsername));
          }
        })
        .catch((err) => {
          setUpdateProfileError(err);
        })
        .finally(() => {
          setSavingChanges(false);
        });
    }
  };

  const firstNameField = (
    <AccountSettingsTextField
      label={"First Name"}
      name={"first_name"}
      TextFieldProps={{
        defaultValue: activeUser?.first_name,
        placeholder: "First Name",
        onChange: (e) => {
          const value = e.target.value;
          setFirstName(value);
        },
      }}
      disabled={savingChanges || isPending}
      helperText={"Note: not shown publicly"}
      errorMessage={updateProfileError?.firstName}
    />
  );

  const lastNameField = (
    <AccountSettingsTextField
      label={"Last Name"}
      name={"last_name"}
      TextFieldProps={{
        defaultValue: activeUser?.last_name,
        placeholder: "Last Name",
        onChange: (e) => {
          const value = e.target.value;
          setLastName(value);
        },
      }}
      disabled={savingChanges || isPending}
      helperText={"Note: not shown publicly"}
      errorMessage={updateProfileError?.lastName}
    />
  );

  const emailField = (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Email
      </Text>
      <FieldInput
        placeholder={"Email"}
        defaultValue={email}
        disabled={savingChanges || isPending}
        fullWidth={true}
        size={"small"}
        onChange={(e) => {
          const email = e.target.value;
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          setEmail(email);

          if (emailPattern.test(email)) {
            setIsValidEmail(true);
          } else {
            setIsValidEmail(false);
          }
        }}
        InputProps={{
          endAdornment: isValidEmail ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : null,
        }}
        error={Boolean(updateProfileError?.email)}
        helperText={updateProfileError?.email}
      />
      {user?.email_verified && (
        <Text
          variant={TextStyleVariant.P3}
          style={{ color: theme.palette.standardColor.Green[600] }}
        >
          <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
          verified
        </Text>
      )}
    </AccountDetailsFieldContainer>
  );

  const locationAndCalendarField = (
    <AccountDetailsFieldContainer>
      {(isStudioProfile ||
        (userAccountTypes.includes(DISCIPLINE_TYPE.ENGINEER) &&
          recordingService &&
          pendingRecordingService)) && (
        <>
          <Text
            variant={TextStyleVariant.P1}
            weight={TEXT_WEIGHT.SEMI_BOLD}
            style={{ marginBottom: "8px" }}
          >
            Location
          </Text>
          <LocationInput
            defaultValue={
              isStudioProfile
                ? (activeProfile as SlimActiveStudio).location?.city_location
                : (pendingRecordingService?.recording_location as string)
            }
            placeholder={"Start by typing in your address"}
            onPlaceSelected={(placeResult) => {
              setLocation(placeResult);
            }}
            showBorder
          />
          <Text
            variant={TextStyleVariant.P1}
            weight={TEXT_WEIGHT.SEMI_BOLD}
            style={{ marginBottom: "8px" }}
          >
            {calendarIntegrated ? "Manage" : "Set up"} booking calendar
          </Text>
          {isStudioProfile && (
            <CalendarManageView
              selector={`#${ACCOUNT_SETTINGS_MODAL_ID}`}
              studioID={activeProfile?.id}
            />
          )}
        </>
      )}
    </AccountDetailsFieldContainer>
  );

  const displayNameField = useMemo(() => {
    return (
      <DisplayNameField
        TextFieldProps={{
          defaultValue:
            activeProfile?.display_name !== `@${activeProfile?.username}`
              ? activeProfile?.display_name
              : "",
          onChange: handleDisplayNameChange,
        }}
        disabled={savingChanges || isPending}
        errorMessage={updateProfileError?.displayName}
        isStudio={isStudioProfile}
      />
    );
  }, [
    savingChanges,
    isPending,
    updateProfileError?.displayName,
    activeProfile?.display_name,
    activeProfile?.username,
    isStudioProfile,
    handleDisplayNameChange,
  ]);

  const usernameField = useMemo(() => {
    return (
      <UsernameField
        TextFieldProps={{
          defaultValue: activeProfile?.username,
          onChange: handleUsernameChange,
        }}
        disabled={savingChanges || isPending}
        errorMessage={updateProfileError?.username}
        validationLabels={hasUsernameErrors}
        isStudio={isStudioProfile}
      />
    );
  }, [
    savingChanges,
    updateProfileError?.username,
    activeProfile?.username,
    isStudioProfile,
    handleUsernameChange,
    hasUsernameErrors,
    isPending,
  ]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
        id={"account-settings-content"}
      >
        <PresentationSettings />
        <AccountDetailsBody>
          <Grid2 container spacing={4}>
            {!isStudioProfile && (
              <>
                <Grid2 size={{ xs: 12, md: 6 }}>{firstNameField}</Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>{lastNameField}</Grid2>
              </>
            )}
            <Grid2 size={{ xs: 12, md: 6 }}>{displayNameField}</Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>{usernameField}</Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>{emailField}</Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <AccountSettingsPhoneNumberField />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <ResetPassword />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>{locationAndCalendarField}</Grid2>
          </Grid2>
        </AccountDetailsBody>
      </Box>
      <SeperatorLine />
      <ServicesFormModalFooter>
        <Button
          variant={ButtonVariant.OUTLINED}
          disabled={savingChanges || isPending}
          onClick={onClose}
          fullWidth
        >
          Cancel
        </Button>
        {activeProfile?.username !== updatedUsername ? (
          <BasePopover
            isOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            closePopover={closePopover}
            title={"Confirm update username"}
            additionalContent={
              <Text>
                Changing your username will affect existing links to your
                {isStudioProfile ? " studio" : "profile"} page. Are you sure you
                want to change your username?
              </Text>
            }
            onConfirm={handleSavingChanges}
            okButtonProps={{
              disabled: savingChanges || isPending,
              loading: savingChanges || isPending,
            }}
          >
            <PopoverTrigger asChild>
              <Button
                loading={savingChanges || isPending}
                disabled={savingChanges || isPending}
                fullWidth
              >
                Save Settings
              </Button>
            </PopoverTrigger>
          </BasePopover>
        ) : (
          <Button
            loading={savingChanges || isPending}
            disabled={savingChanges || isPending}
            onClick={handleSavingChanges}
            fullWidth
          >
            Save Settings
          </Button>
        )}
      </ServicesFormModalFooter>
    </>
  );
};
