import { Text, TEXT_WEIGHT } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { Box } from "@mui/material";
import VerificationCodeInput from "../../../Auth/VerificationCodeInput";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { AccountDetailsFieldContainer } from "../../../AccountSettingsModal/AccountSettingsModal.styles";
import useGuestLoginMutation from "../../../../../hooks/authHooks/useGuestLoginMutation";
import useGetGuestVerificationCodeMutation from "../../../../../hooks/authHooks/useGetGuestVerificationCodeMutation";

interface SignInPhoneNumberVerificationFieldProps {
  phoneNumber: string;
  onComplete?: (code: string) => void;
  onCancel?: () => void;
  onResend?: () => void;
}

export const SignInPhoneNumberVerificationField = ({
  onCancel = () => {},
  onResend = () => {},
  onComplete = () => {},
  phoneNumber,
}: SignInPhoneNumberVerificationFieldProps) => {
  const [hasResentVerification, setHasResentVerification] = useState(false);
  const { mutateAsync: getVerificationCodeAsync, isPending: isSendingCode } =
    useGetGuestVerificationCodeMutation();
  const { mutateAsync: guestLoginAsync, isPending: isLoggingIn } =
    useGuestLoginMutation();

  const handleResendCode = useCallback(async () => {
    return getVerificationCodeAsync({
      phone_number: phoneNumber,
    })
      .then(() => {
        toast.success("Verification code resent");
        onResend();
      })
      .finally(() => {
        setTimeout(() => {
          setHasResentVerification(false);
        }, 5000);
      });
  }, [
    getVerificationCodeAsync,
    phoneNumber,
    setHasResentVerification,
    onResend,
  ]);

  const handleSignIn = useCallback(
    async (verificationCode: string) => {
      return guestLoginAsync({
        phone_number: phoneNumber,
        verification_code: verificationCode,
      }).then(() => {
        onComplete(verificationCode);
      });
    },
    [phoneNumber, guestLoginAsync, onComplete],
  );

  return (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Enter verification code
      </Text>

      <Box style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
        <VerificationCodeInput
          disabled={isLoggingIn}
          codeLength={6}
          onComplete={handleSignIn}
        />
        <Box
          width={"100%"}
          style={{ display: "flex", flexDirection: "column", gap: "2px" }}
        >
          <Button
            variant={ButtonVariant.GHOST}
            onClick={handleResendCode}
            loading={isSendingCode}
            disabled={isSendingCode || hasResentVerification}
            style={{ padding: "0px" }}
            fullWidth
          >
            Resend code
          </Button>
          <Button
            variant={ButtonVariant.GHOST}
            onClick={() => {
              onCancel();
            }}
            style={{ padding: "0px" }}
            fullWidth
          >
            Change phone
          </Button>
        </Box>
      </Box>
    </AccountDetailsFieldContainer>
  );
};
