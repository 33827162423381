import AuthStepWrapper from "../AuthStepWrapper";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../../../atoms/location/authFlowAtoms";
import { Box } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";
import { stepDataAtom } from "../../../atoms";
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DISCIPLINE_TYPE,
  disciplineTypeToRegisterEventMap,
  getDisciplineString,
} from "../../../../../../hooks/user";
import { useAppDispatch } from "../../../../../../store/hooks";
import { activeUserAtom } from "../../../../../../atoms/user/activeUserAtom";
import ProfileSelectionTitle from "./ProfileSelectionTitle";
import ProfileSelectionBody from "./ProfileSelectionBody";
import handleProfileSelectionFormSubmitFactory, {
  ProfileSelectionForm,
} from "./handleProfileSelectionFormSubmitFactory";
import { emitAnalyticsTrackingEvent } from "../../../../../../utils/analyticsUtils";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../../../../../constants/queryKeys";

const StepInner = () => {
  const setStep = useSetAtom(authStepAtom);
  const setStepData = useSetAtom(stepDataAtom);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const activeUser = useAtomValue(activeUserAtom);
  const isLabelUser = useMemo(() => {
    return Boolean(
      activeUser?.disciplines?.aandr || activeUser?.disciplines?.admin,
    );
  }, [activeUser?.disciplines?.aandr, activeUser?.disciplines?.admin]);

  const initialValues = useMemo(() => {
    const result: Partial<Record<DISCIPLINE_TYPE, boolean>> = {};
    if (activeUser?.disciplines) {
      if (activeUser?.disciplines.admin) {
        result[DISCIPLINE_TYPE.ADMIN] = true;
      }
      if (activeUser?.disciplines.aandr) {
        result[DISCIPLINE_TYPE.A_AND_R] = true;
      }
      if (activeUser?.disciplines.engineer) {
        result[DISCIPLINE_TYPE.ENGINEER] = true;
      }
      if (activeUser?.disciplines.artist) {
        result[DISCIPLINE_TYPE.ARTIST] = true;
      }
      if (activeUser?.disciplines.producer) {
        result[DISCIPLINE_TYPE.PRODUCER] = true;
      }
      if (activeUser?.disciplines.studio_manager) {
        result[DISCIPLINE_TYPE.STUDIO_MANAGER] = true;
      }
      if (activeUser?.disciplines.listener) {
        result[DISCIPLINE_TYPE.LISTENER] = true;
      }
      if (activeUser?.disciplines.other) {
        result[DISCIPLINE_TYPE.OTHER] = true;
      }
    }
    return result;
  }, [activeUser?.disciplines]);

  const [clickedDisciplines, setClickedDisciplines] = useState<
    DISCIPLINE_TYPE[]
  >(Object.keys(initialValues).map((key) => parseInt(key) as DISCIPLINE_TYPE));

  const hasOther = useMemo(
    () => clickedDisciplines.includes(DISCIPLINE_TYPE.OTHER),
    [clickedDisciplines],
  );
  const hasStudioManager = useMemo(
    () => clickedDisciplines.includes(DISCIPLINE_TYPE.STUDIO_MANAGER),
    [clickedDisciplines],
  );
  const hasAny = useMemo(
    () => clickedDisciplines.length > 0,
    [clickedDisciplines.length],
  );

  // Calculate the next step from this step based on the user
  const validatedNextStep = useMemo(() => {
    // If the user is a label user, the next step should be label selection, otherwise its username selection
    let nextStep =
      isLabelUser && !activeUser?.has_selected_label
        ? AuthFlowSteps.LABEL_SELECTION
        : AuthFlowSteps.PROFILE_CREATION;
    // If we already have a username, lets move to the next step
    if (
      nextStep === AuthFlowSteps.PROFILE_CREATION &&
      activeUser?.has_selected_username
    ) {
      // If we have a verified email, which we always will as a label at this point, we move to phone collection
      nextStep = activeUser?.email_verified
        ? AuthFlowSteps.PHONE_COLLECTION
        : AuthFlowSteps.EMAIL_VERIFICATION;
      // If we have a phone number, lets make sure its verified
      if (
        nextStep === AuthFlowSteps.PHONE_COLLECTION &&
        activeUser?.phone_number
      ) {
        // if it's verified, then we've move to our onboarding survey!
        nextStep = activeUser?.phone_number?.verified
          ? AuthFlowSteps.ONBOARDING_SURVEY
          : AuthFlowSteps.PHONE_VERIFICATION;
      }
    }
    return nextStep;
  }, [
    isLabelUser,
    activeUser?.has_selected_label,
    activeUser?.has_selected_username,
    activeUser?.phone_number,
    activeUser?.email_verified,
  ]);

  const handleClickDiscipline = useCallback(
    (discipline: DISCIPLINE_TYPE) => {
      const itemIndex = clickedDisciplines.indexOf(discipline);
      if (itemIndex !== -1) {
        setClickedDisciplines((current) => {
          const result = [...current];
          result.splice(itemIndex, 1);
          return result;
        });
      } else {
        setClickedDisciplines((current) => [...current, discipline]);
      }
    },
    [clickedDisciplines, setClickedDisciplines],
  );
  const handleSubmit: FormEventHandler<ProfileSelectionForm> = useCallback(
    (e) =>
      handleProfileSelectionFormSubmitFactory({
        dispatch,
        userDisciplines: activeUser?.disciplines,
        primaryDiscipline: clickedDisciplines[0],
        initialPrimaryDiscipline: activeUser?.primaryDiscipline,
      })(e).then(async () => {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.LOAD_USER],
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_USER_PROFILE, activeUser?.username],
        });
        // Calculate the next step based on the user

        setStep(validatedNextStep);
        const eventName =
          disciplineTypeToRegisterEventMap.get(clickedDisciplines[0]) ||
          "unknown_primary_account_type_registered";
        emitAnalyticsTrackingEvent(eventName, {
          user_id: activeUser?.id,
          username: activeUser?.username,
          email: activeUser?.email,
          all_account_types: clickedDisciplines
            .map(getDisciplineString)
            .join(" / "),
          account_type: getDisciplineString(clickedDisciplines[0]),
        });
        emitAnalyticsTrackingEvent("auth_submit_profile_types", {
          primary_profile_type: clickedDisciplines[0],
          selected_profile_types: clickedDisciplines,
        });
      }),

    [
      activeUser?.id,
      activeUser?.username,
      activeUser?.email,
      activeUser?.primaryDiscipline,
      activeUser?.disciplines,
      queryClient,
      dispatch,
      clickedDisciplines,
      setStep,
      validatedNextStep,
    ],
  );

  // Load step data into the active modal on mount, and clean up data on unmount
  useEffect(() => {
    setStepData({
      onSubmit: clickedDisciplines.length > 0 ? handleSubmit : undefined,
      disableClose: true,
      title: <ProfileSelectionTitle />,
      maxWidth: "lg",
      showDividers: false,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, handleSubmit, clickedDisciplines]);
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <ProfileSelectionBody
        onClickDiscipline={handleClickDiscipline}
        primaryDiscipline={clickedDisciplines[0]}
        isLabelUser={isLabelUser}
        initialValues={initialValues}
        hasStudioManager={hasStudioManager}
        hasOther={hasOther}
        hasAny={hasAny}
        labelEmailVerified={isLabelUser && Boolean(activeUser?.email_verified)}
      />
    </Box>
  );
};

// The reason why we use a wrapper is to prevent unnecessary hook invocations on the inner step
// These hooks are ensured to be safe by the wrapper
const ProfileSelectionStep = () => {
  return (
    <AuthStepWrapper step={AuthFlowSteps.PROFILE_SELECTION}>
      <StepInner />
    </AuthStepWrapper>
  );
};

export default ProfileSelectionStep;
