import AuthStepWrapper from "../AuthStepWrapper";
import { AuthFlowSteps } from "../../../../../../atoms/location/authFlowAtoms";
import { UserProfileCreation } from "./UserProfileCreation";
import { useAtomValue } from "jotai";
import { activeUserAtom } from "../../../../../../atoms/user/activeUserAtom";
import { StudioProfileCreationStep } from "./StudioProfileCreation";
import { activeStudioProfileAtom } from "../../../../../../atoms/user/activeProfileAtom";

const MY_STEP = AuthFlowSteps.PROFILE_CREATION;

const StepInner = () => {
  const activeUser = useAtomValue(activeUserAtom);
  const activeStudio = useAtomValue(activeStudioProfileAtom);
  if (activeUser?.has_studio_manager_experience || activeStudio) {
    return <StudioProfileCreationStep />;
  }
  return <UserProfileCreation />;
};

export const UsernameSelectionStep = () => {
  return (
    <AuthStepWrapper step={MY_STEP}>
      <StepInner />
    </AuthStepWrapper>
  );
};
