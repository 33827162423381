import { Dialog, styled } from "@mui/material";
import { TrackableClassNames } from "../../../../constants/trackableClassNames";

export const EngineEarsDialog = styled(Dialog)(({ theme }) => ({
  [`&:not(.${TrackableClassNames.ENGINEEARS_DIALOG_FULLSCREEN})`]: {
    "& .MuiDialogContent-root, & .MuiDialogTitle-root": {
      borderRadius: theme.spacing(1),
    },
  },
  "& .MuiDialogTitle-root": {
    backgroundColor: theme.palette.customColor.backgroundColor,
  },
  "& .MuiDialogContent-root": {
    backgroundColor: theme.palette.customColor.foregroundColor,
  },
  "& .MuiDialogActions-root": {
    backgroundColor: theme.palette.customColor.foregroundColor,
  },
  "& .MuiDialog-paper": {
    overflowY: "unset",
  },
}));
