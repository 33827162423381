import { Box, styled } from "@mui/material";

const ProfileTypeCardInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "266px",
  alignItems: "flexStart",
  gap: "12px",
  flexShrink: 0,
  pointerEvents: "none",
});

export default ProfileTypeCardInfo;
