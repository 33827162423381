import { ValidationLabelProps } from "../../../AccountSettingsModal/tabs/ValidationLabel.types";
import { ValidationTest } from "./types";
import { validateInput } from "./validators";

const HAS_EMAIL_PATTERN_VALIDATION_KEY = "hasEmail";
export const HAS_EMAIL_PATTERN_VALIDATION_LABEL: ValidationLabelProps = {
  label: "Invalid email address",
};
const checkValidEmailPattern = (email: string) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return !emailPattern.test(email);
};

const HAS_UMG_EMAIL_PATTERN_VALIDATION_KEY = "hasUmgEmail";
export const HAS_UMG_EMAIL_PATTERN_VALIDATION_LABEL: ValidationLabelProps = {
  label: "Organization not found",
};
const VALID_UMG_DOMAINS = ["umusic.com", "umgconsult.com"];
// The function that creates the validation labels expects false to mean that the email is valid. While most other uses expect false to mean that the email is invalid.
export const checkUmgEmailPattern = (email: string, inverseResults = false) => {
  if (!email) return !inverseResults;
  const allowedDomains = VALID_UMG_DOMAINS;
  const emailParts = email.split("@");
  if (emailParts.length !== 2) {
    return false; // Invalid email format
  }

  const domain = emailParts[1].toLowerCase();

  for (const allowedDomain of allowedDomains) {
    // Construct regex. Escape special characters in the allowed domain.
    const escapedDomain = allowedDomain.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters.
    const regex = new RegExp(`^${escapedDomain}$`, "i"); // 'i' flag for case-insensitive matching.

    if (regex.test(domain)) {
      return inverseResults;
    }
  }

  return !inverseResults;
};
const EMAIL_VALIDATION_TESTS: ValidationTest[] = [
  {
    key: HAS_EMAIL_PATTERN_VALIDATION_KEY,
    label: HAS_EMAIL_PATTERN_VALIDATION_LABEL,
    func: checkValidEmailPattern,
  },
];

const UMG_EMAIL_VALIDATION_TESTS: ValidationTest[] = [
  {
    key: HAS_UMG_EMAIL_PATTERN_VALIDATION_KEY,
    label: HAS_UMG_EMAIL_PATTERN_VALIDATION_LABEL,
    func: checkUmgEmailPattern,
  },
];

export const validateEmail = (value: string, showAllPassing = false) => {
  return validateInput(value, EMAIL_VALIDATION_TESTS, {}, showAllPassing);
};

export const validateUmgEmail = (value: string, showAllPassing = false) => {
  return validateInput(value, UMG_EMAIL_VALIDATION_TESTS, {}, showAllPassing);
};
