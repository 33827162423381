import { atomWithStorage, RESET } from "jotai/utils";
import { LOCAL_STORAGE_KEYS } from "../../constants/localstorageConstants";
import { atom } from "jotai";
import { activeUserAtom } from "../user/activeUserAtom";
import { supplyChainTokenAtom } from "../supplychain/supplyChainTokenAtom";
import { supplyChainExpirationAtom } from "../supplychain/supplyChainExpirationAtom";
import { AuthFlowSteps, authStepAtom } from "../location/authFlowAtoms";

const _tokenAtom = atomWithStorage(LOCAL_STORAGE_KEYS.TOKEN, "");

export const tokenAtom = atom(
  (get) => {
    return get(_tokenAtom);
  },
  (_, set, token: string | ((oldToken: string) => string)) => {
    let result = "";
    // This is a helper function to capture the result from the set state action
    set(_tokenAtom, (oldState) => {
      if (typeof token === "function") {
        result = token(oldState);
      } else {
        result = token;
      }
      if (!result) {
        // Clear the active user since we obviously aren't logged in
        set(activeUserAtom, undefined);
        set(authStepAtom, AuthFlowSteps.NONE);
        // Clear Supply Chain tokens
        set(supplyChainTokenAtom, "");
        set(supplyChainExpirationAtom, "");
        return RESET;
      }
      return result;
    });
  },
);

tokenAtom.debugLabel = "User Auth Token";
