import AuthStepWrapper from "./AuthStepWrapper";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../../atoms/location/authFlowAtoms";
import { useSetAtom } from "jotai";
import { stepDataAtom } from "../../atoms";
import { useEffect, useMemo } from "react";
import { takeControlText } from "../../../Auth/AuthBannerInfoWrapper";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { useMediaQueryBreakpoint } from "../../../../../hooks/useMediaQuery";
import { AuthStepBanner } from "./AuthStepBanner";
import { ResponsiveAuthContent } from "../ResponsiveAuthContent";
import AddPhone from "../../../../assets/authBanner/add_phone.png";
import { emitAnalyticsTrackingEvent } from "../../../../../utils/analyticsUtils";
import { ResetPassword } from "../../../AccountSettingsModal/ResetPassword";
import { Text } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import { Box } from "@mui/material";

const NEXT_STEP = AuthFlowSteps.PROFILE_SELECTION;
const REMIND_ME_LATER_STEP = AuthFlowSteps.PROFILE_SELECTION;
const MY_STEP = AuthFlowSteps.PASSWORD_CREATION;

const StepInner = () => {
  const setStepData = useSetAtom(stepDataAtom);
  const setStep = useSetAtom(authStepAtom);

  const { isMobile } = useMediaQueryBreakpoint();

  const titleContent = useMemo(() => {
    return <AuthStepBanner hideBack hideStep />;
  }, []);

  useEffect(() => {
    setStepData({
      disableClose: true,
      hideFooter: true,
      disablePadding: true,
      maxWidth: "md",
      title: undefined,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, isMobile, titleContent]);

  return (
    <ResponsiveAuthContent
      titleContent={titleContent}
      {...takeControlText}
      src={`url(${AddPhone})`}
    >
      <Text variant={TextStyleVariant.H6}>Add a password</Text>
      <Box
        width={"100%"}
        my={"auto"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
      >
        <Text variant={TextStyleVariant.P1}>
          Add a password to secure your account and log in.
        </Text>
        <ResetPassword
          fullSizeAddButton
          showHeader={false}
          onComplete={() => {
            setStep(NEXT_STEP);
          }}
        />
      </Box>
      <Button
        variant={ButtonVariant.UNSTYLED}
        sx={(theme) => ({
          textDecoration: "underline",
          alignSelf: "flex-end",
          color: theme.palette.text.primary,
        })}
        onClick={() => {
          emitAnalyticsTrackingEvent(
            "auth_add_password_clicked_remind_me_later",
            {},
          );
          setStep(REMIND_ME_LATER_STEP);
        }}
      >
        Remind me later
      </Button>
    </ResponsiveAuthContent>
  );
};

export const AddPasswordStep = () => {
  return (
    <AuthStepWrapper step={MY_STEP}>
      <StepInner />
    </AuthStepWrapper>
  );
};
