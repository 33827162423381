import { faAddressCard } from "@fortawesome/pro-regular-svg-icons";
import { Box, Stack } from "@mui/material";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai/index";
import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { codeAtom } from "../../../../atoms/location/codeAtom";
import { editMode } from "../../../../atoms/profileScreenEdit";
import { TRACKING_EVENTS_NAME } from "../../../../constants/trackingEvents";
import { useUpdateUserEngineer } from "../../../../hooks/engineerHooks/useUpdateUserEngineer";
import { useGetFrequentlyAskedQuestions } from "../../../../hooks/profileScreenHooks/useGetFrequentlyAskedQuestions";
import { useGetUserProfile } from "../../../../hooks/profileScreenHooks/useGetUserProfile";
import { useGetEngineerServices } from "../../../../hooks/useGetEngineerServices";
import { useQuery } from "../../../../hooks/useQuery";
import { fetchUniqueBookingLink } from "../../../../store/actions/booking";
import { applyPromoCode } from "../../../../store/actions/marketing";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ProjectType } from "../../../../store/models/project";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { SoundWaveLoader } from "../../../elements/SoundWaveLoader/SoundWaveLoader";
import {
  TOGGLE_SWITCH_SIZE,
  ToggleSwitch,
} from "../../../elements/ToggleSwitch/ToggleSwitch";
import { CenteredFlexContainer } from "../ProfileScreen.styles";
import { AboutMe } from "./AboutMe/AboutMe";
import { NoDataContainer, StyledEmptyFaIcon } from "./AboutMe/AboutMe.styles";
import { EngineerServiceCards } from "./EngineerServiceCards";
import { FrequentlyAskedSectionWrapper } from "./FrequentlyAskedSection/FrequentlyAskedSectionWrapper";
import { isActiveUserAandRAtom } from "../../../../atoms/user/activeUserAtom";

export const ServicesTab = () => {
  const { username } = useParams<{ username: string }>();
  const discountCode = useAtomValue(codeAtom);
  const dispatch = useAppDispatch();
  const queryLocation = useQuery();
  const { user: userMe } = useAppSelector((state) => state.accountInfo);
  const isAandR = useAtomValue(isActiveUserAandRAtom);
  const [isEditMode] = useAtom(editMode);
  const { mutate: updateUserEngineer, isPending: isUpdatingUserEngineer } =
    useUpdateUserEngineer();

  const userOnOwnProfile = useMemo(() => {
    return userMe?.username === username;
  }, [userMe?.username, username]);
  const { data: userData } = useGetUserProfile(username);
  const engineerId = userData?.engineer?.id;

  const {
    data: engineerServicesData,
    isFetching: isEngineerServicesFetching,
    isSuccess: isEngineerServicesSuccess,
    isLoading: isInitialLoadingEngineerServices,
  } = useGetEngineerServices(engineerId);

  const { data: faqData, isLoading: isInitialLoadingFaqs } =
    useGetFrequentlyAskedQuestions(userData?.id);

  const [editServiceType, setEditServiceType] = useState<
    ProjectType | undefined
  >();
  const [searchParams] = useSearchParams();
  const addNewServiceQueryParam = useMemo(
    () => searchParams.get("add_new_service"),
    [searchParams],
  );
  const [addServiceType, setAddServiceType] = useState<ProjectType | undefined>(
    () => {
      const parsedService = parseInt(addNewServiceQueryParam || "-1");
      if (
        userOnOwnProfile &&
        parsedService > ProjectType.NO_TYPE &&
        parsedService <= ProjectType.ATMOS_MIXING
      ) {
        return parsedService;
      }
    },
  );

  // Apply the discount code or promo code specified in the URL query params.
  // Discount code takes priority over promo code.
  useEffect(() => {
    if (!userData?.id) return;

    const promoCode = queryLocation.get("promocode");
    if (!discountCode && !promoCode) return;
    if (discountCode) {
      void dispatch(fetchUniqueBookingLink({ code: discountCode }));
    } else if (promoCode && !isAandR) {
      void dispatch(
        applyPromoCode({
          promocode: promoCode,
          user_id: userData.id,
        }),
      ).unwrap();
    }
  }, [dispatch, queryLocation, isAandR, userData?.id, discountCode]);

  const renderServicesContent = () => {
    if (isEngineerServicesFetching && !editServiceType && !addServiceType) {
      return (
        <CenteredFlexContainer>
          <SoundWaveLoader width={100} height={100} />
        </CenteredFlexContainer>
      );
    }

    if (isEngineerServicesSuccess && engineerServicesData && engineerId) {
      return (
        <EngineerServiceCards
          combinedServices={engineerServicesData}
          userData={userData}
          editMode={isEditMode}
          engineer={userData.engineer!}
          userOnOwnProfile={userOnOwnProfile}
          editServiceType={editServiceType}
          setEditServiceType={setEditServiceType}
          addServiceType={addServiceType}
          setAddServiceType={setAddServiceType}
        />
      );
    }

    return null;
  };

  if (!userData) {
    return null;
  }

  const renderAboutMe = () => {
    if (isEditMode || (!userData?.profile?.long_bio && !userOnOwnProfile)) {
      return null;
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}>
        <AboutMe />
      </Box>
    );
  };

  const renderServicesSection = () => {
    if (
      !engineerServicesData ||
      (engineerServicesData.length === 0 && !isEditMode)
    ) {
      return null;
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "24px" }}>
        <Text variant={TextStyleVariant.S2}>Services</Text>
        {isEditMode && (
          <Stack
            direction="row"
            gap={1}
            sx={{
              opacity: isUpdatingUserEngineer ? 0.5 : 1,
              cursor: isUpdatingUserEngineer ? "not-allowed" : "unset",
            }}
          >
            <Text bold variant={TextStyleVariant.P2}>
              Disable Bookings
            </Text>

            <ToggleSwitch
              size={TOGGLE_SWITCH_SIZE.MEDIUM}
              disabled={isUpdatingUserEngineer}
              checked={!userData.engineer?.not_booking}
              updateCheckedLocally={false}
              onChange={() => {
                emitAnalyticsTrackingEvent(
                  TRACKING_EVENTS_NAME.ENGINEER_TOGGLE_BOOKING,
                  {
                    enable_booking: !userData.engineer?.not_booking,
                  },
                );
                updateUserEngineer({
                  not_booking: !userData.engineer?.not_booking,
                });
              }}
            />
            <Text bold variant={TextStyleVariant.P2}>
              Enable Bookings
            </Text>
          </Stack>
        )}
        {renderServicesContent()}
      </Box>
    );
  };

  // On the initial loading state of data, show a loading indicator
  if (isInitialLoadingEngineerServices || isInitialLoadingFaqs) {
    return (
      <CenteredFlexContainer>
        <SoundWaveLoader width={100} height={100} />
      </CenteredFlexContainer>
    );
  }

  // If there is no displayable information, show the No Info Card
  if (
    !isEditMode &&
    engineerServicesData &&
    engineerServicesData.length === 0 &&
    faqData.length === 0 &&
    !userData?.profile?.long_bio
  ) {
    return (
      <NoDataContainer>
        <StyledEmptyFaIcon icon={faAddressCard} />
        <Text>No info yet</Text>
      </NoDataContainer>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        rowGap: "48px",
        [theme.breakpoints.down("md")]: {
          rowGap: "32px",
        },
      })}
    >
      {renderAboutMe()}

      {renderServicesSection()}
      <FrequentlyAskedSectionWrapper
        faqData={faqData}
        isInitialLoadingFaqs={isInitialLoadingFaqs}
      />
    </Box>
  );
};
