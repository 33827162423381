import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { EmailOrUsernameField } from "../EmailOrUsernameField";
import { validateEmail } from "../../components/validators/emailValidators";
import { ValidationLabelProps } from "../../../AccountSettingsModal/tabs/ValidationLabel.types";
import { SignInPasswordField } from "../SignIn/SignInPasswordField";
import { Button } from "../../../../core-ui/components/Button/Button";
import { validatePassword } from "../../components/validators/passwordValidators";
import { SignInUsernameParameter } from "../../constants";
import { useSearchParams } from "react-router-dom";

interface SignUpInternalProps {
  loading?: boolean;
}

const SignUpInternal = ({ loading }: SignUpInternalProps) => {
  const [searchParams] = useSearchParams({
    [SignInUsernameParameter]: "",
  });
  const [email, setEmail] = useState(
    searchParams.get(SignInUsernameParameter) || "",
  );
  const isValidEmail = useMemo(
    () => Object.keys(validateEmail(email)).length === 0,
    [email],
  );

  const [hasPasswordErrors, setHasPasswordErrors] = useState<
    Record<string, ValidationLabelProps>
  >(validatePassword(""));

  const validPassword = useMemo(() => {
    return (
      Object.values(hasPasswordErrors).findIndex((data) => data.invalid) === -1
    );
  }, [hasPasswordErrors]);

  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        setHasPasswordErrors(validatePassword(e.target.value, true));
      },
      [setHasPasswordErrors],
    );
  return (
    <>
      <EmailOrUsernameField
        emailOnly
        isValid={isValidEmail}
        TextFieldProps={{
          onChange: (e) => {
            setEmail(e.target.value);
          },
        }}
      />
      <SignInPasswordField
        validationLabels={hasPasswordErrors}
        TextFieldProps={{ onChange: handlePasswordChange }}
      />
      <Button
        disabled={!isValidEmail || !validPassword || loading}
        fullWidth
        loading={loading}
        type={"submit"}
      >
        Submit
      </Button>
    </>
  );
};

export default SignUpInternal;
