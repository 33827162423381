import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { Box } from "@mui/material";
import { MouseEventHandler, ReactNode } from "react";

interface AuthStepBannerProps {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  currentStep?: number;
  maxStep?: number;
  hideBack?: boolean;
  hideStep?: boolean;
}

export const AuthStepBanner = ({
  children,
  onClick,
  currentStep,
  maxStep,
  hideBack,
  hideStep,
}: AuthStepBannerProps) => {
  return (
    <Box
      className={"titleContent"}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={"100%"}
      mb={"auto"}
    >
      <Button
        sx={{
          display: "flex",
          gap: 1,
          ...(hideBack && { opacity: 0, pointerEvents: "none" }),
        }}
        variant={ButtonVariant.UNSTYLED}
        onClick={hideBack ? undefined : onClick}
        type={hideBack ? "button" : "reset"}
      >
        <FontAwesomeIcon icon={faChevronLeft} /> Back
      </Button>
      {children}
      {!hideStep && (
        <Box>
          Step {currentStep} of {maxStep}
        </Box>
      )}
    </Box>
  );
};
