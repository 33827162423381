import useDarkModeControls from "./commands/useDarkModeControls";
import useHelpControls from "./commands/useHelpControls";
import { useJotaiDevToolsControls } from "./commands/useJotaiDevToolsControls";

/**
 * Sets up power user controls.
 *
 * This hook sets up all specified power user controls.
 *
 * It stores the shortcuts in the `powerControlKeysListAtom` atom, so that
 * other components can access them.
 *
 * The effect is run once when the component mounts, and never again.
 */
const usePowerUserControls = () => {
  useDarkModeControls();
  useHelpControls();
  useJotaiDevToolsControls();
};

export default usePowerUserControls;
