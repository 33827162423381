import { Box, Grid2 } from "@mui/material";
import { AuthBannerText } from "./AuthBannerText";
import { ResponsiveAuthBannerProps } from "../types";

export const ResponsiveAuthBanner = ({
  children,
  compact,
  gridProps = {},
  src,
  title,
  bulletPoints,
  bottomText,
}: ResponsiveAuthBannerProps) => {
  if (!src) return children;
  return (
    <Grid2 container height={"100%"}>
      {src && !compact && (
        <Grid2
          bgcolor={"black"}
          size={{ xs: null, md: 6 }}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              minHeight: "560px",
            },
          })}
        >
          <Box
            flexDirection={"column"}
            height={"100%"}
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.up("md")]: {
                display: "flex",
              },
            })}
          >
            <Box
              sx={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                mask: "linear-gradient(black 60%, transparent)",
                height: "259px",
                backgroundImage: src,
              }}
            />
            <Box
              px={1.15}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flex={1}
            >
              {title && bottomText && bulletPoints && (
                <AuthBannerText
                  title={title}
                  bottomText={bottomText}
                  bulletPoints={bulletPoints}
                />
              )}
            </Box>
          </Box>
        </Grid2>
      )}
      <Grid2 size={{ xs: 12, md: "grow" }} {...gridProps}>
        {children}
      </Grid2>
    </Grid2>
  );
};
