import { useCallback, useEffect } from "react";
import "./UnauthenticatedModal.css";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  updateUserArtist,
  updateUserEngineer,
} from "../../../store/actions/accountInfo";
import { BaseModal } from "../../core-ui/components/BaseModal/BaseModal";
import { Button, ButtonVariant } from "../../core-ui/components/Button/Button";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { PAGE_HASH } from "../../../atoms/location/locationAtom";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../atoms/location/authFlowAtoms";
import { useSetAtom } from "jotai";

export interface UnauthenticatedModalProps {
  showModal?: boolean;
  closeModal: () => void;
  showCloseButton?: boolean;
  addArtistAccountType?: boolean;
  addEngineerAccountType?: boolean;
  message?: string;
}

export const UnauthenticatedModal = ({
  closeModal = () => {},
  showModal = false,
  addArtistAccountType = false,
  addEngineerAccountType = false,
  message = "Please sign in or sign up to book.",
  showCloseButton = true,
}: UnauthenticatedModalProps) => {
  const isAuthenticated = useAppSelector(
    (state) => state.accountInfo.isAuthenticated,
  );
  const userArtistProfile = useAppSelector(
    (state) => state.accountInfo?.user?.artist,
  );
  const userEngineerProfile = useAppSelector(
    (state) => state.accountInfo?.user?.engineer,
  );
  const dispatch = useAppDispatch();
  const setStep = useSetAtom(authStepAtom);
  const unauthenticatedModalClassname = classNames("unauthenticated-modal");
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (userArtistProfile == null && addArtistAccountType) {
      void dispatch(updateUserArtist({ is_primary_type: true, deleted: false }))
        .unwrap()
        .then(() => {
          handleClose();
        });
      return;
    }
    if (userEngineerProfile == null && addEngineerAccountType) {
      void dispatch(
        updateUserEngineer({ is_primary_type: true, deleted: false }),
      )
        .unwrap()
        .then(() => {
          handleClose();
        });
      return;
    }
    handleClose();
  }, [
    isAuthenticated,
    userArtistProfile,
    addArtistAccountType,
    userEngineerProfile,
    addEngineerAccountType,
    dispatch,
    handleClose,
  ]);

  return (
    <BaseModal
      open={showModal}
      setOpen={handleClose}
      header={"Authentication Required to Continue"}
      showCloseButton={showCloseButton}
    >
      <div className={unauthenticatedModalClassname}>
        <p className="h7-semi-bold unauthenticated-modal-header mb-3">
          {message}
        </p>

        <div className={"unauthenticated-modal-button-container show"}>
          <Button
            fullWidth={true}
            onClick={() => {
              // By setting the flow to complete we allow the user to ignore any onboarding flow after sign in.
              setStep(AuthFlowSteps.ONBOARDING_COMPLETE);
              navigate(PAGE_HASH.SIGN_IN);
              handleClose();
            }}
            variant={ButtonVariant.PRIMARY}
          >
            Login
          </Button>
          <Button
            fullWidth={true}
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              // By setting the flow to complete we allow the user to ignore any onboarding flow after sign in.
              setStep(AuthFlowSteps.ONBOARDING_COMPLETE);
              navigate(PAGE_HASH.SIGN_UP);
              handleClose();
            }}
          >
            Signup
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};
