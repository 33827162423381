import { ValidationLabelProps } from "../../../AccountSettingsModal/tabs/ValidationLabel.types";
import { ValidationTest } from "./types";

export const validateInput = (
  value: string,
  validationTests: ValidationTest[],
  initialResults: Record<string, ValidationLabelProps> = {},
  showAllPassing = false,
) => {
  let hadError = false;
  const results: Record<string, ValidationLabelProps> = { ...initialResults };
  validationTests.forEach((test) => {
    results[test.key] = { invalid: false, ...test.label };
    if (!value || test.func(value)) {
      hadError = true;
      results[test.key].invalid = true;
    }
  });
  if (!hadError && !showAllPassing)
    return {} as Record<string, ValidationLabelProps>;
  return results;
};
