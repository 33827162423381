import {
  TextColor,
  TextStyleVariant,
} from "../../../../../core-ui/components/Text/TextUtils";
import { Text, TEXT_WEIGHT } from "../../../../../core-ui/components/Text/Text";
import { MouseEventHandler, useCallback, useRef } from "react";
import { FormControlLabel, Radio } from "@mui/material";
import {
  getImageSourceAndText,
  MajorLabelEnum,
  UMGSubLabelEnum,
} from "../../../../../../store/models/trophy";
import LabelOptionCardInfo from "./styles/LabelOptionCardInfo.styles";
import LabelOptionCardContainer from "./styles/LabelOptionCardContainer.styles";

export interface LabelOptionCardProps {
  option: UMGSubLabelEnum;
  isDisabled: boolean;
  onClick?: (discipline: UMGSubLabelEnum, checked: boolean) => void;
  defaultValue?: boolean;
}

export const LabelOptionCard = ({
  option,
  defaultValue,
  isDisabled = false,
  onClick = () => {},
}: LabelOptionCardProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const { source, text } = getImageSourceAndText(option, MajorLabelEnum.UMG);

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if ((e.target as HTMLElement).tagName !== "INPUT") {
        e.preventDefault();
        ref.current?.click();
        return;
      }
      onClick(option, (e.target as HTMLInputElement).checked);
    },
    [onClick, ref, option],
  );
  return (
    <LabelOptionCardContainer
      onFocus={(e) => {
        // Need to get the visible parent element
        e.target.parentElement?.parentElement?.parentElement?.scrollIntoView({
          inline: "center",
          block: "center",
          behavior: "smooth",
        });
      }}
      onClick={isDisabled ? undefined : handleClick}
    >
      <FormControlLabel
        control={
          <Radio
            disabled={isDisabled}
            sx={{ opacity: 0, pointerEvents: "none", position: "absolute" }}
            inputRef={ref}
            value={option}
            name={"label"}
            defaultChecked={defaultValue}
          />
        }
        sx={{ position: "absolute" }}
        label=""
      />
      <LabelOptionCardInfo className="option-card-info">
        {option === UMGSubLabelEnum.UMG_UNKNOWN && (
          <Text
            variant={TextStyleVariant.P1}
            weight={TEXT_WEIGHT.SEMI_BOLD}
            color={TextColor.BLACK}
          >
            {text ?? ""}
          </Text>
        )}
        {option !== UMGSubLabelEnum.UMG_UNKNOWN && (
          <img src={source} alt={text ?? ""} width={"100%"} height={"auto"} />
        )}
      </LabelOptionCardInfo>

      <Text variant={TextStyleVariant.P1} weight={TEXT_WEIGHT.SEMI_BOLD}>
        {text ?? ""}
      </Text>
    </LabelOptionCardContainer>
  );
};
