import AuthStepWrapper from "../AuthStepWrapper";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../../../atoms/location/authFlowAtoms";
import { useAtomValue, useSetAtom } from "jotai";
import { stepDataAtom } from "../../../atoms";
import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { Button } from "../../../../../core-ui/components/Button/Button";
import { Text } from "../../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../../core-ui/components/Text/TextUtils";
import { activeUserAtom } from "../../../../../../atoms/user/activeUserAtom";
import {
  getAdminDashboardRoute,
  getDashboardRoute,
  getProfileScreenRoute,
  getStudioScreenRoute,
} from "../../../../../../store/utils/routeGetters";
import { activeProfileAtom } from "../../../../../../atoms/user/activeProfileAtom";
import { ProfileType } from "../../../../../../store/models/base";
import Link from "../../../../../elements/Link/Link";
import { ArtistOnboardingSurveyForm } from "./ArtistOnboardingSurveyForm/ArtistOnboardingSurveyForm";

const NEXT_STEP = AuthFlowSteps.ONBOARDING_COMPLETE;
const MY_STEP = AuthFlowSteps.ONBOARDING_SURVEY;

const OnboardingSurveyCompletion = () => {
  const activeUser = useAtomValue(activeUserAtom);
  const activeProfile = useAtomValue(activeProfileAtom);
  const setStep = useSetAtom(authStepAtom);
  const setStepData = useSetAtom(stepDataAtom);

  const redirectPath = useMemo(() => {
    if (activeProfile?.type === ProfileType.STUDIO) {
      return { to: getStudioScreenRoute(activeProfile.username) };
    } else if (
      activeProfile?.type === ProfileType.USER &&
      !activeUser?.disciplines?.admin &&
      !activeUser?.disciplines?.aandr
    ) {
      return {
        to: getProfileScreenRoute(activeProfile.username),
        state: {
          showTutorial: true,
          showEngineerPromoListModal: Boolean(activeUser?.disciplines?.artist),
        },
      };
    }
    return {
      to: activeUser?.disciplines?.admin
        ? getAdminDashboardRoute()
        : getDashboardRoute(),
    };
  }, [
    activeProfile?.type,
    activeProfile?.username,
    activeUser?.disciplines?.admin,
    activeUser?.disciplines?.aandr,
    activeUser?.disciplines?.artist,
  ]);

  useEffect(() => {
    setStepData({
      disableClose: false,
      hideFooter: true,
      disablePadding: false,
      maxWidth: "md",
      onClose: () => {
        setStep(NEXT_STEP);
      },
      title: `Congratulations @${activeUser?.username}!`,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, setStep, activeUser?.username]);

  // If nothing is selected, show the success window!
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      p={1}
      gap={2}
      boxSizing={"border-box"}
      height={"100%"}
    >
      <Text
        sx={{ marginTop: "auto", marginBottom: "auto" }}
        variant={TextStyleVariant.H5}
      >
        Your profile has been created!
      </Text>
      <Button
        LinkComponent={Link}
        analyticsEvent={{
          name: "auth_account_create_complete",
        }}
        onClick={() => {
          setStep(NEXT_STEP);
        }}
        fullWidth
        {...redirectPath}
      >
        Go to profile
      </Button>
    </Box>
  );
};

const OnboardingSurveySelection = () => {
  const activeUser = useAtomValue(activeUserAtom);
  if (activeUser?.needs_onboarding_info) {
    return <ArtistOnboardingSurveyForm />;
  }
  // If nothing is selected, show the success window!
  return <OnboardingSurveyCompletion />;
};

export const OnboardingSurveyStep = () => {
  return (
    <AuthStepWrapper step={MY_STEP}>
      <OnboardingSurveySelection />
    </AuthStepWrapper>
  );
};
