import { FormEvent } from "react";
import {
  DISCIPLINE_TYPE,
  DISCIPLINE_TYPE_TO_USER_DISCIPLINE,
} from "../../../../../../hooks/user";
import { UserDiscipline } from "../../../../../../store/models/user";
import { ProfileUpdateThunks } from "./constants";
import { HandleProfileSelectionFormSubmitFactoryParams } from "./types";

interface FormElements extends HTMLFormControlsCollection {
  discipline: NodeListOf<HTMLInputElement>;
  otherDescription: HTMLInputElement;
}

export interface ProfileSelectionForm extends HTMLFormElement {
  readonly elements: FormElements;
}

const handleProfileSelectionFormSubmitFactory = ({
  dispatch,
  userDisciplines = {},
  primaryDiscipline,
  initialPrimaryDiscipline,
}: HandleProfileSelectionFormSubmitFactoryParams) => {
  return async (e: FormEvent<ProfileSelectionForm>): Promise<unknown> => {
    e.preventDefault();
    const form = e.target as ProfileSelectionForm;
    const disciplines = form.elements.discipline;
    const otherDescription = form.elements.otherDescription
      ? form.elements.otherDescription.value
      : "";
    const promises: unknown[] = [];
    const newDisciplines: Partial<Record<UserDiscipline, boolean>> = {};
    disciplines.forEach((element) => {
      const discipline = parseInt(element.value) as DISCIPLINE_TYPE;
      if (discipline === DISCIPLINE_TYPE.NO_TYPE) {
        // This is not possible, but helps the type checker
        return;
      }
      const userDisciplineType = DISCIPLINE_TYPE_TO_USER_DISCIPLINE[discipline];
      const userDiscipline = userDisciplines[userDisciplineType];
      if (!element.checked && !userDiscipline) {
        return;
      }
      const deleted = Boolean(userDiscipline) && !element.checked;
      newDisciplines[userDisciplineType] = !deleted;
      const updateData: Record<string, unknown> = {
        is_primary_type: primaryDiscipline === discipline,
        deleted: deleted,
      };
      if (primaryDiscipline === DISCIPLINE_TYPE.OTHER) {
        updateData.account_type_description = otherDescription;
      }
      if (ProfileUpdateThunks[discipline]) {
        promises.push(ProfileUpdateThunks[discipline](updateData));
      }
    });
    if (initialPrimaryDiscipline == primaryDiscipline) {
      const oldDisciplines = Object.keys(userDisciplines);
      const newDisciplineItems = Object.entries(newDisciplines);
      if (newDisciplineItems.length == oldDisciplines.length) {
        let everyKeyIsTheSame = true;
        for (const key in userDisciplines) {
          const oldDisciplineType = key as UserDiscipline;

          if (!newDisciplines[oldDisciplineType]) {
            everyKeyIsTheSame = false;
            break;
          }
        }
        if (everyKeyIsTheSame) {
          return Promise.all([]);
        }
      }
    }
    return await Promise.all(promises.map((p) => dispatch(p)));
  };
};

export default handleProfileSelectionFormSubmitFactory;
