import { MouseEventHandler, ReactNode, useCallback, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getMethodFromSearchParams } from "../helpers";
import { SignInMethodParameter } from "../constants";
import { PAGE_HASH } from "../../../../atoms/location/locationAtom";
import queryString from "query-string";
import { takeControlText } from "../../Auth/AuthBannerInfoWrapper";
import Start from "../../../assets/authBanner/start.png";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import Link from "../../../elements/Link/Link";
import { ResponsiveAuthContent } from "../components/ResponsiveAuthContent";
import { Box } from "@mui/material";
import { SignUpOrInOptions } from "./SignUpOrInOptions";

interface SignUpOrInAllOptionsProps {
  children?: ReactNode;
  compact?: boolean;
  label?: ReactNode;
  // If false, then sign in mode
  signUpMode?: boolean;
}

export const SignUpOrInAllOptions = ({
  children,
  compact,
  label,
  signUpMode,
}: SignUpOrInAllOptionsProps) => {
  const [searchParams] = useSearchParams({
    [SignInMethodParameter]: "",
  });
  const { search } = useLocation();
  const navigate = useNavigate();

  const method = useMemo(
    () => getMethodFromSearchParams(searchParams),
    [searchParams],
  );

  const handleClickSignUpOrIn: MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.preventDefault();
        navigate(
          {
            hash: signUpMode ? PAGE_HASH.SIGN_IN : PAGE_HASH.SIGN_UP,
            search: queryString.stringify({
              ...queryString.parse(search),
              method: undefined,
            }),
          },
          { replace: false },
        );
      },
      [search, navigate, signUpMode],
    );

  const SignUpOrInLink = useMemo(() => {
    return (
      <Link
        color={"secondary"}
        sx={{ display: "inline-block" }}
        to={signUpMode ? PAGE_HASH.SIGN_IN : PAGE_HASH.SIGN_UP}
        onClick={handleClickSignUpOrIn}
      >
        Sign {signUpMode ? "In" : "Up"}!
      </Link>
    );
  }, [handleClickSignUpOrIn, signUpMode]);

  return (
    <ResponsiveAuthContent
      compact={compact}
      {...takeControlText}
      src={`url(${Start})`}
    >
      <Box
        width={"100%"}
        my={"auto"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={2}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={2}
          width={"100%"}
        >
          <Text
            sx={
              compact
                ? {}
                : (theme) => ({
                    display: "none",
                    [theme.breakpoints.up("md")]: {
                      display: "unset",
                    },
                  })
            }
            variant={TextStyleVariant.H6}
          >
            {signUpMode ? "Create new account" : "Sign into your account"}
          </Text>
          {label || compact ? (
            <>
              {label}
              {SignUpOrInLink}
            </>
          ) : (
            <Text variant={TextStyleVariant.P1}>
              {signUpMode ? "Already a member?" : "Don't have an account?"}{" "}
              {SignUpOrInLink}
            </Text>
          )}
        </Box>
        {children}
        <SignUpOrInOptions
          signUpMode={signUpMode}
          compact={compact}
          method={method}
        />
      </Box>
    </ResponsiveAuthContent>
  );
};
