import { ResponsiveDialog } from "../../../core-ui/components/ResponsiveDialog/ResponsiveDialog";
import Form from "../../../elements/Form/Form";
import useHashBoolean from "../../../../hooks/reactRouterHooks/useHashBoolean";
import { PAGE_HASH } from "../../../../atoms/location/locationAtom";
import { FormEventHandler, useCallback, useMemo } from "react";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { Text } from "../../../core-ui/components/Text/Text";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import {
  extractSignUpOrInDataFromForm,
  getMethodFromSearchParams,
} from "../helpers";
import useLoginMutation from "../../../../hooks/authHooks/useLoginMutation";
import useSignUpMutation from "../../../../hooks/authHooks/useSignUpMutation";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import queryString from "query-string";
import { compactSignInOpenAtom } from "../atoms";
import { useAtomValue } from "jotai";
import { SignUpOrInMethodsEnum } from "../types";
import SignUpInternal from "./SignUp/SignUpInternal";
import SignInInternal from "./SignIn/SignInInternal";
import SignInPhone from "./SignIn/SignInPhone";
import { SignUpOrInAllOptions } from "./SignUpOrInAllOptions";
import { SignInMethodParameter } from "../constants";

interface SignUpOrInFormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
  password: HTMLInputElement;
}

interface SignUpOrInFormElement extends HTMLFormElement {
  readonly elements: SignUpOrInFormElements;
}

const SignUpOrInModal = () => {
  const isCompactOpen = useAtomValue(compactSignInOpenAtom);
  const [searchParams] = useSearchParams({
    [SignInMethodParameter]: "",
  });
  const { isOpen: isSignUpOpen } = useHashBoolean({
    value: PAGE_HASH.SIGN_UP,
  });
  const { isOpen: isSignInOpen } = useHashBoolean({ value: PAGE_HASH.SIGN_IN });
  const { search } = useLocation();
  const navigate = useNavigate();
  const { mutateAsync: loginAsync, isPending: loginPending } =
    useLoginMutation();
  const { mutateAsync: signUpAsync, isPending: signUpPending } =
    useSignUpMutation();
  const { isDesktop } = useMediaQueryBreakpoint();
  const handleSuccessfulSignInOrUp = useCallback(() => {
    navigate(
      {
        hash: "#",
        search: queryString.stringify({
          ...queryString.parse(search),
          method: undefined,
        }),
      },
      { replace: true },
    );
  }, [search, navigate]);
  const handleClose = useCallback(() => {
    handleSuccessfulSignInOrUp();
  }, [handleSuccessfulSignInOrUp]);

  const isOpen = useMemo(
    () => (isCompactOpen ? false : isSignUpOpen || isSignInOpen),
    [isSignUpOpen, isSignInOpen, isCompactOpen],
  );

  const method = useMemo(
    () => getMethodFromSearchParams(searchParams),
    [searchParams],
  );

  const handleSignIn: FormEventHandler<SignUpOrInFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const { username, isUmgEmail, password } =
        extractSignUpOrInDataFromForm(e);
      if (isUmgEmail) {
        // Trigger UMG SSO Auth
        return;
      }
      // Trigger normal sign in with username/password
      void loginAsync({
        username: username,
        password: password,
      }).then(() => {
        handleClose();
      });
    },
    [loginAsync, handleClose],
  );
  const handleSignUp: FormEventHandler<SignUpOrInFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const { username, isEmail, isUmgEmail, password } =
        extractSignUpOrInDataFromForm(e);
      if (isUmgEmail) {
        // Trigger UMG SSO Auth
      }
      // Trigger normal sign in with username/password
      void signUpAsync({
        username: isEmail ? "" : username,
        email: isEmail ? username : "",
        password: password,
      }).then(() => {
        handleClose();
      });
    },
    [signUpAsync, handleClose],
  );

  const handleSubmit: FormEventHandler<SignUpOrInFormElement> = useMemo(
    () =>
      isSignUpOpen ? handleSignUp : isSignInOpen ? handleSignIn : () => {},
    [isSignUpOpen, isSignInOpen, handleSignIn, handleSignUp],
  );

  const titleContent = useMemo(() => {
    if (isSignUpOpen) {
      return <Text variant={TextStyleVariant.H6}>Create a new account</Text>;
    }
    if (isSignInOpen) {
      return <Text variant={TextStyleVariant.H6}>Sign into your account</Text>;
    }
    return <></>;
  }, [isSignUpOpen, isSignInOpen]);

  const signUpMethod = useMemo(() => {
    if (method === SignUpOrInMethodsEnum.INTERNAL) {
      return <SignUpInternal loading={signUpPending} />;
    }
    return null;
  }, [method, signUpPending]);
  const signInMethod = useMemo(() => {
    if (method === SignUpOrInMethodsEnum.INTERNAL) {
      return <SignInInternal loading={loginPending} />;
    }

    if (method === SignUpOrInMethodsEnum.PHONE) {
      return <SignInPhone />;
    }
    return null;
  }, [method, loginPending]);

  const contentChildren = useMemo(() => {
    return (
      <SignUpOrInAllOptions signUpMode={isSignUpOpen}>
        {isSignUpOpen ? signUpMethod : signInMethod}
      </SignUpOrInAllOptions>
    );
  }, [isSignUpOpen, signUpMethod, signInMethod]);
  return (
    <ResponsiveDialog
      open={isOpen}
      onClose={handleClose}
      component={Form}
      fullWidth={true}
      maxWidth={"md"}
      // @ts-expect-error the component has a different type
      onSubmit={handleSubmit}
      titleProps={{
        children: !isDesktop ? titleContent : undefined,
      }}
      contentProps={{
        children: contentChildren,
        dividers: false,
        sx: {
          padding: 0,
        },
      }}
    />
  );
};

export default SignUpOrInModal;
