import AuthStepWrapper from "./AuthStepWrapper";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../../atoms/location/authFlowAtoms";
import { useAtomValue, useSetAtom } from "jotai";
import { stepDataAtom } from "../../atoms";
import { useCallback, useEffect, useMemo, useState } from "react";
import { takeControlText } from "../../../Auth/AuthBannerInfoWrapper";
import { Box } from "@mui/material";
import {
  Button,
  ButtonVariant,
} from "../../../../core-ui/components/Button/Button";
import { useMediaQueryBreakpoint } from "../../../../../hooks/useMediaQuery";
import { Text } from "../../../../core-ui/components/Text/Text";
import {
  TextColor,
  TextStyleVariant,
} from "../../../../core-ui/components/Text/TextUtils";
import { AuthStepBanner } from "./AuthStepBanner";
import { ResponsiveAuthContent } from "../ResponsiveAuthContent";
import VerifyEmail from "../../../../assets/authBanner/verify_email.png";
import VerificationCodeInput from "../../../Auth/VerificationCodeInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { SoundWaveLoader } from "../../../../elements/SoundWaveLoader/SoundWaveLoader";
import { emitAnalyticsTrackingEvent } from "../../../../../utils/analyticsUtils";
import useSendEmailVerificationCodeMutation from "../../../../../hooks/authHooks/useSendEmailVerificationCodeMutation";
import useVerifyAccountMutation from "../../../../../hooks/authHooks/useVerifyAccountEmailMutation";
import useActiveUserQuery from "../../../../../hooks/userHooks/useActiveUserQuery";
import { isActiveUserLabelUserAtom } from "../../../../../atoms/user/activeUserAtom";
import { tokenAtom } from "../../../../../atoms/auth/tokenAtom";

const NEXT_STEP = AuthFlowSteps.PHONE_COLLECTION;
const LABEL_USER_NEXT_STEP = AuthFlowSteps.PROFILE_SELECTION;
const MY_STEP = AuthFlowSteps.EMAIL_VERIFICATION;

const StepInner = () => {
  const setStepData = useSetAtom(stepDataAtom);
  const setToken = useSetAtom(tokenAtom);
  const setStep = useSetAtom(authStepAtom);
  const isLabelUser = useAtomValue(isActiveUserLabelUserAtom);
  const [sentInitialCode, setSentInitialCode] = useState(false);
  const { data: activeUser, refetch: reloadActiveUser } = useActiveUserQuery();
  const {
    mutateAsync: verifyAccountEmail,
    isError: verificationError,
    isPending: isVerifyingAccountEmail,
  } = useVerifyAccountMutation();
  const {
    mutateAsync: resendVerificationCode,
    isPending,
    isSuccess,
    reset: resetSendVerificationCode,
  } = useSendEmailVerificationCodeMutation();

  const { isMobile } = useMediaQueryBreakpoint();

  const titleContent = useMemo(() => {
    return (
      <AuthStepBanner
        onClick={() => {
          setStep(AuthFlowSteps.PROFILE_CREATION);
        }}
        currentStep={isLabelUser ? 1 : 2}
        maxStep={isLabelUser ? 4 : 3}
        hideBack={isLabelUser}
      />
    );
  }, [setStep, isLabelUser]);

  const handleVerificationCodeComplete = useCallback(
    (code: string) => {
      void verifyAccountEmail({
        verification: code,
        user_id: activeUser!.id.toString(),
        send_verification_code: true,
      }).then(async () => {
        emitAnalyticsTrackingEvent("auth_verify_email_used_code", {});
        return reloadActiveUser().then(() => {
          setStep(isLabelUser ? LABEL_USER_NEXT_STEP : NEXT_STEP);
        });
      });
    },
    [verifyAccountEmail, isLabelUser, activeUser, setStep, reloadActiveUser],
  );

  const handleResendVerificationCode = useCallback(async () => {
    emitAnalyticsTrackingEvent(
      "auth_verify_email_click_resend_email_verification",
      {},
    );
    return resendVerificationCode({}).then(() => {
      setTimeout(() => {
        resetSendVerificationCode();
      }, 5000);
    });
  }, [resetSendVerificationCode, resendVerificationCode]);

  useEffect(() => {
    setStepData({
      disableClose: true,
      hideFooter: true,
      disablePadding: true,
      showDividers: false,
      maxWidth: "md",
      title: isMobile ? titleContent : undefined,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, isMobile, titleContent]);

  useEffect(() => {
    if (activeUser?.email_verified) {
      // Since they are verified, we go to the next step
      setStep(
        // if the have a phone number, skip to the verification step
        isLabelUser
          ? LABEL_USER_NEXT_STEP
          : activeUser?.phone_number
            ? AuthFlowSteps.PHONE_VERIFICATION
            : NEXT_STEP,
      );
    } else if (!sentInitialCode) {
      setSentInitialCode(true);
      void handleResendVerificationCode();
    }
  }, [
    handleResendVerificationCode,
    isLabelUser,
    sentInitialCode,
    setSentInitialCode,
    setStep,
    activeUser?.email_verified,
    activeUser?.phone_number,
  ]);
  return (
    <ResponsiveAuthContent
      titleContent={titleContent}
      {...takeControlText}
      src={`url(${VerifyEmail})`}
    >
      <Box
        gap={2}
        width={"100%"}
        height={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        sx={{
          "& .verificationCodeInput": {
            width: "100%",
          },
        }}
      >
        <Box gap={1} display={"flex"} flexDirection={"column"}>
          <Text variant={TextStyleVariant.H6}>Confirm your email</Text>
          <Text variant={TextStyleVariant.P1} sx={{ wordBreak: "break-word" }}>
            Enter the verification code we emailed to:
            <br />
            {activeUser?.email}
          </Text>
        </Box>
        <VerificationCodeInput
          disabled={isVerifyingAccountEmail}
          codeLength={6}
          onComplete={handleVerificationCodeComplete}
        />
        {verificationError && (
          <Text variant={TextStyleVariant.P1} color={TextColor.ERROR}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faXmark} />
            Invalid verification code
          </Text>
        )}
        {isPending ? (
          <SoundWaveLoader width={100} height={100} />
        ) : isSuccess ? (
          <Text variant={TextStyleVariant.P1} color={TextColor.SUCCESS}>
            <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faCheck} />
            Verification code resent
          </Text>
        ) : (
          <Text variant={TextStyleVariant.P1}>
            <Button
              variant={ButtonVariant.TEXT}
              style={{ display: "inline", fontWeight: "600" }}
              onClick={handleResendVerificationCode}
            >
              Resend a code
            </Button>{" "}
            or click the link in the email.
          </Text>
        )}
        {!isLabelUser ? (
          <Button
            variant={ButtonVariant.UNSTYLED}
            sx={(theme) => ({
              textDecoration: "underline",
              alignSelf: "flex-end",
              color: theme.palette.text.primary,
            })}
            onClick={() => {
              emitAnalyticsTrackingEvent(
                "auth_verify_email_click_remind_me_later",
                {},
              );
              setStep(NEXT_STEP);
            }}
          >
            Remind me later
          </Button>
        ) : (
          <Button
            variant={ButtonVariant.UNSTYLED}
            sx={(theme) => ({
              textDecoration: "underline",
              alignSelf: "flex-end",
              color: theme.palette.text.primary,
            })}
            onClick={() => {
              setToken("");
              setStep(AuthFlowSteps.NONE);
            }}
          >
            Switch Accounts
          </Button>
        )}
      </Box>
    </ResponsiveAuthContent>
  );
};

export const EmailVerificationStep = () => {
  return (
    <AuthStepWrapper step={MY_STEP}>
      <StepInner />
    </AuthStepWrapper>
  );
};
