import { ResponsiveDialog } from "../../../core-ui/components/ResponsiveDialog/ResponsiveDialog";
import { useAtomValue } from "jotai";
import Form from "../../../elements/Form/Form";
import { FormEvent, useCallback, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import useHashBoolean from "../../../../hooks/reactRouterHooks/useHashBoolean";
import { PAGE_HASH } from "../../../../atoms/location/locationAtom";
import {
  StudioProfileCreation,
  UsernameSelectionForm,
} from "./steps/UsernameSelectionStep/StudioProfileCreation";
import { activeStudioProfileAtom } from "../../../../atoms/user/activeProfileAtom";
import useCreateProfileOnboardingSurveyDataMutation from "../../../../hooks/marketingHooks/useCreateProfileOnboardingSurveyDataMutation";
import useUpdateStudioMutation from "../../../../hooks/studioHooks/useUpdateStudioMutation";
import { useMediaQueryBreakpoint } from "../../../../hooks/useMediaQuery";
import { TrackableClassNames } from "../../../../constants/trackableClassNames";
import IconButton from "@mui/material/IconButton/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { isActiveUserStudioManagerAtom } from "../../../../atoms/user/activeUserAtom";
import { useNavigate } from "react-router-dom";
import { getStudioScreenRoute } from "../../../../store/utils/routeGetters";
import PlaceResult = google.maps.places.PlaceResult;

export const StudioCreationModal = () => {
  const { isOpen, closeHash } = useHashBoolean({
    value: PAGE_HASH.ADD_STUDIO,
  });
  const isStudioManager = useAtomValue(isActiveUserStudioManagerAtom);

  const [locationData, setLocationData] = useState<PlaceResult>();
  const activeStudio = useAtomValue(activeStudioProfileAtom);
  const { mutateAsync: createProfileOnboardingSurvey, isPending } =
    useCreateProfileOnboardingSurveyDataMutation();
  const { mutateAsync: updateStudio, isPending: isUpdatingStudio } =
    useUpdateStudioMutation();

  const { isMobile } = useMediaQueryBreakpoint();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const form = e.target as UsernameSelectionForm;
      const username = form.elements.username.value.toLowerCase();
      // If the username hasn't changed and we already went through this step in the past, go to the next step
      const username_unchanged =
        activeStudio &&
        username === activeStudio.username &&
        activeStudio.has_selected_username;

      const location_unchanged =
        activeStudio &&
        locationData?.formatted_address === activeStudio.location?.formatted;
      if (username_unchanged && location_unchanged) {
        closeHash();
        return;
      }
      // Since we are still here, update the username on the backend
      await updateStudio({
        username,
        location: locationData,
        studio_id: activeStudio?.id,
      }).then((studio) => {
        return createProfileOnboardingSurvey({
          username,
          profile_id: studio.studio_profile!.id!,
        }).then(() => {
          closeHash();
          navigate(getStudioScreenRoute(username));
        });
      });
    },
    [
      createProfileOnboardingSurvey,
      closeHash,
      activeStudio,
      updateStudio,
      locationData,
      navigate,
    ],
  );

  const { isPending: isSubmitting, mutateAsync } = useMutation({
    mutationKey: ["studio-creation-flow"],
    mutationFn: async (e: FormEvent<HTMLFormElement>) => {
      return handleSubmit(e);
    },
  });

  return (
    <ResponsiveDialog
      open={isStudioManager && isOpen}
      onClose={() => {
        closeHash();
      }}
      hideCloseAction={false}
      hideCloseButton={false}
      closeButtonProps={{
        disabled: false,
      }}
      showFooterWithoutClose={true}
      component={Form}
      fullWidth={true}
      maxWidth={"md"}
      // @ts-expect-error the component above overrides the onsubmit type
      onSubmit={mutateAsync}
      titleProps={{
        children: isMobile ? <></> : undefined,
      }}
      contentProps={{
        children: (
          <StudioProfileCreation
            creationOnlyMode
            setLocationData={setLocationData}
            activeStudio={activeStudio}
            isLoading={isSubmitting || isPending || isUpdatingStudio}
            titleContent={
              isMobile ? undefined : (
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    closeHash();
                  }}
                  aria-label="close"
                  // This is how it is preferred at EngineEars.
                  disableTouchRipple={true}
                  disableFocusRipple={true}
                  disableRipple={true}
                  sx={{
                    height: "fit-content",
                    marginLeft: "auto",
                    marginRight: "0",
                  }}
                  className={TrackableClassNames.ENGINEEARS_DIALOG_CLOSE_BUTTON}
                >
                  <FontAwesomeIcon icon={faClose} />
                </IconButton>
              )
            }
          />
        ),
        dividers: false,
        sx: {
          padding: 0,
        },
      }}
      actionsProps={{
        sx: {
          alignItems: "center",
          justifyContent: "center",
        },
        children: undefined,
      }}
    />
  );
};
