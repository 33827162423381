import { Text, TEXT_WEIGHT } from "../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../core-ui/components/Text/TextUtils";
import { PhoneInput } from "../../../../core-ui/components/PhoneNumber/PhoneInput";
import { Button } from "../../../../core-ui/components/Button/Button";
import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useGeolocationGoogleAppEngine } from "../../../../../hooks/useGeolocationCountryCode";
import { Box } from "@mui/material";
import { AccountDetailsFieldContainer } from "../../../AccountSettingsModal/AccountSettingsModal.styles";
import useGetGuestVerificationCodeMutation from "../../../../../hooks/authHooks/useGetGuestVerificationCodeMutation";

interface updateProfileErrorProps {
  code: number;
  firstName: string;
  lastName: string;
  displayName: string;
  username: string;
  email: string;
  phoneNumber: string;
}

interface SignInPhoneNumberInputFieldProps {
  onSave?: (phoneNumber: string) => void;
  originalNumber?: string;
}

export const SignInPhoneNumberInputField = ({
  onSave = () => {},
  originalNumber = "",
}: SignInPhoneNumberInputFieldProps) => {
  const { mutateAsync: getVerificationCodeAsync, isPending: isSendingCode } =
    useGetGuestVerificationCodeMutation();
  const [phoneNumber, setPhoneNumber] = useState(originalNumber);
  const [updateProfileError, setUpdateProfileError] =
    useState<updateProfileErrorProps>();
  const { countryCode } = useGeolocationGoogleAppEngine();
  const isValidPhoneNumber = useMemo(() => {
    return matchIsValidTel(phoneNumber);
  }, [phoneNumber]);

  const handleSavePhoneNumber = useCallback(() => {
    void getVerificationCodeAsync({
      phone_number: phoneNumber,
    })
      .then(() => {
        toast.success("Verification code sent");
        // Delay to prevent showing old data
        setTimeout(() => {
          onSave(phoneNumber);
        }, 250);
      })
      .catch((err) => {
        setUpdateProfileError(err.errors);
      });
  }, [getVerificationCodeAsync, phoneNumber, setUpdateProfileError, onSave]);

  return (
    <AccountDetailsFieldContainer>
      <Text
        variant={TextStyleVariant.P1}
        weight={TEXT_WEIGHT.SEMI_BOLD}
        style={{ marginBottom: "8px" }}
      >
        Phone number
      </Text>

      <PhoneInput
        disabled={isSendingCode}
        isValidPhoneNumber={isValidPhoneNumber}
        defaultCountry={(countryCode as MuiTelInputCountry) || "US"}
        value={phoneNumber}
        onChange={(value: string) => {
          setPhoneNumber(value);
        }}
        size={"small"}
        style={{ width: "100%" }}
        error={Boolean(updateProfileError?.phoneNumber)}
        helperText={updateProfileError?.phoneNumber}
      />
      <Box width={"100%"} display={"flex"} alignItems={"center"} gap={2}>
        <Button
          onClick={handleSavePhoneNumber}
          fullWidth
          disabled={!phoneNumber || !isValidPhoneNumber || isSendingCode}
        >
          Verify phone
        </Button>
      </Box>
    </AccountDetailsFieldContainer>
  );
};
