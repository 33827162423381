import { ValidationLabelProps } from "../../../AccountSettingsModal/tabs/ValidationLabel.types";
import { ValidationTest } from "./types";
import { validateInput } from "./validators";

const MINIMUM_PASSWORD_CHARACTERS = 8;

const HAS_MINIMUM_LENGTH = "hasMinimumLength";
export const HAS_MINIMUM_LENGTH_VALIDATION_LABEL: ValidationLabelProps = {
  label: `Must be ${MINIMUM_PASSWORD_CHARACTERS} characters or more.`,
};
const checkMinimumLength = (password: string) => {
  return password.length < MINIMUM_PASSWORD_CHARACTERS;
};

const HAS_ONE_DIGIT = "hasOneDigit";
export const HAS_ONE_DIGIT_VALIDATION_LABEL: ValidationLabelProps = {
  label: `Must include at least one number (0-9).`,
};
const checkDigitRequirement = (password: string) => {
  return !/\d/.test(password);
};

const HAS_UPPER_CASE_CHARACTER = "hasUpperCaseCharacter";
export const HAS_UPPER_CASE_CHARACTER_VALIDATION_LABEL: ValidationLabelProps = {
  label: `Must include at least one uppercase character (A-Z).`,
};
const checkUpperCaseCharacterRequirement = (password: string) => {
  return !/[A-Z]+/.test(password);
};

const HAS_LOWER_CASE_CHARACTER = "hasLowerCaseCharacter";
export const HAS_LOWER_CASE_CHARACTER_VALIDATION_LABEL: ValidationLabelProps = {
  label: `Must include at least one lowercase character (a-z).`,
};
const checkLowerCaseCharacterRequirement = (password: string) => {
  return !/[a-z]+/.test(password);
};

const HAS_SPECIAL_CHARACTER = "hasSpecialCharacter";
export const HAS_SPECIAL_CHARACTER_VALIDATION_LABEL: ValidationLabelProps = {
  label: `Must include at least one special character (!@#$%^&*).`,
};
const checkSpecialCharacterRequirement = (password: string) => {
  return !/[!@#$%^&*]/.test(password);
};

const PASSWORD_VALIDATION_TESTS: ValidationTest[] = [
  {
    key: HAS_MINIMUM_LENGTH,
    label: HAS_MINIMUM_LENGTH_VALIDATION_LABEL,
    func: checkMinimumLength,
  },
  {
    key: HAS_ONE_DIGIT,
    label: HAS_ONE_DIGIT_VALIDATION_LABEL,
    func: checkDigitRequirement,
  },
  {
    key: HAS_UPPER_CASE_CHARACTER,
    label: HAS_UPPER_CASE_CHARACTER_VALIDATION_LABEL,
    func: checkUpperCaseCharacterRequirement,
  },
  {
    key: HAS_LOWER_CASE_CHARACTER,
    label: HAS_LOWER_CASE_CHARACTER_VALIDATION_LABEL,
    func: checkLowerCaseCharacterRequirement,
  },
  {
    key: HAS_SPECIAL_CHARACTER,
    label: HAS_SPECIAL_CHARACTER_VALIDATION_LABEL,
    func: checkSpecialCharacterRequirement,
  },
];

export const validatePassword = (value: string, showAllPassing = false) => {
  return validateInput(value, PASSWORD_VALIDATION_TESTS, {}, showAllPassing);
};
