import { RadioGroup } from "@mui/material";
import {
  UMGSubLabelEnum,
  UMGSubLabelList,
} from "../../../../../../store/models/trophy";
import { LabelOptionCard } from "./LabelOptionCard";

interface LabelSelectionBodyProps {
  onClick?: (option: UMGSubLabelEnum, checked: boolean) => void;
  initialValue?: UMGSubLabelEnum;
}
const LabelSelectionBody = ({
  initialValue,
  onClick,
}: LabelSelectionBodyProps) => {
  return (
    <RadioGroup
      aria-labelledby="label-radio-buttons-group-label"
      defaultValue={initialValue}
      name="label-radio-buttons-group"
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-evenly",
        gap: 2,
      }}
    >
      {UMGSubLabelList.map((key) => (
        // <Grid2 key={key} size={{ xs: 6, md: 4 }}>
        <LabelOptionCard
          key={key}
          onClick={onClick}
          option={key}
          defaultValue={initialValue === key}
          isDisabled={false}
        />
        // </Grid2>
      ))}
    </RadioGroup>
  );
};

export default LabelSelectionBody;
