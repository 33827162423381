import { Box, styled } from "@mui/material";
import { Button } from "../../core-ui/components/Button/Button";

export const AuthBannerTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 12px;
  & .title {
    text-align: center;
  }
`;

export const SignUpSocialButton = styled(Button)`
  gap: 8px;
  min-width: 162px;
  max-height: 44px;
`;

export const ProfileTypeCardTitle = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
