export interface AuthBannerTextProps {
  title: string;
  bulletPoints: string[];
  bottomText: string;
}

export const takeControlText = {
  title: "Take control of your music career.",
  bulletPoints: [
    "Use flexible payment solutions",
    "Collaborate with trust and transparency",
    "Access project management tools",
    "State-of-the-art file encryption",
  ],
  bottomText: "#HearTheDifference",
};

export const neverMissProjectDeadlinesText = {
  title: "Never miss project deadlines again.",
  bulletPoints: [
    "Securely share and receive project files",
    "Instant studio and audio engineer bookings",
    "Seamless budget approvals",
    "UMG direct billing payment processes",
    "Create teams by inviting admins and A&Rs",
    "Centralized project chat & workflows",
  ],
  bottomText: "#HearTheDifference",
};

export const manageStudioWithEase = {
  title: "Manage your studio with ease.",
  bulletPoints: [
    "Create a profile and highlight your studio",
    "Real-time session management",
    "Seamless budget approvals",
    "Instantly receive payments from clients",
    "Studio staff management tools",
    "Create custom discounts & promotions",
  ],
  bottomText: "#HearTheDifference",
};
