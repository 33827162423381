import { atom, Getter, Setter } from "jotai";
import { atomWithLocation } from "jotai-location";
import pathnameAtom from "./pathnameAtom";

const _searchQueryAtom = atomWithLocation({ replace: true });
_searchQueryAtom.debugPrivate = false;

// The purpose of this interface is to define the expected shape of the search parameters that can be passed to a search function.
export interface ExpectedSearchParams {
  term?: string;
  step?: string;
  flow?: string;
  status?: string;
  page?: string;
  channel?: string;
  code?: string;

  [x: string]: string | undefined;
}

// This represents a key in the url, the value in the url, and a boolean on whether or not the key should be cleared from the URL when being set
export interface SetSearchParams<T> {
  key: keyof ExpectedSearchParams;
  value: T;
  clear?: boolean;
}

const setSearchParamsHelper = (
  get: Getter,
  set: Setter,
  params: SetSearchParams<string> | SetSearchParams<string>[],
) => {
  set(_searchQueryAtom, (prev) => {
    const newParams = new URLSearchParams(location.search);

    const setParam = (param: SetSearchParams<string>) => {
      if (param.clear && !param.value) {
        newParams.delete(String(param.key));
      } else {
        newParams.set(String(param.key), param.value);
      }
    };

    if (Array.isArray(params)) {
      params.forEach(setParam);
    } else {
      setParam(params);
    }

    return { ...prev, searchParams: newParams, pathname: get(pathnameAtom) };
  });
};

// parses the URL parameters into an object that can only be read from.
const searchParamsAtom = atom((get) => {
  const currentParams = get(_searchQueryAtom).searchParams;
  const validatedParams: ExpectedSearchParams = {};
  currentParams?.forEach((value, key) => {
    validatedParams[key] = value;
  });
  return validatedParams;
});

// a read-only atom that returns the URL-safe query string
const queryStringAtom = atom((get) => {
  const params = get(searchParamsAtom) || {};
  return Object.keys(params)
    .map((key) => {
      if (typeof params[key] !== "undefined") {
        return `${key}=${encodeURIComponent(params[key] || "")}`;
      }
      return "";
    })
    .join("&");
});

queryStringAtom.debugLabel = "URL-Safe Query String";

// The values here represent hashes in the URL that may be deep-linked to trigger an effect on page load/url change
export enum PAGE_HASH {
  INVITE_TEAM = "#inviteTeam",
  ADD_ROOM = "#addRoom",
  ADD_STUDIO = "#addStudio",
  EDIT_STUDIO = "#editStudio",
  SIGN_IN = "#signIn",
  SIGN_UP = "#signUp",
  EDIT_ROOM = "#editRoom",
  CAMPAIGN = "#campaigns",
  CREATE_CAMPAIGN = "#create-campaign",
}

export { queryStringAtom, searchParamsAtom, setSearchParamsHelper };
