import { ValidationLabelProps } from "../../../AccountSettingsModal/tabs/ValidationLabel.types";
import { ValidationTest } from "./types";
import { validateInput } from "./validators";

export const checkHasSpace = (value: string) => {
  return /\s/.test(value);
};

const HAS_SPACE_VALIDATION_KEY = "hasSpace";
export const HAS_SPACE_VALIDATION_LABEL: ValidationLabelProps = {
  label: "No spaces allowed",
};

export const checkHasSpecialCharacter = (value: string) => {
  return /[^a-zA-Z0-9_-]/.test(value);
};

const HAS_SPECIAL_CHARACTER_VALIDATION_KEY = "hasSpecialCharacter";
export const HAS_SPECIAL_CHARACTER_VALIDATION_LABEL: ValidationLabelProps = {
  label: "No special characters (dashes and underscores are allowed)",
};

const USERNAME_VALIDATION_TESTS: ValidationTest[] = [
  {
    key: HAS_SPACE_VALIDATION_KEY,
    label: HAS_SPACE_VALIDATION_LABEL,
    func: checkHasSpace,
  },
  {
    key: HAS_SPECIAL_CHARACTER_VALIDATION_KEY,
    label: HAS_SPECIAL_CHARACTER_VALIDATION_LABEL,
    func: checkHasSpecialCharacter,
  },
];

export const validateUsername = (value: string, showAllPassing = false) => {
  const initialResults: Record<string, ValidationLabelProps> = {
    [HAS_SPACE_VALIDATION_KEY]: HAS_SPACE_VALIDATION_LABEL,
  };
  return validateInput(
    value,
    USERNAME_VALIDATION_TESTS,
    initialResults,
    showAllPassing,
  );
};
