import { DISCIPLINE_TYPE } from "../../../../../../hooks/user";
import {
  updateUserAAndR,
  updateUserAdmin,
  updateUserArtist,
  updateUserEngineer,
  updateUserListener,
  updateUserOther,
  updateUserProducer,
  updateUserStudioManager,
} from "../../../../../../store/actions/accountInfo";

export const ProfileTypeDescriptions: Record<DISCIPLINE_TYPE, string> = {
  [DISCIPLINE_TYPE.NO_TYPE]: "",
  [DISCIPLINE_TYPE.ARTIST]:
    "I want to record, mix, master, and/or release my songs.",
  [DISCIPLINE_TYPE.STUDIO_MANAGER]: "I want to offer hourly studio bookings.",
  [DISCIPLINE_TYPE.ENGINEER]:
    "I want to get paid to record, mix, and/or master songs.",
  [DISCIPLINE_TYPE.PRODUCER]:
    "I want to sell my production and get paid for collaborations.",
  [DISCIPLINE_TYPE.LISTENER]: "I want to discover and support new music.",
  [DISCIPLINE_TYPE.OTHER]: "I signed up for a different reason.",
  [DISCIPLINE_TYPE.ADMIN]:
    "I want to manage budgets and approvals for team projects.",
  [DISCIPLINE_TYPE.A_AND_R]:
    "I want to manage the completion of my artists’ songs.",
};

export const ProfileTypes = [
  DISCIPLINE_TYPE.ARTIST,
  DISCIPLINE_TYPE.STUDIO_MANAGER,
  DISCIPLINE_TYPE.ENGINEER,
  DISCIPLINE_TYPE.PRODUCER,
  DISCIPLINE_TYPE.LISTENER,
  DISCIPLINE_TYPE.OTHER,
];

export const LabelProfileTypes = [
  DISCIPLINE_TYPE.ADMIN,
  DISCIPLINE_TYPE.A_AND_R,
];

export const ProfileUpdateThunks = {
  [DISCIPLINE_TYPE.ENGINEER]: updateUserEngineer,
  [DISCIPLINE_TYPE.ARTIST]: updateUserArtist,
  [DISCIPLINE_TYPE.PRODUCER]: updateUserProducer,
  [DISCIPLINE_TYPE.A_AND_R]: updateUserAAndR,
  [DISCIPLINE_TYPE.STUDIO_MANAGER]: updateUserStudioManager,
  [DISCIPLINE_TYPE.OTHER]: updateUserOther,
  [DISCIPLINE_TYPE.LISTENER]: updateUserListener,
  [DISCIPLINE_TYPE.ADMIN]: updateUserAdmin,
};
