import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import useSendEmailVerificationCodeMutation from "../../../../../hooks/authHooks/useSendEmailVerificationCodeMutation";
import useGuestLoginMutation from "../../../../../hooks/authHooks/useGuestLoginMutation";
import { FieldContainer } from "../../../SignInForm/SignInForm.styles";
import { Text, TEXT_WEIGHT } from "../../../../core-ui/components/Text/Text";
import VerificationCodeInput from "../../../Auth/VerificationCodeInput";
import {
  TextColor,
  TextStyleVariant,
} from "../../../../core-ui/components/Text/TextUtils";
import { Button } from "../../../../core-ui/components/Button/Button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface SignInVerificationCodeFieldProps {
  email: string;
}
export const SignInVerificationCodeField = ({
  email,
}: SignInVerificationCodeFieldProps) => {
  const [verificationError, setVerificationError] = useState(false);
  const navigate = useNavigate();
  const { mutateAsync: guestLoginAsync, isPending: checkingCode } =
    useGuestLoginMutation();
  const {
    mutateAsync: getVerificationCode,
    isPending: isGettingVerificationCode,
  } = useSendEmailVerificationCodeMutation();
  return (
    <FieldContainer>
      <Text weight={TEXT_WEIGHT.SEMI_BOLD}>
        Enter the verification code we sent you
      </Text>
      <VerificationCodeInput
        codeLength={6}
        onComplete={async (code) => {
          void guestLoginAsync({
            email,
            verification_code: code,
          })
            .then(() => {
              navigate("#");
              toast.success(
                "Successfully verified code! You are now logged in!",
                {
                  autoClose: 1500,
                },
              );
            })
            .catch(() => {
              setVerificationError(true);
            });
        }}
      />
      {verificationError && (
        <Text variant={TextStyleVariant.P1} color={TextColor.ERROR}>
          <FontAwesomeIcon style={{ marginRight: "4px" }} icon={faXmark} />
          Invalid verification code
        </Text>
      )}
      <Button
        style={{ marginTop: 8 }}
        fullWidth
        onClick={() => {
          void getVerificationCode({
            email_or_username: email,
            send_verification_code: true,
          }).then(() => {
            toast.success("Verification Code Resent");
          });
        }}
        disabled={checkingCode || isGettingVerificationCode}
        loading={checkingCode || isGettingVerificationCode}
      >
        Resend code
      </Button>
    </FieldContainer>
  );
};
