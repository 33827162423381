import {
  Button,
  ButtonVariant,
} from "../../../core-ui/components/Button/Button";
import { SignUpOrInWithGoogle } from "../components/SignUpOrInWithGoogle";
import { SignUpOrInMethodsEnum } from "../types";
import Link from "../../../elements/Link/Link";
import { SCREENS } from "../../../../routes/screens";
import { compactSignInOpenAtom } from "../atoms";
import { useSetAtom } from "jotai";
import { SignUpOrInOptionButton } from "./SignUpOrInOptionButton";
import { useGoogleAuthMutation } from "../../../../hooks/authHooks/useGoogleAuthMutation";
import { useAppSelector } from "../../../../store/hooks";
import { CredentialResponse } from "@react-oauth/google";
import { useCallback } from "react";
import { emitAnalyticsTrackingEvent } from "../../../../utils/analyticsUtils";
import { TRACKING_EVENTS_NAME } from "../../../../constants/trackingEvents";

interface SignUpOrInOptionsProps {
  method?: SignUpOrInMethodsEnum;
  compact?: boolean;
  signUpMode?: boolean;
}

export const SignUpOrInOptions = ({
  compact,
  method,
  signUpMode,
}: SignUpOrInOptionsProps) => {
  const setIsRequired = useSetAtom(compactSignInOpenAtom);
  const {
    isPending,
    isSuccess,
    mutateAsync: authViaGoogle,
  } = useGoogleAuthMutation();
  const localUTMParams = useAppSelector(
    (state) => state.accountInfo.localUTMParams,
  );

  const handleSuccessfulGoogleSignIn = useCallback(
    (credentialResponse: CredentialResponse) => {
      if (credentialResponse.credential && credentialResponse.clientId) {
        void authViaGoogle({
          credentials: credentialResponse.credential,
          clientId: credentialResponse.clientId,
        }).then((result) => {
          emitAnalyticsTrackingEvent("login_google", {});
          if (result.created) {
            emitAnalyticsTrackingEvent(
              TRACKING_EVENTS_NAME.REGISTER_VIA_GOOGLE,
              {
                email: result.user.email,
                phoneNumber: result.user.phone_number?.phone_number,
                userId: result.user.id,
                username: result.user.username,
                ...localUTMParams,
              },
            );
          }
        });
      }
    },
    [authViaGoogle, localUTMParams],
  );
  return (
    <>
      <SignUpOrInWithGoogle onSuccess={handleSuccessfulGoogleSignIn} />
      {method !== SignUpOrInMethodsEnum.PHONE && !signUpMode && (
        <SignUpOrInOptionButton
          method={SignUpOrInMethodsEnum.PHONE}
          variant={ButtonVariant.OUTLINED}
          disabled={isPending || isSuccess}
        >
          Continue with phone number
        </SignUpOrInOptionButton>
      )}
      {method !== SignUpOrInMethodsEnum.INTERNAL && (
        <SignUpOrInOptionButton
          disabled={isPending || isSuccess}
          method={SignUpOrInMethodsEnum.INTERNAL}
        >
          Continue with email{signUpMode ? "" : " or username"}
        </SignUpOrInOptionButton>
      )}
      {compact && (
        <Button
          LinkComponent={Link}
          {...{ to: SCREENS.HOME_SCREEN }}
          onClick={() => {
            setIsRequired(undefined);
          }}
          variant={ButtonVariant.GHOST}
          disabled={isPending || isSuccess}
          fullWidth
        >
          Go Home
        </Button>
      )}
    </>
  );
};
