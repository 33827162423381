import { ReactNode } from "react";
import { ResponsiveAuthBanner } from "./ResponsiveAuthBanner";
import {
  AuthStepBannerContentWrapper,
  MainContentContainer,
  SignUpFooter,
} from "./steps/AuthStepBanner.styles";
import FooterContent from "../../Auth/FooterContent";
import { ResponsiveAuthBannerProps } from "../types";

interface ResponsiveAuthContentProps extends ResponsiveAuthBannerProps {
  titleContent?: ReactNode;
  compact?: boolean;
}

export const ResponsiveAuthContent = ({
  children,
  titleContent,
  ...props
}: ResponsiveAuthContentProps) => {
  return (
    <ResponsiveAuthBanner {...props}>
      <AuthStepBannerContentWrapper>
        <MainContentContainer>
          {titleContent}
          {children}
          <SignUpFooter mx="auto">
            <FooterContent />
          </SignUpFooter>
        </MainContentContainer>
      </AuthStepBannerContentWrapper>
    </ResponsiveAuthBanner>
  );
};
