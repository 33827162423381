import { useAtomValue } from "jotai";
import useCreateArtistSurveyDataMutation from "../../../../../../../hooks/marketingHooks/useCreateArtistSurveyDataMutation";
import { FormEventHandler, useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Text } from "../../../../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../../../../core-ui/components/Text/TextUtils";
import { Box, Grid2 } from "@mui/material";
import FormOutlinedTextField from "../../../../../../elements/Form/FormOutlinedTextField";
import FormSelect from "../../../../../../elements/Form/FormSelect";
import {
  Genre,
  genreToStringMap,
  valueToGenreRecord,
} from "../../../../../../../store/models/genres";
import { ProjectTypes, TargetProjectEndDates } from "./constants";
import { useUpdateUserProfile } from "../../../../../../../hooks/userHooks/useUpdateUserProfile";
import { activeUserIdAtom } from "../../../../../../../atoms/user/activeUserAtom";
import usePostGenresMutation from "../../../../../../../hooks/profileScreenHooks/usePostGenres";
import { useSetAtom } from "jotai/index";
import { stepDataAtom } from "../../../../atoms";
import useActiveUserQuery from "../../../../../../../hooks/userHooks/useActiveUserQuery";

interface FormElements extends HTMLFormControlsCollection {
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
  genres: HTMLInputElement;
  projects: HTMLInputElement;
  edd: HTMLInputElement;
  other: HTMLInputElement;
}

interface ArtistOnboardingSurveyFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

const copy = new Map(genreToStringMap);
copy.delete(Genre.NO_GENRE);

const filteredGenres = Object.values(Object.fromEntries(copy));

export const ArtistOnboardingSurveyForm = () => {
  const setStepData = useSetAtom(stepDataAtom);
  const { mutateAsync: createArtistSurveyData, isPending } =
    useCreateArtistSurveyDataMutation();
  const activeUserId = useAtomValue(activeUserIdAtom);
  const { refetch } = useActiveUserQuery();
  const { mutateAsync: updateUserProfile } = useUpdateUserProfile();
  const { mutateAsync: updateGenres } = usePostGenresMutation({
    userId: activeUserId,
  });

  const handleSubmit: FormEventHandler<ArtistOnboardingSurveyFormElement> =
    useCallback(
      async (e) => {
        e.preventDefault();
        const { firstName, lastName, genres, projects, edd, other } = (
          e.target as ArtistOnboardingSurveyFormElement
        ).elements;
        const genresList = genres.value.split(",");
        const projectsList = projects.value.split(",");
        void updateUserProfile({
          first_name: firstName.value.trim(),
          last_name: lastName.value.trim(),
        });
        const selectedGenres = genresList
          .map((genre) => {
            return valueToGenreRecord[genre];
          })
          .filter((item) => item !== undefined);
        void updateGenres({ genres: selectedGenres });
        await createArtistSurveyData({
          surveyName: "Artist Onboarding Survey",
          answers: {
            firstName: firstName.value.trim(),
            lastName: lastName.value.trim(),
            genres: genresList,
            projects: projectsList,
            edd: edd.value,
            other: other.value.trim(),
          },
        })
          .then(() => {
            toast.success(
              `Successfully submitted artist onboarding survey form`,
            );
          })
          .catch(() => {
            toast.error(`Error submitting artist onboarding survey form`);
          });
      },
      [createArtistSurveyData, updateGenres, updateUserProfile],
    );

  const titleContent = useMemo(() => {
    return (
      <Box display={"flex"} flexDirection={"column"}>
        <Text variant={TextStyleVariant.H5}>
          Welcome to EngineEars for Artists
        </Text>
        <Text variant={TextStyleVariant.P1}>
          Help us get to know you so we can tailor the EngineEars experience to
          you.
        </Text>
      </Box>
    );
  }, []);

  const handleClose = useCallback(async () => {
    return await createArtistSurveyData({
      surveyName: "Artist Onboarding Survey",
      answers: {
        other: "declined survey",
      },
    }).then(() => {
      return refetch();
    });
  }, [createArtistSurveyData, refetch]);

  useEffect(() => {
    setStepData({
      disableClose: isPending,
      onClose: handleClose,
      maxWidth: "lg",
      title: titleContent,
      onSubmit: handleSubmit,
    });
    return () => {
      setStepData({});
    };
  }, [setStepData, titleContent, handleSubmit, handleClose, isPending]);

  return (
    <Box display={"flex"} flexDirection={"column"} p={2}>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <FormOutlinedTextField
            id="outlined-adornment-first-name"
            label="First Name"
            name={"firstName"}
            fullWidth
            required
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <FormOutlinedTextField
            id="outlined-adornment-last-name"
            label="Last Name"
            name={"lastName"}
            fullWidth
            required
          />
        </Grid2>
        <Grid2 size={12}>
          <FormSelect
            id="outlined-adornment-genres"
            label="What is your genre of music?"
            name={"genres"}
            fullWidth
            required
            multiple
            items={filteredGenres}
          />
        </Grid2>
        <Grid2 size={12}>
          <FormSelect
            id="outlined-adornment-projects"
            label="What project(s) are you working on?"
            name={"projects"}
            fullWidth
            required
            multiple
            items={ProjectTypes}
          />
        </Grid2>
        <Grid2 size={12}>
          <FormSelect
            id="outlined-adornment-edd"
            label="When are you targeting to finish?"
            name={"edd"}
            fullWidth
            required
            items={TargetProjectEndDates}
          />
        </Grid2>
        <Grid2 size={12}>
          <FormOutlinedTextField
            id="outlined-adornment-other"
            label="Anything Else?"
            name={"other"}
            fullWidth
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};
