import { NavTabButtonEnum } from "./BottomNav/NavItem";
import { SCREENS } from "../../../routes/screens";

export const getNavButtonColor = (
  linkPaths: string[],
  pathname: string,
  defaultColor = "var(--text-primary-color)",
  navTabEnum?: NavTabButtonEnum,
) => {
  if (pathname === SCREENS.AUTH_SCREEN) {
    if (navTabEnum === NavTabButtonEnum.SIGN_IN) {
      return "var(--nav-button-color--selected)";
    }
    if (navTabEnum === NavTabButtonEnum.SIGN_UP) {
      return "var(--nav-button-color--selected)";
    }
    return defaultColor;
  }

  return linkPaths.find((linkPath) => pathname.startsWith(linkPath))
    ? "var(--nav-button-color--selected)"
    : defaultColor;
};
