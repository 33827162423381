import { ResponsiveDialog } from "../../../core-ui/components/ResponsiveDialog/ResponsiveDialog";
import { authStepAtom } from "../../../../atoms/location/authFlowAtoms";
import { useAtom, useAtomValue } from "jotai";
import { stepDataAtom } from "../atoms";
import Form from "../../../elements/Form/Form";
import ProfileSelectionStep from "./steps/ProfileSelectionStep/ProfileSelectionStep";
import { Button } from "../../../core-ui/components/Button/Button";
import { FormEvent, FormEventHandler, useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import { UsernameSelectionStep } from "./steps/UsernameSelectionStep/UsernameSelectionStep";
import { EmailVerificationStep } from "./steps/EmailVerificationStep";
import { PhoneCollectionStep } from "./steps/PhoneCollectionStep";
import { PhoneVerificationStep } from "./steps/PhoneVerificationStep/PhoneVerificationStep";
import { OnboardingSurveyStep } from "./steps/OnboardingSurveyStep/OnboardingSurveyStep";
import { LabelSelectionStep } from "./steps/LabelSelectionStep/LabelSelectionStep";
import { AddPasswordStep } from "./steps/AddPasswordStep";

const AuthFlowModal = () => {
  const [step, setStep] = useAtom(authStepAtom);
  const {
    onSubmit,
    hideFooter = false,
    disableClose = false,
    onClose,
    disablePadding = false,
    showDividers = true,
    maxWidth,
    title,
  } = useAtomValue(stepDataAtom);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      if (onSubmit) {
        onSubmit(e);
      }
    },
    [onSubmit],
  );
  const { isPending, mutateAsync } = useMutation({
    mutationKey: ["auth-flow"],
    mutationFn: async (e: FormEvent<HTMLFormElement>) => {
      handleSubmit(e);
    },
  });

  return (
    <ResponsiveDialog
      open={Boolean(step)}
      onClose={() => {
        if (onClose) {
          onClose();
          return;
        }
        setStep(undefined);
      }}
      hideCloseAction={disableClose}
      hideCloseButton={disableClose}
      closeButtonProps={{
        disabled: disableClose,
      }}
      showFooterWithoutClose={true}
      component={Form}
      fullWidth={true}
      maxWidth={maxWidth}
      // @ts-expect-error the component above overrides the onsubmit type
      onSubmit={mutateAsync}
      titleProps={{ children: title }}
      contentProps={{
        children: (
          <>
            <AddPasswordStep />
            <ProfileSelectionStep />
            <LabelSelectionStep />
            <UsernameSelectionStep />
            <EmailVerificationStep />
            <PhoneCollectionStep />
            <PhoneVerificationStep />
            <OnboardingSurveyStep />
          </>
        ),
        dividers: !showDividers ? false : Boolean(title) || !hideFooter,
        sx: {
          padding: disablePadding ? 0 : undefined,
        },
      }}
      actionsProps={{
        sx: {
          alignItems: "center",
          justifyContent: "center",
        },
        children: hideFooter ? undefined : (
          <Button
            disabled={isPending || typeof onSubmit === "undefined"}
            type={"submit"}
            title={isPending ? "submitting" : "next"}
          >
            {isPending ? "Submitting..." : "Next"}
          </Button>
        ),
      }}
    />
  );
};

export default AuthFlowModal;
