import { AuthStepComponentProps } from "../../types";
import { useAtomValue } from "jotai";
import { authStepAtom } from "../../../../../atoms/location/authFlowAtoms";
import { ReactNode } from "react";

interface AuthStepWrapperProps extends AuthStepComponentProps {
  children: ReactNode;
}

const AuthStepWrapper = ({ step, children }: AuthStepWrapperProps) => {
  const authStep = useAtomValue(authStepAtom);
  if (authStep !== step) return null;
  return children;
};

export default AuthStepWrapper;
