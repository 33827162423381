import { ResponsiveDialog } from "../../../core-ui/components/ResponsiveDialog/ResponsiveDialog";
import Form from "../../../elements/Form/Form";
import { FormEventHandler, useCallback, useEffect, useMemo } from "react";
import {
  extractSignUpOrInDataFromForm,
  getMethodFromSearchParams,
} from "../helpers";
import useLoginMutation from "../../../../hooks/authHooks/useLoginMutation";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { activeUserAtom } from "../../../../atoms/user/activeUserAtom";
import { compactSignInDataAtom, compactSignInOpenAtom } from "../atoms";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../../atoms/location/authFlowAtoms";
import useSignUpMutation from "../../../../hooks/authHooks/useSignUpMutation";
import useHashBoolean from "../../../../hooks/reactRouterHooks/useHashBoolean";
import { PAGE_HASH } from "../../../../atoms/location/locationAtom";
import { SignUpOrInAllOptions } from "./SignUpOrInAllOptions";
import { SignUpOrInMethodsEnum } from "../types";
import SignInInternal from "./SignIn/SignInInternal";
import SignInPhone from "./SignIn/SignInPhone";
import { SignInMethodParameter } from "../constants";
import { useSearchParams } from "react-router-dom";
import SignUpInternal from "./SignUp/SignUpInternal";

interface SignUpOrInFormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
  password: HTMLInputElement;
}

interface SignUpOrInFormElement extends HTMLFormElement {
  readonly elements: SignUpOrInFormElements;
}

// The goal of this component is to provide a modal for pages where sign in is required to continue
// This component will be globally mounted, and controlled via Atoms
const CompactSignInModalInner = () => {
  const setIsRequired = useSetAtom(compactSignInOpenAtom);
  const {
    label,
    required,
    onClose = () => {},
  } = useAtomValue(compactSignInDataAtom);
  const [searchParams] = useSearchParams({
    [SignInMethodParameter]: "",
  });
  const { isOpen: signUpEnabled } = useHashBoolean({
    value: PAGE_HASH.SIGN_UP,
  });
  const [step, setStep] = useAtom(authStepAtom);
  const { mutateAsync: loginAsync, isPending: loginPending } =
    useLoginMutation();
  const { mutateAsync: signUpAsync, isPending: signUpPending } =
    useSignUpMutation();
  const handleSuccessfulSignIn = useCallback(() => {
    onClose();
    setIsRequired(undefined);
  }, [setIsRequired, onClose]);
  const handleClose = useCallback(() => {
    onClose();
    setIsRequired(undefined);
    setStep(AuthFlowSteps.NONE);
  }, [setIsRequired, setStep, onClose]);

  const handleSignIn: FormEventHandler<SignUpOrInFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const { username, isUmgEmail, password } =
        extractSignUpOrInDataFromForm(e);
      if (isUmgEmail) {
        // Trigger UMG SSO Auth
        return;
      }
      // Trigger normal sign in with username/password
      void loginAsync({
        username: username,
        password: password,
      }).then(() => {
        handleSuccessfulSignIn();
      });
    },
    [loginAsync, handleSuccessfulSignIn],
  );

  const handleSignUp: FormEventHandler<SignUpOrInFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const { username, isEmail, isUmgEmail, password } =
        extractSignUpOrInDataFromForm(e);
      if (isUmgEmail) {
        // Trigger UMG SSO Auth
      }
      // Trigger normal sign in with username/password
      void signUpAsync({
        username: isEmail ? "" : username,
        email: isEmail ? username : "",
        password: password,
      }).then(() => {
        handleSuccessfulSignIn();
      });
    },
    [signUpAsync, handleSuccessfulSignIn],
  );

  const method = useMemo(
    () => getMethodFromSearchParams(searchParams),
    [searchParams],
  );

  const handleSubmit: FormEventHandler<SignUpOrInFormElement> = useMemo(
    () => (signUpEnabled ? handleSignUp : handleSignIn),
    [handleSignIn, signUpEnabled, handleSignUp],
  );
  const signUpMethod = useMemo(() => {
    if (method === SignUpOrInMethodsEnum.INTERNAL) {
      return <SignUpInternal loading={signUpPending} />;
    }
    return null;
  }, [method, signUpPending]);
  const signInMethod = useMemo(() => {
    if (method === SignUpOrInMethodsEnum.INTERNAL) {
      return <SignInInternal loading={loginPending} />;
    }

    if (method === SignUpOrInMethodsEnum.PHONE) {
      return <SignInPhone />;
    }
    return null;
  }, [method, loginPending]);
  const contentChildren = useMemo(() => {
    return (
      <SignUpOrInAllOptions label={label} compact signUpMode={signUpEnabled}>
        {signUpEnabled ? signUpMethod : signInMethod}
      </SignUpOrInAllOptions>
    );
  }, [signUpEnabled, label, signUpMethod, signInMethod]);
  // This allows the user to skip the onboarding flow when signing in with this dialog
  useEffect(() => {
    if (typeof required === "undefined") return;
    if (step !== AuthFlowSteps.ONBOARDING_COMPLETE) {
      setStep(AuthFlowSteps.ONBOARDING_COMPLETE);
    }
  }, [step, setStep, required]);

  return (
    <ResponsiveDialog
      open={typeof required !== "undefined"}
      onClose={required ? undefined : handleClose}
      component={Form}
      fullScreen={false}
      fullWidth={true}
      maxWidth={"sm"}
      // @ts-expect-error the component has a different type
      onSubmit={handleSubmit}
      contentProps={{
        children: contentChildren,
        dividers: false,
        sx: {
          padding: 0,
        },
      }}
    />
  );
};

export const CompactSignUpOrInModal = () => {
  // Should already not exist thanks to where its implemented
  const activeUser = useAtomValue(activeUserAtom);
  const isOpen = useAtomValue(compactSignInOpenAtom);
  // Do not render the modal or trigger any of the associated hooks if a user is signed in
  if (activeUser || !isOpen) return null;
  return <CompactSignInModalInner />;
};
