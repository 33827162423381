import { atom } from "jotai";
import { SlimPrivateActiveUser } from "../../store/models/user";
import store from "../../store";
import { setUser } from "../../store/actions/accountInfo";
import { resetChecklistAtom } from "../../stories/components/Onboarding/atoms";
import { consentAtom } from "../consentAtom";
import { activeUserStudiosAtom } from "./activeUserStudiosAtom";
import { DISCIPLINE_TYPE } from "../../hooks/user";
import { checkUmgEmailPattern } from "../../stories/components/AuthFlow/components/validators/emailValidators";

type ActiveUserState = SlimPrivateActiveUser | undefined;
// We use a private atom to prevent calls that change the data that don't have the desired side effects.
const _activeUserAtom = atom(
  // We cast the type here instead of using <> to let typescript's magic figure out the type naturally
  undefined as ActiveUserState,
);

// This buffer atom exists so we can create side effects
// We know these will run when a developer interacts with the active user
export const activeUserAtom = atom(
  (get) => {
    // We just do a simple return of the inner atom
    return get(_activeUserAtom);
  },
  (
    _,
    set,
    user: ActiveUserState | ((oldUser: ActiveUserState) => ActiveUserState),
  ) => {
    // Since the value may be a function, we need to capture the value
    let result: ActiveUserState = undefined;
    // This is a helper function to capture the result from the set state action
    set(_activeUserAtom, (oldState) => {
      if (typeof user === "function") {
        result = user(oldState);
      } else {
        result = user;
      }
      // Unset the list of studios if the user changes
      const hasUserChanged = !result || (oldState && oldState.id !== result.id);
      if (hasUserChanged) {
        set(activeUserStudiosAtom, []);
      }
      if (!result) {
        store.dispatch(setUser(undefined));
        set(resetChecklistAtom);
      } else {
        // If a user signs in, they automatically consent to tracking
        set(consentAtom, true);
        // Locally transform the result to extract some fields for later
        if (result.date_joined) {
          result.memberSinceYear = new Date(result.date_joined).getFullYear();
        }
        let primaryDiscipline: DISCIPLINE_TYPE | undefined = undefined;
        if (result.disciplines) {
          if (result.disciplines.aandr?.is_primary_type) {
            primaryDiscipline = DISCIPLINE_TYPE.A_AND_R;
          } else if (result.disciplines.engineer?.is_primary_type) {
            primaryDiscipline = DISCIPLINE_TYPE.ENGINEER;
          } else if (result.disciplines.artist?.is_primary_type) {
            primaryDiscipline = DISCIPLINE_TYPE.ARTIST;
          } else if (result.disciplines.producer?.is_primary_type) {
            primaryDiscipline = DISCIPLINE_TYPE.PRODUCER;
          } else if (result.disciplines.studio_manager?.is_primary_type) {
            primaryDiscipline = DISCIPLINE_TYPE.STUDIO_MANAGER;
          } else if (result.disciplines.other?.is_primary_type) {
            primaryDiscipline = DISCIPLINE_TYPE.OTHER;
          } else if (result.disciplines.listener?.is_primary_type) {
            primaryDiscipline = DISCIPLINE_TYPE.LISTENER;
          } else if (result.disciplines.admin?.is_primary_type) {
            primaryDiscipline = DISCIPLINE_TYPE.ADMIN;
          }
        }
        result.primaryDiscipline = primaryDiscipline;
      }
      return result;
    });
  },
);

export const activeUserIdAtom = atom((get) => {
  return get(activeUserAtom)?.id;
});

export const activeUserChatTokenAtom = atom((get) => {
  const user = get(activeUserAtom);
  return user ? user.stream_token : undefined;
});

export const activeUserChatDataAtom = atom((get) => {
  const user = get(activeUserAtom);
  return user
    ? {
        id: String(user.id),
        name: user.display_name,
        username: user.username,
        image: user.profile_picture,
      }
    : undefined;
});

export const activeUserDisciplinesAtom = atom((get) => {
  const user = get(activeUserAtom);
  return user?.disciplines || {};
});

export const activeUserEngineerIdAtom = atom((get) => {
  return get(activeUserDisciplinesAtom).engineer;
});

export const isActiveUserEngineerAtom = atom((get) => {
  return Boolean(get(activeUserEngineerIdAtom));
});

export const activeUserStudioManagerIdAtom = atom((get) => {
  return get(activeUserDisciplinesAtom).studio_manager;
});

export const isActiveUserStudioManagerAtom = atom((get) => {
  return Boolean(get(activeUserStudioManagerIdAtom));
});

export const isActiveUserEngineerOrStudioManagerAtom = atom((get) => {
  return get(isActiveUserEngineerAtom) || get(isActiveUserStudioManagerAtom);
});

export const activeUserAandRIdAtom = atom((get) => {
  return get(activeUserDisciplinesAtom).aandr;
});

export const isActiveUserAandRAtom = atom((get) => {
  return Boolean(get(activeUserAandRIdAtom));
});

export const activeUserArtistIdAtom = atom((get) => {
  return get(activeUserDisciplinesAtom).artist;
});

export const isActiveUserArtistAtom = atom((get) => {
  return Boolean(get(activeUserArtistIdAtom));
});

export const activeUserAdminAtom = atom((get) => {
  return get(activeUserDisciplinesAtom).admin;
});

export const isActiveUserAdminAtom = atom((get) => {
  return Boolean(get(activeUserAdminAtom));
});

export const isActiveUserLabelUserAtom = atom((get) => {
  return get(isActiveUserAandRAtom) || get(isActiveUserAdminAtom);
});

export const isUmgActiveUserAtom = atom((get) => {
  const user = get(activeUserAtom);
  if (!user?.email) return false;
  return checkUmgEmailPattern(user.email, true);
});

export const isVerifiedUmgActiveUserAtom = atom((get) => {
  const user = get(activeUserAtom);
  if (!user?.email_verified) return false;
  return get(isUmgActiveUserAtom);
});

export const isVerifiedUmgAndAandRActiveUserAtom = atom((get) => {
  const user = get(activeUserAtom);
  if (!user?.email_verified) return false;
  return get(isUmgActiveUserAtom) && get(isActiveUserAandRAtom);
});
