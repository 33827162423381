import { Box, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/pro-solid-svg-icons";
import { Text } from "../../../core-ui/components/Text/Text";
import { TextStyleVariant } from "../../../core-ui/components/Text/TextUtils";
import { ValidationLabelProps } from "./ValidationLabel.types";

// Aria-Disabled is a standard prop that indicates that the element and its descendants are not editable or interactive.
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-disabled
const ValidationLabelWrapper = styled(Box)(
  ({ theme, "aria-disabled": ariaDisabled }) => ({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "&, & .validation-label-icon, & .validation-label-text": {
      color: ariaDisabled
        ? theme.palette.customColor.textErrorColor
        : theme.palette.success.main,
    },
  }),
);

export const ValidationLabel = ({ label, invalid }: ValidationLabelProps) => {
  return (
    <ValidationLabelWrapper aria-disabled={invalid}>
      <FontAwesomeIcon
        className={"validation-label-icon"}
        icon={invalid ? faClose : faCheck}
      />
      <Text variant={TextStyleVariant.P3} className={"validation-label-text"}>
        {label}
      </Text>
    </ValidationLabelWrapper>
  );
};
