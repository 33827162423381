import { Box, styled } from "@mui/material";

const LabelOptionCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "flex-start",
  boxShadow: "none",
  cursor: "pointer",
  maxWidth: "150px",
  [theme.breakpoints.up("lg")]: {
    maxWidth: "200px",
  },
  "&:has(*:focus)": {
    "& .option-card-info": {
      border: `2px dashed ${theme.palette.primary.main}`,
    },
  },
  "&:hover": {
    cursor: "pointer",
    "& .option-card-info": {
      border: `2px dashed ${theme.palette.primary.light}`,
    },
  },
  "&:has(.PrivateSwitchBase-input:checked)": {
    "& .option-card-info": {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  "&:has(.Mui-disabled)": {
    cursor: "not-allowed",
  },
  "& > label": {
    pointerEvents: "none",
    flex: 1,
    flexDirection: "row-reverse",
    margin: 0,
    "& > *:first-of-type": {
      marginLeft: "auto",
    },
    "& *": {
      pointerEvents: "none",
    },
  },
  "& .MuiTypography-root": {
    textAlign: "center",
  },
}));

export default LabelOptionCardContainer;
