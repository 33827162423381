import { useMemo } from "react";
import { RecordingService } from "../../store/models/recording";
import Service from "../../store/models/service";
import { isActiveUserAandRAtom } from "../../atoms/user/activeUserAtom";
import { useAtomValue } from "jotai";

export const useGetServiceOriginalRate = (
  service?: RecordingService | Service,
  userOnOwnProfile = false,
) => {
  const isAandR = useAtomValue(isActiveUserAandRAtom);

  const isLabelPriceApplied = useMemo(() => {
    return Boolean(
      isAandR && service?.service_rate?.label_price && !userOnOwnProfile,
    );
  }, [isAandR, service?.service_rate?.label_price, userOnOwnProfile]);

  const originalRate = useMemo(() => {
    const price = isLabelPriceApplied
      ? service?.service_rate?.label_price
      : service?.service_rate?.price;

    return price || 0;
  }, [
    isLabelPriceApplied,
    service?.service_rate?.label_price,
    service?.service_rate?.price,
  ]);

  return { originalRate, isLabelPriceApplied };
};
