import AuthFlowModal from "./components/AuthFlowModal";
import { useAtomValue } from "jotai";
import {
  AuthFlowSteps,
  authStepAtom,
} from "../../../atoms/location/authFlowAtoms";
import { activeUserAtom } from "../../../atoms/user/activeUserAtom";
import SignUpOrInModal from "./SignUpOrInModal/SignUpOrInModal";
import { CompactSignUpOrInModal } from "./SignUpOrInModal/CompactSignUpOrInModal";

// This is a wrapper for the entire auth flow to be mounted at the UI Content level
const AuthFlowModalWrapper = () => {
  const step = useAtomValue(authStepAtom);
  if (step === AuthFlowSteps.NONE || step === AuthFlowSteps.ONBOARDING_COMPLETE)
    return null;
  return <AuthFlowModal />;
};

const AuthFlow = () => {
  const activeUser = useAtomValue(activeUserAtom);
  if (activeUser) {
    return <AuthFlowModalWrapper />;
  }
  return (
    <>
      <CompactSignUpOrInModal />
      <SignUpOrInModal />
    </>
  );
};

export default AuthFlow;
