import { atom } from "jotai";
import { atomWithReset, RESET } from "jotai/utils";
import { FormEventHandler, ReactNode } from "react";

export interface StepData {
  onSubmit?: FormEventHandler<HTMLFormElement>;
  onClose?: () => void;
  disableClose?: boolean;
  title?: ReactNode;
  hideFooter?: boolean;
  showDividers?: boolean;
  disablePadding?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const stepDataAtom = atom({} as StepData);
// This atom represents if the application currently requires sign in to continue doing an action
// The value represents the label that should be displayed on the sign in modal
// This may need to be upgraded from a string to a ReactNode at some point
interface CompactSignInData {
  label?: ReactNode;
  onClose?: () => void;
  // This is a trilean - undefined means closed. True and false both mean open.
  required?: boolean;
  // If not sign up, then sign in
  signUp?: boolean;
}

const compactSignInAtom = atomWithReset({} as CompactSignInData);
compactSignInAtom.debugLabel = "Compact Sign In Data";
// If the required value is undefined, then the sign in modal is closed.
export const compactSignInOpenAtom = atom(
  (get) => typeof get(compactSignInAtom).required !== "undefined",
  (_get, set, required?: boolean) =>
    set(
      compactSignInAtom,
      typeof required === "undefined"
        ? RESET
        : (prev) => {
            return {
              ...prev,
              required: required,
            };
          },
    ),
);
compactSignInOpenAtom.debugPrivate = true;

export const compactSignInDataAtom = atom(
  (get) => {
    const data = get(compactSignInAtom);
    return { ...data, open: typeof data.required !== "undefined" };
  },
  (_get, set, data?: Partial<CompactSignInData>) =>
    set(
      compactSignInAtom,
      typeof data === "undefined"
        ? RESET
        : (prev) => {
            return { ...prev, ...data };
          },
    ),
);

compactSignInDataAtom.debugLabel = "Compact Validated Sign In Data";
