import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants/queryKeys";
import CreateProfileOnboardingSurveyData, {
  CreateProfileOnboardingSurveyDataArgs,
} from "../../api/marketing/createProfileOnboardingSurveyData";
import useActiveUserQuery from "../userHooks/useActiveUserQuery";
import { EngineEarsError } from "../../store/actions/errorStore";
import { displayEngineEarsError } from "../../api/helpers";

const useCreateProfileOnboardingSurveyDataMutation = () => {
  const { refetch } = useActiveUserQuery();
  return useMutation({
    mutationKey: [QUERY_KEYS.CREATE_PROFILE_ONBOARDING_SURVEY],
    mutationFn: async (params: CreateProfileOnboardingSurveyDataArgs) => {
      return CreateProfileOnboardingSurveyData(params);
    },
    onSuccess: async () => {
      await refetch();
    },
    onError: (error: EngineEarsError) => {
      displayEngineEarsError(error);
    },
  });
};

export default useCreateProfileOnboardingSurveyDataMutation;
